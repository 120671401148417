import * as React from 'react';

import './history-paid-out-card.scss';
import {Card} from '../atoms/Card/Card';
import {ColoredIcons, Paginator} from 'in5pire-storybook';
import {HistoryPaidOutTable} from '../atoms/HistoryPaidOutTable/HistoryPaidOutTable';

interface Props {
  projectsData: { projectName: string, amount: string, id: string }[],
  changePage: (payload: number) => void,
  className?: string,
  page: number;
  total: number;
  onRowClick: (value: string) => void;
}

export const HistoryPaidOutCard = (props: Props) => {
  return (
    <Card cardTitle='PAYOUT HISTORY'
          icon={ColoredIcons.HistoryIcon()}
          className={props.className}>
      <HistoryPaidOutTable projectsData={props.projectsData} onRowClick={props.onRowClick}/>
      <Paginator page={props.page} total={props.total} perPageLimit={15} changePage={props.changePage}/>
    </Card>
  )
};
