export function getShortFundingTarget(project) {
  return project.longFundingTarget ? project.shortFundingTarget - getLongRemainingAmount(project) : project.shortFundingTarget;
}
export function getShortRemainingAmount(project) {
  var shortFoundedSum = project.shortFoundedSum,
      longFundingTarget = project.longFundingTarget;
  var shortFundingTarget = getShortFundingTarget(project);

  if (!longFundingTarget) {
    return shortFundingTarget - shortFoundedSum;
  }

  return shortFundingTarget - shortFoundedSum;
}
export function getLongRemainingAmount(project) {
  var longFoundedSum = project.longFoundedSum,
      longFundingTarget = project.longFundingTarget;
  return longFundingTarget - longFoundedSum;
}
export function getShortMinAmount(state) {
  var shortFundingMinimum = state.shortFundingMinimum;
  var shortRemainingAmount = getShortRemainingAmount(state);
  return shortFundingMinimum > shortRemainingAmount ? shortRemainingAmount : shortFundingMinimum;
}
export function getShortMaxAmount(state) {
  var shortRemainingAmount = getShortRemainingAmount(state);

  if (!state.longFundingTarget) {
    return shortRemainingAmount;
  }

  return shortRemainingAmount + getLongRemainingAmount(state);
}
export function getLongMinAmount(state) {
  var longFundingMinimum = state.longFundingMinimum,
      shortAmount = state.shortAmount;
  var shortRemainingAmount = getShortRemainingAmount(state);
  var longRemainingAmount = getLongRemainingAmount(state);

  if (shortAmount > shortRemainingAmount) {
    return shortAmount - shortRemainingAmount;
  }

  if (longFundingMinimum > longRemainingAmount) {
    return longRemainingAmount;
  }

  return longFundingMinimum;
}
export function amountIsValid(state) {
  var longMax = getLongRemainingAmount(state);
  var shortMax = getShortMaxAmount(state);
  var shortIsValid = state.shortAmount >= state.shortMin && state.shortAmount <= shortMax;

  if (state.isLong === false) {
    return shortIsValid;
  }

  var longIsValid = state.longAmount >= state.longMin && state.longAmount <= longMax;
  var longInRange = state.shortAmount <= state.shortMax - state.longMax + state.longAmount;
  return shortIsValid && longIsValid && longInRange;
}