import React from 'react';
import { Modal, Modes } from 'in5pire-storybook';
import { ContactForm } from '../../components/ContactForm/ContactForm';
import { EnumContactUsRequestInterest, MarketingControllerService, UserDto } from 'in5pire-api';
import { resolveError } from '../../helpers';

interface Props {
  onClose: () => void;
  user: UserDto;
  onSend?: () => void;
  projectName: string,
}

export function PlatformContact({onClose, user, onSend, projectName}: Props) {

  const [contactError, setContactError] = React.useState('');
  const [contactSuccessMessage, setContactSuccessMessage] = React.useState('');

  const [contactMessageInfo, changeContactMessageInfo] = React.useState({
    companyName: user.companyName,
    email: user.email,
    fullName: user.fullName,
    interest: user.usedAnswerAttempts >= 3 ? EnumContactUsRequestInterest.KYC :
      !!user.companyName ? EnumContactUsRequestInterest.COMPANY_INVESTOR :
        EnumContactUsRequestInterest.INDIVIDUAL_INVESTOR,
    message: '',
    phoneNumber: user.phoneNumber
  });
  const setContactMessageInfo = React.useCallback(info => {
    changeContactMessageInfo({...info, message: info.message.slice(0, 2500)});
  }, [])
  const handleSendSubmit = React.useCallback(() => {
    MarketingControllerService.contactUsUsingPost({request: {
      ...contactMessageInfo,
      projectName,
    }})
      .then(() => {
        if (onSend) {
          onSend();
        }
        setContactSuccessMessage('Your query has been submitted. A member of our team will contact you shortly, thanks for your patience.');
        setContactMessageInfo({...contactMessageInfo, message: ''});
      })
      .catch(error => setContactError(resolveError(error)))
  }, [contactMessageInfo, onSend, projectName, setContactMessageInfo]);

  React.useEffect(() => {
    if (contactMessageInfo.message) {
      setContactSuccessMessage('');
    }
  }, [contactMessageInfo]);

  return (
    <Modal mode={Modes.white} onClose={onClose}>
      <ContactForm info={{...contactMessageInfo, projectName: projectName}}
        handleChange={setContactMessageInfo}
        handleSubmit={() => handleSendSubmit()}
        contactError={contactError}
        successMessage={contactSuccessMessage} />
    </Modal>
  )
}
