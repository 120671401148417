import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { UserState } from '../stores/user-state';
import { observer } from 'mobx-react';
import { EnumLoginResponseRole } from 'in5pire-api';

interface Props extends RouteProps {
  user: UserState,
}

export const RedirectRoute = observer(({ children, location, user, ...rest }: Props) => {
  return (
    <Route
      {...rest}
      children={<Redirect to={{ pathname: getRedirectUrl(user) }} />}
    />
  )
});

function getRedirectUrl(userState: UserState) {
  if (userState.token == null) {
    return '/login';
  }
  if (userState.role === EnumLoginResponseRole.INVESTOR /* && userState.user.kycStatus === EnumUserDtoKycStatus.APPROVED */) {
    return '/platform/dashboard';
  }
  if (userState.role === EnumLoginResponseRole.ADMIN) {
    return '/platform/dashboard';
  }
  return '/platform/kyc';
}
