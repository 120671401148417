import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { AdminUsersList, ErrorModal }  from 'in5pire-storybook';
import {resolveError} from '../../helpers';
import {Modal, Modes} from 'in5pire-storybook';
import { UsersControllerService, EnumBaseUserDtoStatus, EnumUserDtoKycStatus} from 'in5pire-api';

import './user-list-page.scss';

const USERS_PAGE_LIMIT = 20;

export const UserListPage = observer((props: RouteComponentProps) => {
  const [page, setPage] =  React.useState(1);
  const [resetedUserNumber, setResetedUserNumber] = React.useState(undefined);
  const [search, setSearch] = React.useState('');
  const [status, setStatus] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [sort, setSort] = React.useState({field: 'EMAIL', direction: 'ASC'});
  const [error, setError] = React.useState('');
  const [disabledButtons, setDisabledButtons] = React.useState([]);

  const goToUser = React.useCallback(id => props.history.push(`/platform/users/${id}`), [props.history]);
  const onSettingData = React.useCallback((page: number, search: string, status: string[], sort: {field: string, direction: string}) => {
      props.history.push(`/platform/users/?page=${page}&search=${search}&column=${sort.field || ''}&direction=${sort.direction || ''}&status=${status.join('+')}`);
  }, [props.history])
  const getUsers = React.useCallback((page, searchTerm, status, column, direction) => {
    const params = {
      limit: USERS_PAGE_LIMIT,
      offset: (page - 1) * USERS_PAGE_LIMIT,
      status,
      searchTerm,
      column,
      direction
    };
    return UsersControllerService.getUsersUsingGet(params);
  }, []);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    const page = parseInt(searchParams.get('page')) || 1;
    const search = searchParams.get('search') || '';
    const status = searchParams.get('status');
    const column = searchParams.get('column');
    const direction = searchParams.get('direction');

    setPage(page as number);
    setSearch(search as string);
    setStatus(status ? (status as string).split(' ') :  Object.keys(EnumUserDtoKycStatus));
    setSort({field: column, direction: direction});
    setLoading(true);
    setError('');
    const userStatus = status ? (status as string).split(' ') : Object.keys(EnumUserDtoKycStatus);
    getUsers(page, search, userStatus, column, direction).then(({users, count}) => {
      setUsers(users);
      setTotal(count);
      setError('');
      setLoading(false);
    }).catch(error =>  {
      setLoading(false);
      setUsers([]);
      setTotal(0);
      setError(resolveError(error));
    });
  }, [props.location, getUsers]);
  const onClose = React.useCallback(() => {
    setError('');
  }, [setError]);
  const changeStatus = React.useCallback(id => {
    const targetUser = users.find(item => item.id === id);
    if (!targetUser) {
      return;
    }
    setDisabledButtons([...disabledButtons, id]);
    const activationAction = targetUser.status === EnumBaseUserDtoStatus.ACTIVE ?
    UsersControllerService.deactivateUserUsingPut({id}) : UsersControllerService.activateUserUsingPut({id});
    const newStatus = targetUser.status === EnumBaseUserDtoStatus.ACTIVE ?
      EnumBaseUserDtoStatus.NOT_ACTIVE : EnumBaseUserDtoStatus.ACTIVE;
    activationAction.then(() => {
      const newUsers = users.map(item => item.id === id ? {...item, status: newStatus} : item);
      setUsers(newUsers);
      setDisabledButtons([...disabledButtons.filter(item => item !== id)]);
    }).catch(error =>  {
      setLoading(false);
      setError(resolveError(error));
    });
  }, [disabledButtons, users]);
  const resetQuestions = React.useCallback(id => {
    UsersControllerService.resetAnswerAttemptsUsingPut({id}).then(() => {
      setResetedUserNumber(id);
    }).catch(err => setError(resolveError(err)));
  }, []);
  return (
    <div className={loading ? 'data-loading disable-controls' : 'admin-userlist__wrapper'}>
      {error && <ErrorModal onClose={onClose} content={error} />}
      {resetedUserNumber &&
        <Modal mode={Modes.blue} customClass="reset-questions-modal" onClose={() => setResetedUserNumber(undefined)}>
          <div>Questions were reseted(id: {resetedUserNumber})</div>
        </Modal>
      }
      <AdminUsersList caption="Users list"
                      page={page}
                      total={total}
                      status={status}
                      limit={USERS_PAGE_LIMIT}
                      setData={onSettingData}
                      setStatus={setStatus}
                      resetQuestions={resetQuestions}
                      searchTerm={search}
                      users={users}
                      changeStatus={changeStatus}
                      blockedButtons={disabledButtons}
                      sort={sort}
                      goToUser={goToUser}
      />
    </div>
  );
});
