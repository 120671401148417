import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { LoginPage } from '../../pages/Login/LoginPage';

import './unauthorized-layout.scss';
import { RegistrationPage } from '../../pages/Registration/RegistrationPage';
import { NewPasswordPage } from '../../pages/NewPassword/NewPasswordPage';
import { Footer, PlatformPreFooter } from 'in5pire-storybook';

export function UnauthorizedLayout() {
  return (
    <div className="unauthorized">
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route path="/login/new-password/:token" component={NewPasswordPage} />
        <Route path="/login/:token" component={RegistrationPage} />
      </Switch>
      <Footer preFooter={<PlatformPreFooter />} />
    </div>
  )
}
