import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { InvestorProjectList, ErrorModal }  from 'in5pire-storybook';
import { ProjectsControllerService, EnumBaseProjectDtoStatus} from 'in5pire-api';
import { NavLink } from 'react-router-dom';
import {resolveError} from '../../helpers';

import './project-list-page.scss';

const PROJECTS_PAGE_LIMIT = 12;

export const ProjectListPage = observer((props: RouteComponentProps) => {
  const [page, setPage] =  React.useState(1);
  const [search, setSearch] = React.useState('');
  const [status, setStatus] = React.useState([]);
  const [projects, setProjects] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const getNavLink = (to, caption, classN) => <NavLink to={to} className={classN}>{caption}</NavLink>
  const goToProject = id => props.history.push(`/platform/projects/${id}`);
  const onSettingData = React.useCallback((page, search, status) => {
      props.history.push(`/platform/projects/?page=${page}&search=${search}&status=${status.join('+')}`);
  }, [props.history]);
  const getProjects = React.useCallback((page, searchTerm, status) => {
    const params = {
      limit: PROJECTS_PAGE_LIMIT,
      offset: (page - 1) * PROJECTS_PAGE_LIMIT,
      status,
      searchTerm
    };
    return ProjectsControllerService.getProjectsUsingGet(params);
  }, []);
  React.useEffect(() => {
    const defaultStatus = Object.keys(EnumBaseProjectDtoStatus)
      .filter(item => item === EnumBaseProjectDtoStatus.COMING_SOON || item === EnumBaseProjectDtoStatus.AVAILABLE_TO_PLEDGE);
    const searchParams = new URLSearchParams(props.location.search);
    const page = parseInt(searchParams.get('page')) || 1;
    const search = searchParams.get('search') || '';
    const status = searchParams.get('status');

    setPage(page as number);
    setSearch(search as string);
    setStatus(status ? (status as string).split(' ') :  defaultStatus);
    setLoading(true);
    setError('');
    getProjects(page, search, status ? (status as string).split(' ') : defaultStatus).then(({projects, count}) => {
      projects = projects.map(item => ({...item, images: item.images || [{url: '', id: 1, main: true}]}))
      setProjects(projects);
      setTotal(count);
      setError('');
      setLoading(false);
    }).catch(error =>  {
      setProjects([]);
      setTotal(0);
      setError(resolveError(error));
      setLoading(false);
    });
  }, [props.location, getProjects]);
  const onClose = () => {
    setError('');
  }
  return (
    <div className={loading ? 'data-loading disable-controls' : ''}>
      {error && <ErrorModal onClose={onClose} content={error} />}
      <InvestorProjectList page={page}
                           searchTerm={search}
                           setData={onSettingData}
                           data={projects}
                           total={total}
                           setStatus={setStatus}
                           status={status}
                           goToProject={goToProject}
                           getNavLink={getNavLink}
      />
    </div>
  );
});
