import * as React from 'react';

import { Registration, NotMemberModal, SuccessModal, ErrorModal } from 'in5pire-storybook';
import { RouteComponentProps } from 'react-router-dom';
import { LoginResponse, UserControllerService, AuthControllerService, EnumValidateTokenFromLinkRequestTokenType } from 'in5pire-api';
import { RootContext } from '../../stores/root-context';

enum Statuses {
  wait = 0,
  error = 1,
  success = 2
}

export function RegistrationPage({ match, history }: RouteComponentProps) {

  const { userState } = React.useContext(RootContext);

  const handleRegister = React.useCallback((response: LoginResponse) => {
    userState.login(response.token.replace('Bearer ', ''), false);
    UserControllerService.getProfileUsingGet().then(response => {
      userState.setUser(response.user);
      history.push('/');
    });
  }, [userState, history]);

  const [status, setStatus] = React.useState(Statuses.wait);
  const [state, setState] = React.useState({
    isShowNotMember: true,
    isShowApplySuccess: false,
    applyError: ''
  });

  React.useEffect(() => {
    AuthControllerService.validateTokenUsingPost({request: {
      token: match.params['token'],
      tokenType: EnumValidateTokenFromLinkRequestTokenType.REGISTRY
    }})
      .then(() => setStatus(Statuses.success))
      .catch(() => setStatus(Statuses.error));
  }, [match.params]);

  return (
    <React.Fragment>
      {
        status === Statuses.wait ? <React.Fragment /> :
          status === Statuses.error ? <React.Fragment>
            {state.isShowNotMember && <NotMemberModal
              onRegister={() => {
                setState({...state, isShowNotMember: false, isShowApplySuccess: true})
              }}
              onError={(error) => {
                setState({...state, isShowNotMember: false, applyError: error});
              }}
              onClose={() => {}}
              isShowModal={true}/>}
            {state.isShowApplySuccess && <SuccessModal
              onClose={() => {}}
              title={'Thank you!'}
              subtitle={'You request has been sent.'}
              isShowModal={true}
            />}
            {!!state.applyError && <ErrorModal
              content={state.applyError}
              onClose={() => setState({...state, applyError: '', isShowNotMember: true})}
            />}
          </React.Fragment> : <Registration
            termsUrl=""
            onError={() => {}}
            onRegister={handleRegister}
            token={match.params['token']}
          />
      }
    </React.Fragment>
  );
}
