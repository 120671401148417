import * as React from 'react';

import './followed-projects-card.scss';
import {Card} from '../atoms/Card/Card';
import {ColoredIcons, Paginator} from 'in5pire-storybook';
import {ProjectsTable} from '../atoms/ProjectsTable/ProjectsTable';

interface Props {
  projectsData: { title: string, status: string, id: string }[],
  changePage: (payload: number) => void,
  className?: string,
  page: number,
  total: number,
  onRowClick: (id: string) => void;
}

export const FollowedProjectsCard = (props: Props) => {
  return (
    <Card cardTitle='projects i  follow'
          icon={ColoredIcons.HearthIcon()}
          className={props.className}>
      <ProjectsTable projectsData={props.projectsData}
                     evenRowColor='grey'
                     onRowClick={props.onRowClick}
                     headerColor='orange'/>
      <Paginator page={props.page} total={props.total} perPageLimit={8} changePage={props.changePage}/>
    </Card>
  );
};
