const currencyFormatterGB = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
const dateFormatter = new Intl.DateTimeFormat('en-GB');

export const resolveError = error => error.response && error.response.data && error.response.data.message ?
error.response.data.message : error.message;
export const randomColor = () => `rgb(${Math.floor(Math.random()*256)},${Math.floor(Math.random()*256)},${Math.floor(Math.random()*256)})`;
export const dynamicCurrencyFormat = (amount = 0) => amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
export const toMoneySum = (amount = 0) => amount !== null ? currencyFormatterGB.format(amount) :'0';
export const formatDateGB = time => {
  const date = new Date(time);
  return dateFormatter.format(date);
}
export const roundFloatNumber = (value, count) => {
  return value % 1 ? value.toFixed(count) : value.toString();
}
