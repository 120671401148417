import * as React from 'react';

import './donut-chart.scss';
import Chart from "chart.js";
import {randomColor} from '../../../../../helpers'
let saver1, saver2;

interface Props {
  projectsStatistic: { itemName: string, amount: number, caption?: string, percent?: number, color?: string }[];
  caption: {title: string, count: number};
  isDouble?: boolean;
  second?: boolean;
}

export class DonutChart extends React.PureComponent<Props> {
  chartRef;
  labels = [];
  amounts = [];
  colors = [];

  constructor(props) {
    super(props);
    this.chartRef = React.createRef<HTMLCanvasElement>();
  }

  componentDidUpdate(prev) {
    if (prev.projectsStatistic.length === this.props.projectsStatistic.length
      && this.props.projectsStatistic.reduce((a, b, i) => a &&
      prev.projectsStatistic[i].amount === b.amount &&
      prev.projectsStatistic[i].percent === b.percent &&
      prev.projectsStatistic[i].itemName === b.itemName, true)) {
      return;
    }
    this.labels = [];
    this.amounts = [];
    this.colors = [];
    this.props.projectsStatistic.forEach(({ itemName, amount, color}) => {
      this.amounts.push(amount);
      this.colors.push(color || randomColor());
      this.labels.push(itemName);
    });
    this.buildChart();
   // this.forceUpdate();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");
    if (typeof this.props.second !== "undefined" && typeof saver2 !== "undefined") {
      saver2.destroy();
    } else if (typeof this.props.second === "undefined" && typeof saver1 !== "undefined") {
      saver1.destroy();
    }

    const chartRef = new Chart(myChartRef, {
      type: 'doughnut',
      data: {
        labels: this.labels,
        datasets: [
          {
            data: this.amounts,
            backgroundColor: this.colors,
            fill: true,
            borderWidth: 0,
          }
        ]
      },
      options: {
        legend: false,
        cutoutPercentage: 88,
      }
    });
    if (typeof this.props.second !== "undefined") {
      saver2 = chartRef
    } else {
      saver1 = chartRef;
    }
  };

  render() {
    return (
      <div className='graph-container'>
        <div className='projects-count'>
          {this.props.caption.title} <p>{this.props.caption.count}</p>
        </div>
        <canvas id={`chart-${this.props.caption.title}`} ref={this.chartRef}/>
        <ul className={this.props.isDouble ? 'legend multi-legend' : 'legend'}>
          {this.props.projectsStatistic.map(({ itemName, percent, amount}, index) =>
            (<li className='legend__item' key={(percent || amount || 1).toString() + index}>
              <div style={{backgroundColor: this.colors[index]}}/>
              <span className='legend__title'>{itemName}</span>
              <span className='legend__percent'>{percent ? `${percent}%` : amount}</span>
            </li>))}
        </ul>
      </div>
    );
  }
}
