import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { saveAs } from 'file-saver';
import {
  ProjectsControllerService,
  EnumBaseProjectDtoStatus,
  BaseProjectDto,
} from 'in5pire-api';
import {
  SvgIcons,
  projectType,
  projectStatus,
  ListHeader,
  ErrorModal,
  getInvestmentStrategyName,
} from 'in5pire-storybook';
import {useState} from 'react';
import {resolveError, toMoneySum, formatDateGB} from '../../helpers';

import './admin-project-list-page.scss';

const PROJECTS_PAGE_LIMIT = 20;

const headerTitles = [
  {
    column: 'CREATE_TIMESTAMP',
    title: 'ID',
    className: 'small-width'
  },
  {
    column: 'TITLE',
    title: 'Name',
    className: 'break-words large-width',
  },
  {
    column: 'TYPE',
    title: 'Type',
    className: 'medium-width'
  },
  {
    column: '',
    title: 'Strategy'
  },
  {
    column: 'STATUS',
    title: 'Status'
  },
  {
    column: 'REQUIREMENT',
    title: 'Total Requirement'
  },
  {
    column: 'PROGRESS',
    title: 'Progress',
    className: 'medium-width'
  },
  {
    column: 'DURATION',
    title: 'Project Dates',
    className: 'large-width',
    style: {minWidth: '230px'}
  },
];

const showActualDatesStatuses = new Set([
  'FULLY_FUNDED_DEVELOPMENT_BEGAN',
  'FULLY_FUNDED_DEVELOPMENT_COMPLETE_SHORT',
  'COMPLETE_SHORT_TERM',
  'FULLY_FUNDED_DEVELOPMENT_COMPLETE_LONG',
  'COMPLETE',
  'CANCELLED_NOT_PAID_OUT',
  'CANCELLED_PAID_OUT',
  'ARCHIVED',
]);

const statusValues = Object.keys(EnumBaseProjectDtoStatus).filter(item => item !== 'FULLY_FUNDED_DEVELOPMENT_COMPLETE_SHORT').map(item => ({
  included: [item],
  title: projectStatus(item as EnumBaseProjectDtoStatus)
}));

const defaultStatus = Object.keys(EnumBaseProjectDtoStatus);
enum filterTypes {
  searchTerm = 'searchTerm',
  page = 'page',
  status = 'status',
  status_click = 'status_click',
  sort = 'sort'
}

const getProjects = (page, searchTerm, status, column, direction) => {
  const params = {
    limit: PROJECTS_PAGE_LIMIT,
    offset: (page - 1) * PROJECTS_PAGE_LIMIT,
    status,
    searchTerm,
    column,
    direction
  };
  return ProjectsControllerService.getProjectsUsingGet(params);
}

function canDeleteProject(project: BaseProjectDto): boolean {
  if (project.status === EnumBaseProjectDtoStatus.COMING_SOON) {
    return true;
  }
  if (project.status === EnumBaseProjectDtoStatus.DRAFT) {
    return true;
  }
  return false;
}

export const AdminProjectsListPage = ({history, location}: RouteComponentProps) => {
  const [projects, setProjects] = React.useState<BaseProjectDto[]>([]);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState('');
  const [status, setStatus] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [sort, setSort] = React.useState({field: 'CREATE_TIMESTAMP', direction: 'DESC'});

  const onExportInvestors = React.useCallback((id, title) => {
    ProjectsControllerService.csvExportSalesUsingGet({id}, {responseType: 'blob'})
      .then(content => {
        saveAs(content, `${id}-${title}.csv`);
      })
      .catch(err => setError(resolveError(err)));
  }, []);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get('page')) || 1;
    const search = searchParams.get('search') || '';
    const status = searchParams.get('status');
    const column = searchParams.get('column') || 'CREATE_TIMESTAMP';
    const direction = searchParams.get('direction') || 'DESC';

    setPage(page as number);
    setSearch(search as string);
    setStatus(status ? (status as string).split(' ') : defaultStatus);
    setSort({field: column || 'CREATE_TIMESTAMP', direction: direction || 'DESC'});
    setLoading(true);
    const projectStatus = status ? (status as string).split(' ') : defaultStatus;
    getProjects(page, search, projectStatus, column, direction)
      .then(({projects, count}) => {
        setProjects(projects.map(item => ({...item, images: item.images || [{url: '', id: 1, main: true}]})));
        setTotal(count);
        setLoading(false);
      })
      .catch(error => {
        setError(resolveError(error));
        setLoading(false);
        setProjects([]);
        setTotal(0);
      });
  }, [location]);

  const setData = React.useCallback((page, search, status, sort: {field: string, direction: string}) => {
    history.push(`/platform/projects/?page=${page}&search=${search}&column=${sort.field || ''}&direction=${sort.direction || ''}&status=${status.join('+')}`);
  }, [history]);
  const changeFilter = React.useCallback((param, value) => {
    switch(param) {
    case filterTypes.searchTerm:
      setData(1, value, status, sort);
      break;
    case filterTypes.page:
      setData(value, search, status, sort);
      break;
    case filterTypes.status:
      setStatus(value);
      break;
    case filterTypes.status_click:
      setData(1, search, status, sort);
      break;
    case filterTypes.sort:
      console.log(status)
      setData(1, search, status, value);
      break;
    }
  }, [search, setData, sort, status]);

  const handleDelete = React.useCallback(id => {
    if (!window.confirm('Delete project?')) return;
    ProjectsControllerService.deleteProjectUsingDelete({id}).then(() => history.go(0));
  }, [history]);

  return (
    <div className={loading ? 'disable-controls data-loading admin-project-list' : 'admin-project-list'}>
      {error && <ErrorModal content={error} onClose={() => setError('')}/>}
      <ListHeader caption="Project list"
        changeFilter={changeFilter}
        isAdminProjects={true}
        page={page}
        total={total}
        status={status}
        searchTerm={search}
        statusItems={statusValues}
        limit={PROJECTS_PAGE_LIMIT}
      />
      <div className="admin-project-list__wrapper">
        <table>
          <thead>
            <tr>
              {headerTitles.map(({column, title, className, style}, i) => (
                <th key={title} className={className || ''} style={style}>
                  <div className={column ? 'project-list__th-sort' : ''}
                    onClick={column ? e => changeFilter(filterTypes.sort, {
                      field: column,
                      direction: sort.direction === 'ASC' && sort.field === column ? 'DESC' : 'ASC'})
                      : () => {}}>
                    <div className={sort.field === column ? 'admin-project-list__active-header' : ''}>{title}</div>
                    { sort.field === column &&
                      <SvgIcons isStaticImg
                        customClass={
                          sort.direction === 'DESC' ? 'user-list__rotate-icon' : ''
                        }
                        iconType="down"
                      />
                    }
                  </div>
                </th>)
              )}
              <th className="menu-cell">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {projects.length > 0 && projects.map(project => (
              <ProjectRow onDelete={canDeleteProject(project) ? handleDelete : null}
                key={project.id}
                onExportInvestors={onExportInvestors}
                project={project}/>
            ))}
          </tbody>
        </table>
        {!projects.length && <div className="table__no-result">No search result</div>}
      </div>
    </div>
  )
};

interface ProjectRowProps {
  project: BaseProjectDto;
  onBlur?: () => void;
  onDelete: (id: number) => void | null;
  onExportInvestors: (id: number, title: string) => void;
}

const MenuContainer = ({project, onBlur, onDelete, onExportInvestors}: ProjectRowProps) => (
  <nav onBlur={onBlur} className="project-list__user-settings">
    <Link to={'/platform/projects/edit/' + project.id}>
      <SvgIcons isStaticImg iconType={'edit'}/> Edit
    </Link>
    {onDelete != null && <button type="button" onClick={(e) => { onDelete(project.id) }} >
      <SvgIcons isStaticImg iconType={'trash'}/> Delete
    </button>}
    { (project.status !== EnumBaseProjectDtoStatus.DRAFT && project.status !== EnumBaseProjectDtoStatus.COMING_SOON)
    ? <React.Fragment>
      <Link to={'/platform/projects/payments/' + project.id}>
        <SvgIcons isStaticImg iconType={'payments'}/> Payments
      </Link>
      <button type="button" onClick={(e) => { onExportInvestors(project.id, project.title) }} >
        <SvgIcons isStaticImg iconType={'turnon'}/> Export investors
      </button>
    </React.Fragment>
    : null }
  </nav>
);

function ProjectRow(props: ProjectRowProps) {
  const project = props.project;
  const [menu, setMenu] = useState({
    isShow: false
  });
  const {shortTypes, longTypes} = getInvestmentStrategyName(project);
  const projectStrategies = shortTypes.map(item => longTypes.indexOf(item) !== -1 ? `${item} (long)` : item).join(', ');

  const shortStart = showActualDatesStatuses.has(project.status) && project.shortActualStartDate
    ? project.shortActualStartDate
    : project.shortStartDate;

  const shortEnd = showActualDatesStatuses.has(project.status) && project.shortActualEndDate
    ? project.shortActualEndDate
    : project.shortEndDate;


  const longStart = showActualDatesStatuses.has(project.status) && project.longActualStartDate
    ? project.longActualStartDate
    : project.longStartDate;

  const longEnd = showActualDatesStatuses.has(project.status) && project.longActualEndDate
    ? project.longActualEndDate
    : project.longEndDate;

  return <tr onMouseLeave={() => {setMenu({...menu, isShow: false})}}>
    <td className="small-width">{project.id}</td>
    <td className="large-width break-words">{project.title}</td>
    <td className="medium-width">{projectType(project.projectType)}</td>
    <td>{projectStrategies}</td>
    <td>{projectStatus(project.status)}</td>
    <td>
      <div>Short: {toMoneySum(project.shortFundingTarget)}</div>
      {project.longFundingTarget && <div>Long: {toMoneySum(project.longFundingTarget)}</div>}
    </td>
    <td className="medium-width">
      <div>Short: {Math.round(project.shortFoundedSum / (project.shortFundingTarget || 1) * 100)}%</div>
      {project.longFoundedSum !== null && project.longFundingTarget !== null
      && <div>Long: {Math.round(project.longFoundedSum / (project.longFundingTarget || 1) * 100)}%</div>}
    </td>
    <td className="large-width" style={{minWidth: '230px'}}>
      <div>Short: {
          formatDateGB(shortStart)
        } - {
          formatDateGB(shortEnd)
        }</div>
      {project.allowLongTerm && (
        <div>Long: {
            formatDateGB(longStart)
          } - {
            formatDateGB(longEnd)
          }
        </div>
      )}
    </td>
    <td className="project-list__menu-cell">
      <button
        type="button"
        className="button button--icon"
        onClick={() => { setMenu({...menu, isShow: !menu.isShow})}}
      >
        <img src="more.svg" alt="more"/>
      </button>
      {menu.isShow ? <MenuContainer onBlur={() => { setMenu({...menu, isShow: !menu.isShow})}} {...props} /> : null}
    </td>
  </tr>
}
