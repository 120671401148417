export var localization = {
  registration: {
    title: 'Welcome to Solia',
    description: 'We’re happy to see you again! Sign up to access the platform.',
    fieldsRequired: '*All fields are required',
    fullName: 'Full Name',
    email: 'Email address',
    password: 'Password',
    repeatPassword: 'Repeat password',
    agreement: 'I accept Investor Platform Terms and Conditions. By accepting terms you also agree that the company can perform AML and KYC checks.',
    createInvestorButton: 'Create Investor Account',
    emptyFullName: 'Please enter your full name',
    emptyEmailPassword: 'Please enter the Email address” / “Please enter the password',
    emptyPassword: 'Please enter the password',
    incorrectEmail: 'Incorrect Email address',
    passwordMatch: 'Password mismatch',
    passwordReq: 'The password shall contain at least 1 digit, 1 upper- and 1-lowercase letters, and password length  must be at least 6 chars.',
    repeatPasswordReq: 'Repeat password required'
  },
  notMember: {
    submitButton: 'let’s get started',
    title: 'Provide us with some information to register your interest. <b>Just tell us:</b>'
  },
  success: {},
  footer: {
    powered: 'Powered by',
    allRights: '© 2020 All rights reserved'
  }
};