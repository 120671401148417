import * as React from 'react';
import {ItemSelector} from 'in5pire-storybook';
import './selector.scss';

interface FilterItems {
  value: 'MONTH' | 'HALF_YEAR' | 'YEAR' | 'ALL_TIME';
  title: string;
}

const filterItems: FilterItems[] = [
  {
    value: 'MONTH',
    title: 'Last 30 Days'
  },
  {
    value: 'HALF_YEAR',
    title: 'Last 6 Months'
  },
  {
    value: 'YEAR',
    title: 'Last Year'
  },
  {
    value: 'ALL_TIME',
    title: 'All Time'
  }
];
interface Props {
  onSelect: (value: 'MONTH' | 'HALF_YEAR' | 'YEAR' | 'ALL_TIME'| number) => void;
  selected: 'MONTH' | 'HALF_YEAR' | 'YEAR' | 'ALL_TIME'
}

export function Selector(props: Props) {
  return (
    <div className="dashboard-selector">
      <ItemSelector selected={props.selected}
                    onSelect={props.onSelect}
                    statusItems={filterItems} />
    </div>
  );
}
