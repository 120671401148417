import React from 'react';
import {
  AgreementsControllerService
} from 'in5pire-api';

import { RouteComponentProps } from 'react-router-dom';

export const AgreementPdf = ({match, history, location}: RouteComponentProps) => {

  React.useEffect(() => {
    if(location.state && location.state['agreement']) {
      return;
    }
    const id = parseInt(match.params['id'], 10);
    AgreementsControllerService.getAgreementUsingGet({id}).then(({agreements}) => {
      if (!agreements || !agreements[0]) {
        return;
      }
      history.replace({pathname: location.pathname, state: {agreement: agreements[0].agreementUrl}})
    });
  }, [match.params, location, history]);

  return (
    <>
      {location.state && location.state['agreement'] ?
        <iframe title="agreement" className="agreement-frame" src={location.state['agreement']} /> :
        <></>
      }
    </>
  );
}