import {observer} from 'mobx-react';
import React from 'react';

import {PageTitle} from '../../components/PageTitle/PageTitle';

import './dashboard-page.scss';
import {PledgedInvestmentCard} from './components/PledgedInvestmentCard/PledgedInvestmentCard';
import {InterestOccurredCard} from './components/InterestAccuredCard/InterestOccurredCard';
import {FollowedProjectsCard} from './components/FollowedProjectsCard/FollowedProjectsCard';
import {InvestedProjectsCard} from './components/InvestedProjectsCard/InvestedProjectsCard';
import {InvestmentsCard} from './components/InvestmentsCard/InvestmentsCard';
import {HistoryPaidOutCard} from './components/HistoryPaidOutCard/HistoryPaidOutCard';
import {WalletCard} from './components/WalletCard/WalletCard';
import {ChartCard} from './components/ChartCard/ChartCard';
import {FundModal} from './components/FundModal/FundWithdrawModal';
import {Selector} from './components/Selector/Selector';
import { RouteComponentProps } from 'react-router-dom';
import { DashboardControllerService } from 'in5pire-api';
import {projectStatus, ErrorModal} from 'in5pire-storybook';
import {resolveError, toMoneySum, formatDateGB} from '../../helpers';
import { RootContext } from '../../stores/root-context';

const SMALL_PAGE = 8;
const LARGE_PAGE = 15;

const investStatuses = {
  PENDING: 'pending',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed'
};
const walletStatuses = {
  PAY_IN: 'Pay in',
  TRANSFER_TO_PROJECT: 'Transfer To Project',
  TRANSFER_FROM_PROJECT: 'Transfer from Project',
  PAY_OUT: 'Pay out'
};

const DEFAULT_INFO = {
  pledgedInvestment: 0,
  totalInterestAccrued: 0,
  walletBalance: 0,
  myMoneyInPlatform: []
}

interface LocationParams {
  filterParam?: 'MONTH' | 'HALF_YEAR' | 'YEAR' | 'ALL_TIME' | number;
  investmentsParam?: number;
  followedParam?: number;
  investedParam?: number;
  paidsoutsParam?: number;
  historyParam?: number;
}
interface Pages {
  investments?: number;
  followed?: number;
  invested?: number;
  paidsouts?: number;
  history?: number;
}
interface Loadings {
  info?: boolean;
  investments?: boolean;
  followed?: boolean;
  invested?: boolean;
  paidsouts?: boolean;
  history?: boolean;
}

export const DashboardPage = observer((props: RouteComponentProps) => {
  const getDefault = React.useCallback((type: 'investments' | 'projects' | 'paidOuts' | 'history') => {
    return {count: 0, [type]: []} as any;
  }, []);

  const userContext = React.useContext(RootContext);
  const [filter, setFilter] = React.useState(undefined);
  const [pages, setPages] = React.useState({
    investments: undefined,
    followed: undefined,
    invested: undefined,
    paidsouts: undefined,
    history: undefined} as Pages);
  const [info, setInfo] = React.useState(DEFAULT_INFO);
  const [isPageBecomeUndefined, setIsPageBecomeUndefined] = React.useState(false);
  const [investments, setInvestments] = React.useState(getDefault('investments'));
  const [followed, setProjectsFollowed] = React.useState(getDefault('projects'));
  const [invested, setProjectsInvested] = React.useState(getDefault('projects'));
  const [paidouts, setPaidouts] = React.useState(getDefault('paidOuts'));
  const [wHistory, setWalletHistory] = React.useState(getDefault('history'));
  const [loadingFilter, setLoadingFilter] = React.useState({} as Loadings);
  const [error, setError] = React.useState('');

  const [fundWithdrawModal, setFundWithdrawModal] = React.useState('' as 'fund' | 'withdraw' | '');

  const onSettingData = React.useCallback((params: LocationParams) => {
    if (params.filterParam) {
      props.history.push(`/platform/dashboard/?filter=${params.filterParam}`);
    } else {
      const otherPages = Object.keys(pages).reduce((res: string, cur: string) => {
        if (params[`${cur}Param`]) {
          res += `&${cur}=${params[cur + 'Param']}`;
        } else if (pages[cur]) {
          res += `&${cur}=${pages[cur]}`;
        }
        return res;
      }, '');
      props.history.push(`/platform/dashboard/?filter=${filter}${otherPages}`);
    }
  }, [props.history, filter, pages]);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    const newFilter = searchParams.get('filter') || 'ALL_TIME';
    const investments = searchParams.get('investments');
    const followed = searchParams.get('followed');
    const invested = searchParams.get('invested');
    const paidsouts = searchParams.get('paidsouts');
    const history = searchParams.get('history');
    setPages(
      {
        investments: investments ? parseInt(investments) : undefined,
        followed: followed ? parseInt(followed) : undefined,
        invested: invested ? parseInt(invested) : undefined,
        paidsouts: paidsouts ? parseInt(paidsouts) : undefined,
        history: history ? parseInt(history) : undefined
      }
    );
    if (filter === newFilter && !isPageBecomeUndefined) {
      return;
    }
    if (isPageBecomeUndefined) {
      setIsPageBecomeUndefined(false);
    }
    if (newFilter) {
      setFilter(newFilter as 'MONTH' | 'HALF_YEAR' | 'YEAR' | 'ALL_TIME');
    }
    setLoadingFilter({info: true});
    DashboardControllerService.getUserDashboardUsingGet({filter: newFilter || 'ALL_TIME', loadFirsPageRelated: true})
      .then(resp => {
        const {
          pledgedInvestment,
          totalInterestAccrued,
          walletBalance,
          myMoneyInPlatform,
          investorDashboardInvestmentHistoryResponse,
          investorDashboardPaidOutHistoryResponse,
          investorDashboardProjectsAreInvestedResponse,
          investorDashboardProjectsFollowResponse,
          investorDashboardWalletHistoryResponse
        } = resp;
        setInfo({pledgedInvestment, totalInterestAccrued, walletBalance, myMoneyInPlatform});
        if (!investments) {
          setInvestments(investorDashboardInvestmentHistoryResponse || getDefault('investments'));
        }
        if (!paidsouts) {
          setPaidouts(investorDashboardPaidOutHistoryResponse || getDefault('paidOuts'));
        }
        if (!invested) {
          setProjectsInvested(investorDashboardProjectsAreInvestedResponse || getDefault('projects'));
        }
        if (!followed) {
          setProjectsFollowed(investorDashboardProjectsFollowResponse || getDefault('projects'));
        }
        if (!history) {
          setWalletHistory(investorDashboardWalletHistoryResponse || getDefault('history'));
        }
        setLoadingFilter({});
      })
      .catch(error => {
        setLoadingFilter({});
        setInfo(DEFAULT_INFO);
        setInvestments(getDefault('investments'));
        setPaidouts(getDefault('paidOuts'));
        setProjectsInvested(getDefault('projects'));
        setProjectsFollowed(getDefault('projects'));
        setWalletHistory(getDefault('history'));
        setError(resolveError(error));
      });
  }, [props.location, getDefault, filter, isPageBecomeUndefined]);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    const isPageBecomeUndefined = (pages.investments && !searchParams.get('investments')) ||
      (pages.followed && !searchParams.get('followed')) ||
      (pages.invested && !searchParams.get('invested')) ||
      (pages.paidsouts && !searchParams.get('paidsouts')) ||
      (pages.history && !searchParams.get('history'));
    setIsPageBecomeUndefined(isPageBecomeUndefined);
  }, [props.location, pages]);

  const onModalClose = React.useCallback(() => {
    setFundWithdrawModal('');
    Promise.all([
      DashboardControllerService.getUserDashboardUsingGet({filter, loadFirsPageRelated: false}),
      DashboardControllerService.getWalletHistoryUsingGet({
        filter,
        limit: LARGE_PAGE,
        offset: pages.history ? LARGE_PAGE * (pages.history - 1) : 0
      })
    ]).then(([{pledgedInvestment, totalInterestAccrued, walletBalance, myMoneyInPlatform}, walletHistory]) => {
      setInfo({pledgedInvestment, totalInterestAccrued, walletBalance, myMoneyInPlatform});
      setWalletHistory(walletHistory);
    })
  }, [filter, pages]);

  React.useEffect(() => {
    if (!pages.followed) {
      return;
    }
    setLoadingFilter({followed: true});
    DashboardControllerService.getProjectsIFollowUsingGet({
      filter,
      limit: SMALL_PAGE,
      offset: SMALL_PAGE * (pages.followed - 1)
    }).then(resp => {
      setLoadingFilter({});
      setProjectsFollowed(resp || getDefault('projects'));
    }).catch(error => {
      setLoadingFilter({});
      setProjectsFollowed(getDefault('projects'));
      setError(resolveError(error));
    });
  }, [pages.followed, filter, getDefault]);

  React.useEffect(() => {
    if (!pages.invested) {
      return;
    }
    setLoadingFilter({invested: true});
    DashboardControllerService.getProjectsAreInvestedUsingGet({
      filter,
      limit: SMALL_PAGE,
      offset: SMALL_PAGE * (pages.invested - 1)
    }).then(resp => {
      setLoadingFilter({});
      setProjectsInvested(resp || getDefault('projects'));
    }).catch(error => {
      setLoadingFilter({});
      setProjectsInvested(getDefault('projects'));
      setError(resolveError(error));
    });
  }, [pages.invested, filter, getDefault]);

  React.useEffect(() => {
    if (!pages.history) {
      return;
    }
    setLoadingFilter({history: true});
    DashboardControllerService.getWalletHistoryUsingGet({
      filter,
      limit: LARGE_PAGE,
      offset: LARGE_PAGE * (pages.history - 1)
    }).then(resp => {
      setLoadingFilter({});
      setWalletHistory(resp || getDefault('history'));
    }).catch(error => {
      setLoadingFilter({});
      setWalletHistory(getDefault('history'));
      setError(resolveError(error));
    });
  }, [pages.history, filter, getDefault]);

  React.useEffect(() => {
    if (!pages.paidsouts) {
      return;
    }
    setLoadingFilter({paidsouts: true});
    DashboardControllerService.getHistoryPaidOutUsingGet({
      filter,
      limit: LARGE_PAGE,
      offset: LARGE_PAGE * (pages.paidsouts - 1)
    }).then(resp => {
      setLoadingFilter({});
      setPaidouts(resp || getDefault('paidOuts'));
    }).catch(error => {
      setLoadingFilter({});
      setPaidouts(getDefault('paidOuts'));
      setError(resolveError(error));
    });
  }, [pages.paidsouts, filter, getDefault]);

  React.useEffect(() => {
    if (!pages.investments) {
      return;
    }
    setLoadingFilter({investments: true});
    DashboardControllerService.getMyInvestmentsUsingGet({
      filter,
      limit: SMALL_PAGE,
      offset: SMALL_PAGE * (pages.investments - 1)
    }).then(resp => {
      setLoadingFilter({});
      setInvestments(resp || getDefault('investments'));
    }).catch(error => {
      setLoadingFilter({});
      setPaidouts(getDefault('investments'));
      setError(resolveError(error));
    });
  }, [pages.investments, filter, getDefault]);

  return (
    <div className={Object.keys(loadingFilter).length ? 'disable-filters' : ''}>
      {error && <ErrorModal onClose={() => setError('')} content={error} />}
      <PageTitle pageTitle="Dashboard" />
      <div className={`dashboard-page ${loadingFilter.info ? 'data-loading' : ''}`}>
        <Selector onSelect={filter => onSettingData({filterParam: filter})} selected={filter} />
        <div className='cards__wrapper'>
          <div className='cards__left'>
            <div className="cards__row margin-bottom">
              <PledgedInvestmentCard className='cards__row_item' totalInvestment={`${toMoneySum(info.pledgedInvestment)}`} />
              <InterestOccurredCard className='cards__row_item' interestOccurred={`${toMoneySum(info.totalInterestAccrued)}`} percent='12%' />
            </div>
            <div className='cards__row margin-bottom'>
              <InvestedProjectsCard className={`cards__row_item invested-card ${loadingFilter.invested ? 'data-loading' : ''}`}
                projectsData={
                  invested.projects.map(({status, projectName, projectId}) => ({
                    title: projectName,
                    id: projectId,
                    status: projectStatus(status)
                  }))
                }
                onRowClick={id => props.history.push(`/platform/projects/${id}`)}
                page={pages.invested || 1}
                total={invested.count}
                changePage={num => onSettingData({investedParam: num})}/>
              <FollowedProjectsCard className={`cards__row_item follow-card ${loadingFilter.followed ? 'data-loading' : ''}`}
                projectsData={
                  followed.projects.map(({status, projectName, projectId}) => ({
                    title: projectName,
                    id: projectId,
                    status: projectStatus(status)
                  }))
                }
                page={pages.followed || 1}
                onRowClick={id => props.history.push(`/platform/projects/${id}`)}
                total={followed.count}
                changePage={num => onSettingData({followedParam: num})} />
            </div>
            <ChartCard className='margin-bottom'
              projectsStatistic={
                info.myMoneyInPlatform.map(({percent, projectName, sum}) => ({
                  itemName: projectName,
                  amount: sum,
                  percent
                }))
              }
              cardTitle='My money in the platform'
              caption={{title: 'projects', count: info.myMoneyInPlatform && info.myMoneyInPlatform.length ? info.myMoneyInPlatform.length : 0}}/>
            <InvestmentsCard className={`investments-card ${loadingFilter.investments ? 'data-loading' : ''}`}
              projectsData={
                investments.investments.map(({projectName, status, timestamp, amount, projectID}) => ({
                  projectName,
                  id: projectID,
                  status: investStatuses[status],
                  amount: `${toMoneySum(amount)}`,
                  date: formatDateGB(timestamp)
                }))
              }
              onRowClick={id => props.history.push(`/platform/projects/${id}`)}
              page={pages.investments || 1}
              total={investments.count}
              changePage={num => onSettingData({investmentsParam: num})} />
          </div>
          <div className='cards__right'>
            <WalletCard className={`margin-bottom ${loadingFilter.history ? 'data-loading' : ''}`}
              transactions={
                wHistory.history.map(({amount, mangoPayId, type, status, timestamp}) => ({
                  amount: `${toMoneySum(amount)}`,
                  tsxId: mangoPayId,
                  type: walletStatuses[type],
                  status: investStatuses[status],
                  date: formatDateGB(timestamp)
                }))
              }
              page={pages.history || 1}
              total={wHistory.count}
              changePage={num => onSettingData({historyParam: num})}
              holder={userContext.userState.user ? userContext.userState.user.fullName : ''}
              balance={toMoneySum(info.walletBalance)}
              onFundClick={() => setFundWithdrawModal('fund')}
              onWithdrawClick={() => setFundWithdrawModal('withdraw')} />
            <HistoryPaidOutCard className={`historypaid-card ${loadingFilter.paidsouts ? 'data-loading' : ''}`}
              projectsData={
                paidouts.paidOuts.map(({amount, projectName, projectId}) => ({
                  projectName,
                  id: projectId,
                  amount: `${toMoneySum(amount)}`
                }))
              }
              onRowClick={id => props.history.push(`/platform/projects/${id}`)}
              page={pages.paidsouts || 1}
              total={paidouts.count}
              changePage={num => onSettingData({paidsoutsParam: num})}/>
          </div>
        </div>

        <div className={`dashboard-page__modal dashboard-page__modal_${fundWithdrawModal || 'closed'}`}>
          {!!fundWithdrawModal &&
            <FundModal currentBalance={info.walletBalance}
              type={fundWithdrawModal}
              onClose={onModalClose}/>
          }
        </div>
      </div>
    </div>
  );
});
