var formatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP'
});
export var toCurrencyFormat = function toCurrencyFormat() {
  var amount = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return amount !== null ? formatter.format(amount) : '0';
};
export var dynamicCurrencyFormat = function dynamicCurrencyFormat() {
  var amount = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};