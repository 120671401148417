import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { ProjectDetails, ErrorModal, Pledge } from 'in5pire-storybook';
import { ProjectsControllerService, ProjectDto, EnumProjectDtoStatus } from 'in5pire-api';
import { NavLink } from 'react-router-dom';
import { RootContext } from '../../stores/root-context';

import './project-details-page.scss';
import { PlatformContact } from './ProjectDetailsAsk';


function getNavLink(to: string, caption: string | JSX.Element, classN: string) {
  return <NavLink to={to} className={classN}>{caption}</NavLink>;
}

export const ProjectDetailPage = observer((props: RouteComponentProps) => {
  const rootState = React.useContext(RootContext);

  const [project, setProject] = React.useState({} as ProjectDto);
  const [disableSubscription, setDisableSubscription] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isPledgeVisible, setPledgeVisible] = React.useState(false);
  const [error, setError] = React.useState('');
  const [isHideError, setIsHideError] = React.useState(false);

  const [isContactModalOpen, setIsContactModalOpen] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    setError('');
    const id = props.match.params['id'];
    ProjectsControllerService.getProjectUsingGet({id}).then(({project}) => {
      setProject(project);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setIsHideError(false);
      setError(error.response && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message); }
    );
  }, [props.match.params]);

  const toggleSubscription = () => {
    setError('');
    setDisableSubscription(true);
    const query = !project.currentUserFollowed ? ProjectsControllerService.subscribeProjectUsingPost({id: project.id})
      : ProjectsControllerService.unsubscribeProjectUsingDelete({id: project.id});
    query
      .then(() => {
        setDisableSubscription(false);
        setProject({...project, currentUserFollowed: !project.currentUserFollowed});
      })
      .catch(err => {
        setDisableSubscription(false);
        setError(err.response && err.response.data && err.response.data.message ? err.response.data.message : err.message || 'Unspecified Error');
      });
  }
  const { shortFoundedSum, shortFundingTarget} = project as ProjectDto;

  const onErrorClose = React.useCallback(() => {
    setIsHideError(true);
  }, []);

  const canPledge = (shortFundingTarget > shortFoundedSum) && project.status === EnumProjectDtoStatus.AVAILABLE_TO_PLEDGE;
  const pledgeWrapperClass = isPledgeVisible ? 'project-details__pledge-wrapper': 'project-details__pledge-wrapper project-details__pledge-wrapper_hidden';

  return (
    <div className="project-details-page">
      {loading && <div>Loading...</div>}
      {error && !isHideError && <ErrorModal onClose={onErrorClose} content={error} />}
      {!loading && !error && project.id &&
        <ProjectDetails data={project as ProjectDto}
          toggleSubscription={toggleSubscription}
          getNavLink={getNavLink}
          openContactUs={() => setIsContactModalOpen(true)}
          disableSubscription={disableSubscription}
          startPledgeProcess={() => setPledgeVisible(true)}/>}

      {canPledge &&
        <div className={pledgeWrapperClass}>
          {isPledgeVisible &&
            <Pledge
              onClose={() => {
                setPledgeVisible(false);
                rootState.userState.updateUser();
              }}
              eWalletBalance={rootState.userState.user.balance}
              project={project}
            />
          }
        </div>
      }

      {isContactModalOpen &&
        <PlatformContact
          user={rootState.userState.user}
          onClose={() => setIsContactModalOpen(false)}
          projectName={project.title}
        />
      }
    </div>
  );
});
