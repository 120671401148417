import { PledgeSignatureType } from './../types/IPledgeProcess';
import { getInvestmentStrategyOptionsForPledge } from './getPledgeStrategyOptions';
import { getLongRemainingAmount, getShortMaxAmount, getShortRemainingAmount } from './amount';
export function initPledgeState(_ref) {
  var eWalletBalance = _ref.eWalletBalance,
      project = _ref.project;
  var id = project.id,
      shortFoundedSum = project.shortFoundedSum,
      longFoundedSum = project.longFoundedSum,
      shortFundingMinimum = project.shortFundingMinimum,
      longFundingMinimum = project.longFundingMinimum,
      shortFundingTarget = project.shortFundingTarget,
      longFundingTarget = project.longFundingTarget,
      shortStartDate = project.shortStartDate,
      shortEndDate = project.shortEndDate,
      longStartDate = project.longStartDate,
      longEndDate = project.longEndDate,
      title = project.title;
  return {
    pledgeId: null,
    amountShortOption: null,
    projectName: title,
    paymentSuccess: false,
    investAgreementPdfUrl: null,
    isLong: null,
    longAmount: null,
    shortAmount: null,
    signatureBase64: null,
    source: null,
    stepIndex: 0,
    strategy: null,
    eWalletBalance: eWalletBalance,
    projectId: id,
    shortFoundedSum: shortFoundedSum,
    longFoundedSum: longFoundedSum,
    shortFundingMinimum: shortFundingMinimum,
    longFundingMinimum: longFundingMinimum,
    shortFundingTarget: shortFundingTarget,
    statement: false,
    conditions: false,
    shortStartDate: shortStartDate,
    shortEndDate: shortEndDate,
    longStartDate: longStartDate,
    longEndDate: longEndDate,
    longFundingTarget: longFundingTarget,
    eSignatureType: PledgeSignatureType.DRAW,
    options: getInvestmentStrategyOptionsForPledge(project),
    paymentConfirmed: false,
    smsCode: null,
    makingPayment: false,
    longMax: getLongRemainingAmount(project),
    longMin: Math.min(project.longFundingMinimum, getLongRemainingAmount(project)),
    shortMax: getShortMaxAmount(project),
    shortMin: Math.min(project.shortFundingMinimum, getShortRemainingAmount(project))
  };
}