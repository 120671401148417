import * as React from 'react';
import {RouteComponentProps } from 'react-router-dom';
import {environment} from '../../environment/environment';
import { ProjectsControllerService, ProjectDto } from 'in5pire-api';
import { CreateProject } from 'in5pire-storybook';
export const ProjectEditPage = ({history, match}: RouteComponentProps) => {
  const [ project, setProject ] = React.useState<ProjectDto>(null);

  React.useEffect(() => {
    ProjectsControllerService.getProjectUsingGet({id: match.params['id']}).then(({project}) => {
      setProject(project);
    })
  }, [match, setProject])

  return project != null ? <div>
    <CreateProject
      editProject={project}
      onSuccess={() => history.push('/platform/projects')}
      mapApiKey={environment.googleMapsApiKey}
      geocodeApiKey={environment.googleGeocodeApiKey}/>
  </div> : <div>Loading...</div>;
};