import React from 'react';

import { ContactUsRequest } from 'in5pire-api';
import { useForm } from 'react-hook-form';
import { FormControl, ColoredIcons, Message } from 'in5pire-storybook';
import { emailRe, nameMaxLength } from 'in5pire-storybook/dist/validators';
import './contact-form.scss';

interface Props {
  info: ContactUsRequest;
  handleChange: (event: any) => void;
  handleSubmit: () => void;
  contactError: string;
  successMessage: string;
}

export function ContactForm(props: Props) {
  const { handleSubmit, register, errors } = useForm({
    mode: 'onBlur',
  });

  const handleChange = React.useCallback(event =>
    props.handleChange({...props.info, [event.target.name]: event.target.value})
  , [props]);

  return (
    <div className={'contact-us-form__composition'}>
      <ColoredIcons.LogoOnWhite />
      <p><b>Please fill required fields to contact us. We will try to help you as soon as possible:</b></p>
      <form className="contact-us__form" onSubmit={handleSubmit(props.handleSubmit)}>
        <FormControl error={errors.fullName}>
          <input name="fullName"
            ref={register({required: 'Please enter Full Name', ...nameMaxLength(150)})}
            type="text"
            placeholder="Full Name"
            value={props.info.fullName}
            onChange={handleChange}/>
        </FormControl>
        <FormControl error={errors.email}>
          <input name="email"
            ref={register({required: 'Please enter the Email', pattern: {value: emailRe, message: 'Incorrect email format'}})}
            type="email" placeholder="Email" value={props.info.email} onChange={props.handleChange}/>
        </FormControl>
        <FormControl error={errors.message}>
          <textarea ref={register({required: 'Please enter your message'})} name="message" placeholder="How can we help?" value={props.info.message} onChange={handleChange}/>
        </FormControl>
        {props.successMessage && <Message type="success">{props.successMessage}</Message>}
        {props.contactError && <Message type="error">{props.contactError}</Message>}
        <button type="submit" className="button button--primary" disabled={false}>Send</button>
      </form>
    </div>
  );
}

