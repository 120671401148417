export var isPasswordSymbols = function isPasswordSymbols(value) {
  var hasAlphaCharacters = new RegExp('^(?=.*[a-z])(?=.*[@*%!#^&$<>.()-_=+\'\\d])');
  return hasAlphaCharacters.test(value);
};
export var emailRe = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export var passwordRe = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*[\s]).{6,40}$/;
export var emojiRe = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;
var countryValidators = {
  'GB': {
    rePostCode: /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2})$/i,
    rePhone: /^((7([0-9]){9})|(0([0-9]){10}))$/,
    reOnlyPostCode: /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2})$/i
  }
};
export var getCountryValidator = function getCountryValidator(name) {
  return countryValidators[name] || {
    rePostCode: /.*/i,
    rePhone: /.*/i,
    reOnlyPostCode: /.*/i
  };
};
export var nameMaxLength = function nameMaxLength(max) {
  return {
    pattern: {
      value: new RegExp("^([a-zA-Z-'\u2019 ]){2,".concat(max, "}$"), 'i'),
      message: 'Incorrect Format'
    },
    validate: function validate(val) {
      return emptyTextFieldIsIncorrectFormat(val, 2);
    }
  };
};
export var emptyTextField = function emptyTextField(val) {
  return val.replace(/\s/g, '').length > 0 || 'Field is required';
};
export var emptyTextFieldIsIncorrectFormat = function emptyTextFieldIsIncorrectFormat(val, min) {
  return val.length === 0 || val.replace(/\s/g, '').length >= min || 'Incorrect Format';
};