import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { UserState } from '../stores/user-state';
import { observer } from 'mobx-react';
import {EnumUserDtoStatus} from 'in5pire-api'

interface Props extends RouteProps {
  user: UserState,
}

export const AuthRoute = observer(({ children, location, user, ...rest }: Props) => {
  const component = user.token != null && user.user && user.user.status === EnumUserDtoStatus.ACTIVE
    ? children
    : <Redirect
        to={{
          pathname: "/login",
          state: { from: location }
        }} />;
  return (
    <Route
      {...rest}
      children={component}
    />
  )
});
