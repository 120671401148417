import { UserState } from "./user-state"
import { observable } from "mobx";

export class RootState {

  @observable mainNavVisible = true;
  @observable mainNavActive = true;

  constructor(
    public userState: UserState,
  ) {
    this.mainNavToggle = this.mainNavToggle.bind(this);
    this.mainNavToggleActive = this.mainNavToggleActive.bind(this);
  }

  mainNavToggle() {
    this.mainNavVisible = !this.mainNavVisible;
  }
  mainNavToggleActive(value) {
    this.mainNavActive = value;
  }
}
