import * as React from 'react';
import {ColoredIcons} from 'in5pire-storybook';
import { Link } from 'react-router-dom'
enum filterTypes {
  searchTerm = 'searchTerm',
  page = 'page',
}

interface Props {
  caption: string;
  changeFilter: (type: filterTypes, value: string | number | boolean | {field: string, direction: string}) => void;
  page: number;
  total: number;
  limit: number;
  activeTab: string;
  searchTerm: string;
}

export function AgreementsHeader({caption, changeFilter, page, limit, total, searchTerm, activeTab}: Props) {
  const [search, setSearch] = React.useState('');
  React.useEffect(() => setSearch(searchTerm), [searchTerm]);
  const maxLastItem = page * limit;
  const lastItem = maxLastItem > total ? total : maxLastItem;
  const firstItem =  lastItem ? maxLastItem - limit + 1 : 0;
  return (
    <React.Fragment>
      <div className="list-headers__header1">
        <div className="list-headers__header1-caption">
          {caption}
        </div>
        <div>
          <div className="list-headers__header1-search">
            <input className="list-headers__header1-input"
              type="text"
              required
              value={search}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  changeFilter(filterTypes.searchTerm, (e.target as HTMLInputElement).value)}
              }
              }
              onChange={e => {
                if (e.target.value === '') {
                  changeFilter(filterTypes.searchTerm, e.target.value)
                }
                setSearch(e.target.value)
              }}
              placeholder="SEARCH" />
            <div className="img_search"><ColoredIcons.SearchIcon /></div>
          </div>
        </div>
      </div>
      <div className="list-headers__header2">
        <div className="list-headers__header2-tabs">
          <div className={`list-headers__tab ${activeTab === 'all' ? 'list-headers__tab_active' : ''}`}>
            <Link to="/platform/agreements/all" >ALL AGREEMENTS</Link>
          </div>
          <div className={`list-headers__tab ${activeTab === 'active' ? 'list-headers__tab_active' : ''}`}>
            <Link to="/platform/agreements/active">ACTIVE</Link>
          </div>
          <div className={`list-headers__tab ${activeTab === 'completed' ? 'list-headers__tab_active' : ''}`}>
            <Link to="/platform/agreements/completed">COMPLETED</Link>
          </div>
          <div className={`list-headers__tab ${activeTab === 'cancelled' ? 'list-headers__tab_active' : ''}`}>
            <Link to="/platform/agreements/cancelled">CANCELLED</Link>
          </div>
        </div>
        <div className="list-headers__header2-paging">
          <div className="list-headers__header2-info">Viewing {firstItem}-{lastItem} of <span>{total}</span></div>
          <div className="list-headers__header2-controls">
            <div className={`list-headers__header2-control${page === 1 ? '_disabled' : ''}`}
              onClick={() => changeFilter(filterTypes.page, page - 1)}>
              <div className="list-headers__arrow-left" />
            </div>
            <div className={`list-headers__header2-control${lastItem === total ? '_disabled' : ''}`}
              onClick={() => changeFilter(filterTypes.page, page + 1)}>
              <div className="list-headers__arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}