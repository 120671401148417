import * as React from 'react';
import { Login } from 'in5pire-storybook';
import { environment } from '../../environment/environment';
import { RootContext } from '../../stores/root-context';
import { UserState } from '../../stores/user-state';
import { LoginResponse, UserControllerService, EnumUserDtoStatus } from 'in5pire-api';
import { RouteChildrenProps } from 'react-router-dom';
import {ErrorModal} from 'in5pire-storybook';

interface Props extends RouteChildrenProps {
  user: UserState
}

export function LoginPage({history, location}: Props) {

  const rootState = React.useContext(RootContext);
  const [error, setError] = React.useState('');

  const handleLogin = React.useCallback((response: LoginResponse, remember: boolean) => {
    rootState.userState.login(response.token.replace('Bearer ', ''), remember);
    UserControllerService.getProfileUsingGet().then(response => {
      rootState.userState.setUser(response.user);
      if (response.user.status === EnumUserDtoStatus.NOT_ACTIVE) {
        setError('Something went wrong. Please contact support: support@co-funding.uk.com');
      }
      else if (location.state && location.state['from']) {
        history.push(location.state['from']);
      } else {
        history.push('/');
      }
    });
  }, [rootState, history, location]);

  React.useEffect(() => {
    if (location.state && location.state['expired']) {
      setError('Link was expired. Please repeat the forgotten password flow again.');
    }
  }, [location])

  return (
    <React.Fragment>
      {error && <ErrorModal content={error} onClose={() => setError('')}/>}
      <Login
        backUrl={environment.marketingUrl}
        onError={setError}
        onLogin={handleLogin}
      />
    </React.Fragment>
  );
}
