import { EnumBaseProjectDtoProjectType, EnumUpdateProfileRequestPersonType, EnumUpdateProfileRequestInvestorType, EnumBaseProjectDtoStatus, EnumProjectDtoPurpose } from "in5pire-api";
export function projectType(type) {
  switch (type) {
    case EnumBaseProjectDtoProjectType.COMMERCIAL_BUILD:
      return 'Commercial Build';

    case EnumBaseProjectDtoProjectType.RESIDENTIAL_BUILD:
      return 'Residental Build';

    case EnumBaseProjectDtoProjectType.REFURBISHMENT:
      return 'Refurbishment';
  }

  return 'type';
}
export function projectPurpose(type) {
  switch (type) {
    case EnumProjectDtoPurpose.DEVELOPING:
      return 'Develop';

    case EnumProjectDtoPurpose.PURCHASEA_AND_DEVELOP:
      return 'Purchase & develop';

    case EnumProjectDtoPurpose.PURCHASING:
      return 'Purchase';
  }

  return 'type';
}
export function investorType(type) {
  switch (type) {
    case EnumUpdateProfileRequestInvestorType.ADVISED_INVESTOR:
      return 'Advised Investor';

    case EnumUpdateProfileRequestInvestorType.CERTIFIED_SOPHISTICATED_INVESTOR:
      return 'Certified Sophisticated Investor';

    case EnumUpdateProfileRequestInvestorType.EVERYDAY_INVESTOR:
      return 'Everyday Investor';

    case EnumUpdateProfileRequestInvestorType.HIGH_NET_WORTH_INVESTOR:
      return 'High Net Worth Investor';

    case EnumUpdateProfileRequestInvestorType.SELF_CERTIFIED_INVESTOR:
      return 'Self-certified Sophisticated Investor';
  }
}
export function personType(type) {
  switch (type) {
    case EnumUpdateProfileRequestPersonType.INDIVIDUAL:
      return 'Individual';

    case EnumUpdateProfileRequestPersonType.PROFESSIONAL:
      return 'Company';
  }
}
export function projectStatus(type) {
  switch (type) {
    case EnumBaseProjectDtoStatus.AVAILABLE_TO_PLEDGE:
      return 'Available to Pledge';

    case EnumBaseProjectDtoStatus.COMING_SOON:
      return 'Coming Soon';

    case EnumBaseProjectDtoStatus.CANCELLED_NOT_PAID_OUT:
      return 'Canceled (Not paid out)';

    case EnumBaseProjectDtoStatus.CANCELLED_PAID_OUT:
      return 'Canceled (Paid out)';

    case EnumBaseProjectDtoStatus.DRAFT:
      return 'Draft';

    case EnumBaseProjectDtoStatus.FULLY_FUNDED_DEVELOPMENT_NOT_BEGAN:
      return 'Fully Pledged';

    case EnumBaseProjectDtoStatus.FULLY_FUNDED_DEVELOPMENT_BEGAN:
      return 'In Progress (Short Term)';

    case EnumBaseProjectDtoStatus.COMPLETE:
      return 'Completed and Paid Out';

    case EnumBaseProjectDtoStatus.COMPLETE_SHORT_TERM:
      return 'Completed and Paid Out (Short Term)';

    case EnumBaseProjectDtoStatus.ARCHIVED:
      return 'Archived';

    case EnumBaseProjectDtoStatus.FULLY_FUNDED_DEVELOPMENT_COMPLETE_LONG:
      return 'In progress (Long Term)';

    default:
      return type;
  }
}