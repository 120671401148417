import { observable, computed, action } from 'mobx';
import { LoginResponse, UserDto, UserControllerService } from 'in5pire-api';
import cookies from 'js-cookie';

const tokenKey = 'token';

export class UserState {

  @observable private loginData: LoginResponse = null;
  @observable user: UserDto = null;

  @computed get token() {
    return this.loginData != null ? this.loginData.token : null;
  }

  @computed get role() {
    return this.loginData != null ? this.loginData.role : null;
  }

  constructor() {
    this.setToken(cookies.get(tokenKey));
  }

  login(token: string, remember: boolean) {
    this.setToken(token);
    cookies.set(tokenKey, token, { expires: remember ? 30 : undefined })
  }

  setToken(token: string | null) {
    if (token == null) return;
    try {
      this.loginData = parseToken(token);
    } catch (e) {
      console.error('Error parsing token', e);
      this.logout();
    }
  }

  @action setUser(user: UserDto) {
    this.user = user;
  }

  @action updateUser() {
    UserControllerService.getProfileUsingGet().then(response => {
      this.setUser(response.user);
    }).catch(console.log);
  }

  logout() {
    this.loginData = null;
    this.user = null;
    cookies.remove(tokenKey);
  }

}

function parseToken(token: string): LoginResponse {
  const parts = token.split('.');
  const data = JSON.parse(atob(parts[1]));
  return {
    id: data.USER_ID_PARAM,
    role: data.USER_ROLE_PARAM,
    email: '',
    token: token,
  } as LoginResponse;
}
