import * as React from 'react';

import './draft-projects.scss';
import {Card} from '../../../Dashboard/components/atoms/Card/Card';

interface Props {
  amount: string,
  percent: string,
}

export function DraftProjectsCard(props: Props) {
  return (
    <Card cardTitle='projects in draft'
          className="draft-projects__card">
      <div className="draft-projects__content">
        <div className="draft-projects__info">
          <div className="draft-projects__amount">
            {props.amount}
          </div>
          <div className="draft-projects__percent">
            ({props.percent}% <span>of the total amount</span>)
          </div>
        </div>
        <div className="draft-projects__indicator-wrapper">
          <div className="draft-projects__indicator" style={{width: `${props.percent}%`}}>
          </div>
        </div>
      </div>
    </Card>
  );
}
