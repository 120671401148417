import * as React from 'react';

import {Table} from '../Table/Table';
import './history-paid-out.scss';

interface Props {
  projectsData: { projectName: string, amount: string, id: string }[],
  onRowClick: (value: string) => void
}
const header = {
  projectName: 'Project Name',
  amount: 'Amount',
};

export function HistoryPaidOutTable(props: Props) {
  return <Table header={header}
                headerOrder={['projectName', 'amount']}
                data={props.projectsData}
                dataOrder={['projectName', 'amount']}
                evenRowColor='grey'
                onRowClick={props.onRowClick}
                headerColor='red'
                className='paid-out'/>
}
