import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import {EnumUserDtoKycStatus, EnumLoginResponseRole} from 'in5pire-api';
import { UserState } from '../stores/user-state';


interface Props extends RouteProps {
  user: UserState,
  exact?: boolean
}

export const KycRoute = observer(({ children, location, user, ...rest }: Props) => {
  const component = user.user && (user.user.kycStatus === EnumUserDtoKycStatus.APPROVED)
    ? <Redirect to={{ pathname: "/" }} />
    : children;
  return (
    <Route
      {...rest}
      children={component}
    />
  )
});
export const NoKycRoute = observer(({ children, location, exact, user, ...rest }: Props) => {
  const component = user.user && user.role !== EnumLoginResponseRole.ADMIN && (user.user.kycStatus !== EnumUserDtoKycStatus.APPROVED)
    ? <Redirect to={{ pathname: "/platform/kyc" }} />
    : children;
  return (
    <Route
      {...rest}
      exact={!!exact}
      children={component}
    />
  )
});
export const ProfileRoute = observer(({ children, location, exact, user, ...rest }: Props) => {
  const component = user.user && user.role === EnumLoginResponseRole.ADMIN
    ? <Redirect to={{ pathname: "/" }} />
    :  user.user && user.user.kycStatus !== EnumUserDtoKycStatus.APPROVED
    ? <Redirect to={{ pathname: "/platform/kyc" }} />
    : children;
  return (
    <Route
      {...rest}
      exact={!!exact}
      children={component}
    />
  )
});