export var PledgeSource;

(function (PledgeSource) {
  PledgeSource["E_WALLET"] = "E_WALLET";
  PledgeSource["WIRE"] = "WIRE";
})(PledgeSource || (PledgeSource = {}));

;
export var PledgeSignatureType;

(function (PledgeSignatureType) {
  PledgeSignatureType[PledgeSignatureType["DRAW"] = 0] = "DRAW";
  PledgeSignatureType[PledgeSignatureType["TYPE"] = 1] = "TYPE";
  PledgeSignatureType[PledgeSignatureType["UPLOAD"] = 2] = "UPLOAD";
})(PledgeSignatureType || (PledgeSignatureType = {}));

export var PledgeAmountShortOption;

(function (PledgeAmountShortOption) {
  PledgeAmountShortOption["MINIMUM"] = "MINIMUM";
  PledgeAmountShortOption["CUSTOM"] = "CUSTOM";
  PledgeAmountShortOption["REMAINING"] = "REMAINING";
})(PledgeAmountShortOption || (PledgeAmountShortOption = {}));

export var PledgeStep;

(function (PledgeStep) {
  PledgeStep[PledgeStep["SOURCE"] = 0] = "SOURCE";
  PledgeStep[PledgeStep["STRATEGY"] = 1] = "STRATEGY";
  PledgeStep[PledgeStep["TERM"] = 2] = "TERM";
  PledgeStep[PledgeStep["AMOUNT"] = 3] = "AMOUNT";
  PledgeStep[PledgeStep["STATEMENT"] = 4] = "STATEMENT";
  PledgeStep[PledgeStep["CONDITIONS"] = 5] = "CONDITIONS";
  PledgeStep[PledgeStep["E_SIGNATURE"] = 6] = "E_SIGNATURE";
  PledgeStep[PledgeStep["PAYMENT"] = 7] = "PAYMENT";
  PledgeStep[PledgeStep["SUCCESS"] = 8] = "SUCCESS";
})(PledgeStep || (PledgeStep = {}));