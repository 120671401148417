import _slicedToArray from "/builds/in5pire/in5pire-web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// const formatter = new Intl.DateTimeFormat('en-GB')

/* export function formatDate(date) {-
  return formatter.format(new Date(date));
}; */
export function formatDate(date) {
  return [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(function (e) {
    return e > 9 ? e : '0' + e;
  }).join('-');
}
export function formatDateToTimestamp(date) {
  var _date$split$map = date.split('-').map(function (item) {
    return parseInt(item);
  }),
      _date$split$map2 = _slicedToArray(_date$split$map, 3),
      Y = _date$split$map2[0],
      M = _date$split$map2[1],
      D = _date$split$map2[2];

  console.log(date, new Date(Y, M - 1, D).getTime());
  return new Date(Y, M - 1, D).getTime();
}
export function countMonths(startDate, endDate) {
  if (!startDate || !endDate) {
    return 0;
  }

  var start = startDate instanceof Date ? formatDate(startDate) : startDate;
  var end = endDate instanceof Date ? formatDate(endDate) : endDate;
  var startYear = parseInt(start.split('-')[0]);
  var endYear = parseInt(end.split('-')[0]);
  var startMonth = parseInt(start.split('-')[1]);
  var endMonth = parseInt(end.split('-')[1]) + (endYear - startYear) * 12;
  return endMonth - startMonth;
}