export var backendCodes = {
  1: "Something went wrong. Please try again",
  2: "Invalid data",
  3: "Unable to upload file",
  4: "File processing error",
  5: "File does not exist",
  6: "Need token",
  7: "Incorrect Email or Password",
  8: "Invalid token",
  9: "Illegal file url",
  10: "Illegal access",
  11: "Illegal role",
  12: "User already exists",
  13: "The user doesn't exist",
  14: "KYC procedure finished",
  15: "KYC procedure not finished",
  16: "KYC procedure in progress",
  17: "Project does not exist",
  18: "Mango pay communication exception"
};