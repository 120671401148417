import * as React from 'react';

import './pledged-investment-card.scss';
import {Card} from '../atoms/Card/Card';
import {ColoredIcons} from 'in5pire-storybook';

interface Props {
  totalInvestment: string,
  className?: string
}

export function PledgedInvestmentCard(props: Props) {
  return (
    <Card cardTitle='pledged investment'
          cardSubTitle='Total'
          icon={ColoredIcons.InvestmentIcon()}
          colorUnderline='pink'
          className={props.className}>
      <span className='total-money'>
        { props.totalInvestment }
      </span>
    </Card>
  );
}
