import * as React from 'react';

import {Table} from '../Table/Table';
import './last-projects.scss';

interface Props {
  projectsData: { projectName: string, status: string }[],
  onRowClick: (value: string | number) => void;
}
const header = {
  projectName: 'Name',
  status: 'Status',
};

export function LastProjectTable(props: Props) {
  return <Table header={header}
                headerOrder={['projectName', 'status']}
                data={props.projectsData}
                dataOrder={['projectName', 'status']}
                evenRowColor='grey'
                onRowClick={props.onRowClick}
                headerColor='violet'
                className='last-projects'/>
}
