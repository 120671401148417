import { emailRe, emptyTextFieldIsIncorrectFormat } from '../../validators/index';
export var nameMaxLength = function nameMaxLength(max) {
  return {
    pattern: {
      value: new RegExp("^([a-zA-Z-'\u2019 ]){2,".concat(max, "}$"), 'i'),
      message: 'Incorrect Format'
    },
    validate: function validate(val) {
      return emptyTextFieldIsIncorrectFormat(val, 2);
    }
  };
};
export var addressMaxLength = function addressMaxLength(max) {
  return {
    pattern: {
      value: new RegExp("^([a-zA-Z-'\"\u2019  0-9,:;./]){2,".concat(max, "}$"), 'i'),
      message: 'Incorrect Format'
    },
    validate: function validate(val) {
      return emptyTextFieldIsIncorrectFormat(val, 2);
    }
  };
};
export var required = {
  required: 'Please fill required field'
};
export var sortCode = {
  pattern: {
    value: /^[0-9]{6}$/,
    message: 'Incorrect Format'
  }
};
export var accountNumber = {
  pattern: {
    value: /^[0-9]{8}$/,
    message: 'Incorrect Format'
  }
};
export var companyNumber = {
  pattern: {
    value: /^[0-9a-zA-Z]{8}$/,
    message: 'Incorrect Format'
  }
};
export var email = {
  required: 'Field is required',
  pattern: {
    value: emailRe,
    message: 'Incorrect format'
  }
};
export var website = {
  pattern: {
    value: /^([a-zA-Z-:'"’ 0-9,./]){7,50}$/i,
    message: 'Incorrect format'
  },
  validate: function validate(val) {
    return emptyTextFieldIsIncorrectFormat(val, 7);
  }
};