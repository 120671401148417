import * as React from 'react';
import {FunctionComponent} from 'react';

import './card.scss';
import {CardHeader} from '../CardHeader/CardHeader';

interface Props {
  cardTitle?: string,
  cardSubTitle?: string,
  icon?: JSX.Element,
  colorUnderline?: 'pink' | 'orange' | 'blue',
  className?: string,
}

export const Card: FunctionComponent<Props> = (props) => {
  return (
    <div className={`card card--${props.colorUnderline} ${props.className}`}>
      {props.cardTitle &&
      <CardHeader cardTitle={props.cardTitle}
                  cardSubTitle={props.cardSubTitle}
                  icon={props.icon} />}
        {props.children}
    </div>
  );
};
