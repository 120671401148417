import * as React from 'react';
import './main-nav.scss';
import { NavLink } from 'react-router-dom';
import { RootContext } from '../../stores/root-context';
import { observer } from 'mobx-react';
import {ColoredIcons} from 'in5pire-storybook';
import {EnumUserDtoKycStatus, EnumLoginResponseRole} from 'in5pire-api';


interface Props {
  nav: any[];
}

export const MainNav = observer((props: Props) => {

  const { mainNavVisible, mainNavActive, userState } = React.useContext(RootContext);
  const isNotActive = userState.role !== EnumLoginResponseRole.ADMIN && (!mainNavActive ||  userState.user.kycStatus !== EnumUserDtoKycStatus.APPROVED);
  return (
    <nav className={'main-nav' + (mainNavVisible ? ' visible' : '') + (isNotActive ? ' main-nav_disabled' : '')}>
      <div className="main-nav__logo"><ColoredIcons.LogoOnBlack /></div>
      <ul>
        {props.nav.map(item => (
          <li key={item.path}>
            <NavLink exact={item.exact} activeClassName="active" to={item.path}>{item.name}</NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
});
