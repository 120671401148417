import * as React from 'react';

import {Table} from '../Table/Table';

interface Props {
  projectsData: { title: string, status: string, id: string}[],
  headerColor?: 'orange' | 'violet',
  evenRowColor?: 'grey'| 'violet',
  onRowClick: (id: string) => void;
}
const header = {
  projectName: 'Project Name',
  status: 'Status',
};

export function ProjectsTable(props: Props) {
  return <Table header={header}
                headerOrder={['projectName', 'status']}
                data={props.projectsData}
                dataOrder={['title', 'status']}
                evenRowColor={props.evenRowColor}
                onRowClick={props.onRowClick}
                headerColor={props.headerColor}/>
}
