import * as React from 'react';

import './table.scss';

interface Props {
  headerOrder: string[],
  dataOrder: string[],
  header: { [key: string]: string },
  data: { [key: string]: string | number }[],
  headerColor?: 'orange' | 'violet' | 'blue-green' | 'red',
  evenRowColor?: 'grey'| 'violet',
  className?: string,
  onRowClick?: (value: string) => void;
}

export const Table = (props: Props) =>  {
  return (
    <table className={`table ${props.className} ${props.headerColor || ''} row-${props.evenRowColor || ''}`}>
      <thead>
        <tr>
          {
            props.headerOrder.map((keyTitle) => (<th key={keyTitle}>{props.header[keyTitle]}</th>) )
          }
        </tr>
      </thead>
      <tbody>
        {props.data.map((row,index) => (
          <tr key={row[props.dataOrder[0]] + index.toString()} onClick={e => props.onRowClick ? props.onRowClick(row.id as any) : {}}>
            {
              props.dataOrder.map((keyData) => (<td key={keyData}>{ row[keyData] }</td>))
            }
          </tr>
        ))}
      </tbody>
    </table>
  );
}
