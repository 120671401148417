import * as React from 'react';

import './wallet-history-table.scss';

interface Props {
  data: {
    amount: string,
    tsxId: string,
    type: string
    status: string,
    date: string
  }[],
}

export const WalletHistoryTable = (props: Props) =>  {
  return (
    <table className={`history-table`}>
      <tbody>
        {props.data.map((row, index) => (
          <tr key={row.tsxId + index}>
            <td>
              <p className='amount'>{row.amount}</p>
              <p className='date'>{row.date}</p>
            </td>
            <td>
              <p className='tsxId'>{row.tsxId}</p>
              <p className='type'>{row.type}</p>
            </td>
            <td className={`status ${row.status === 'failed' ? 'pink' : row.status === 'pending' ? 'orange' : 'green'}`}>
              {row.status}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
};
