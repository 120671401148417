import {observer} from 'mobx-react';
import React from 'react';
import {PageTitle} from '../../components/PageTitle/PageTitle';
import '../Dashboard/dashboard-page.scss';
import {projectStatus, ErrorModal } from 'in5pire-storybook';
import {LastProjectsCard} from './components/LastProjectsCard/LastProjectsCard';
import {AdminCard} from './components/AdminCard/AdminCard';
import {DraftProjectsCard} from './components/DraftProjectsCard/draftProjectsCard';
import {ChartCard} from '../Dashboard/components/ChartCard/ChartCard';
import {Selector} from '../Dashboard/components/Selector/Selector';
import {toMoneySum} from '../../helpers';
import { RouteComponentProps } from 'react-router-dom';
import { DashboardControllerService, DashboardResponse, EnumBaseProjectDtoStatus } from 'in5pire-api';
import {resolveError, roundFloatNumber} from '../../helpers';

const P_LIMIT = 8;

interface LocationParams {
  filterParam?: 'MONTH' | 'HALF_YEAR' | 'YEAR' | 'ALL_TIME' | number;
  pageParam?: number;
}

const DEFAULT_INFO: DashboardResponse = {
  projectsStatistic: Object.keys(EnumBaseProjectDtoStatus).reduce((res, cur) => ({...res, [cur]: 0}), {}),
  registeredUsers: {
    progress: 0,
    dailyUsers: 0,
    periodUsers: 0
  },
  interestPaidOut: {
    progress: 0,
    dailyInterestPaidOut: 0,
    periodInterestPaidOut: 0
  },
  pledgedInvestment: {
    progress: 0,
    dailyPledgedInvestment: 0,
    periodPledgedInvestment: 0
  }
}

export const AdminDashboardPage = observer((props: RouteComponentProps) => {
  const [filter, setFilter] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(100);
  const [tableData, setTableData] = React.useState(null);
  const [loadingFilter, setLoadingFilter] = React.useState(false);
  const [loadingPage, setLoadingPage] = React.useState(false);
  const [error, setError] = React.useState('');
  const [info, setInfo] = React.useState(DEFAULT_INFO as any);

  const chartOngoing = [
    { itemName: projectStatus(EnumBaseProjectDtoStatus.COMING_SOON), amount: info.projectsStatistic.COMING_SOON, color: '#5A84FB' },
    { itemName: projectStatus(EnumBaseProjectDtoStatus.AVAILABLE_TO_PLEDGE), amount: info.projectsStatistic.AVAILABLE_TO_PLEDGE, color: '#F46775' },
    { itemName: projectStatus(EnumBaseProjectDtoStatus.FULLY_FUNDED_DEVELOPMENT_NOT_BEGAN), amount: info.projectsStatistic.FULLY_FUNDED_DEVELOPMENT_NOT_BEGAN, color: '#3AE1AB' },
    { itemName: projectStatus(EnumBaseProjectDtoStatus.FULLY_FUNDED_DEVELOPMENT_BEGAN), amount: info.projectsStatistic.FULLY_FUNDED_DEVELOPMENT_BEGAN , color: '#EE8427' },
  ];
  const chartOngoingCaption = chartOngoing.reduce((res, cur) => res + cur.amount, 0);

  const chartCompleted = [
    { itemName: projectStatus(EnumBaseProjectDtoStatus.FULLY_FUNDED_DEVELOPMENT_COMPLETE_LONG), amount: info.projectsStatistic.FULLY_FUNDED_DEVELOPMENT_COMPLETE_LONG, color: '#62C903' },
    { itemName: projectStatus(EnumBaseProjectDtoStatus.COMPLETE_SHORT_TERM), amount: info.projectsStatistic.COMPLETE_SHORT_TERM, color: '#5A84FB' },
    { itemName: projectStatus(EnumBaseProjectDtoStatus.COMPLETE), amount: info.projectsStatistic.COMPLETE, color: '#3AE1AB' },
    { itemName: projectStatus(EnumBaseProjectDtoStatus.CANCELLED_NOT_PAID_OUT), amount: info.projectsStatistic.CANCELLED_NOT_PAID_OUT, color: '#EE8427' },
    { itemName: projectStatus(EnumBaseProjectDtoStatus.CANCELLED_PAID_OUT), amount: info.projectsStatistic.CANCELLED_PAID_OUT, color: '#F46775' },
  ];

  const chartCompletedCaption = chartCompleted.reduce((res, cur) => res + cur.amount, 0);

  const allProjectsCount = Object.keys(info.projectsStatistic).reduce((r, cur) => r + info.projectsStatistic[cur], 0);
  const draftPercent = allProjectsCount ? ((info.projectsStatistic.DRAFT / allProjectsCount) * 100).toFixed(2) : 0;

  const onSettingData = React.useCallback(({pageParam, filterParam}: LocationParams) => {
    if (filterParam) {
      props.history.push(`/platform/dashboard/?filter=${filterParam}&page=1`);
    } else {
      props.history.push(`/platform/dashboard/?filter=${filter}&page=${pageParam}`);
    }
  }, [props.history, filter]);

  React.useEffect(() => {
    if (!props.location.search) {
      setFilter('ALL_TIME');
      setPage(1);
      return;
    }
    const searchParams = new URLSearchParams(props.location.search);
    const newPage = parseInt(searchParams.get('page'));
    const newFilter = searchParams.get('filter');
    setFilter(newFilter as 'MONTH' | 'HALF_YEAR' | 'YEAR' | 'ALL_TIME');
    setPage(newPage);
  }, [props.location]);

  const onClose = React.useCallback(() => {
    setError('');
  }, [setError]);

  React.useEffect(() => {
    if (!filter) {
      return
    }
    setLoadingFilter(true);
    DashboardControllerService.getDashboardUsingGet({filter})
      .then(resp => {
        setInfo(resp);
        setLoadingFilter(false);
      })
      .catch(error => {
        setLoadingFilter(false);
        setInfo(DEFAULT_INFO);
        setError(resolveError(error));
      });
  }, [filter]);

  React.useEffect(() => {
    if (!filter || !page) {
      return;
    }
    setLoadingPage(true);
    DashboardControllerService.getLastProjectForDashboardUsingGet({filter, limit: P_LIMIT, offset: P_LIMIT * (page - 1)})
      .then(({totalCount, projects}) => {
        setTotal(totalCount);
        const tableData = projects.projects.map(({title, status, id}) => ({
          projectName: title,
          status: projectStatus(status),
          id
        }));
        setTableData(tableData);
        setLoadingPage(false);
      }).catch(error => {
        setLoadingPage(false);
        setTableData(null);
        setError(resolveError(error));
      });
  }, [filter, page]);

  return (
    <div className={loadingFilter || loadingPage ? 'disable-filters' : ''}>
      {error && <ErrorModal onClose={onClose} content={error} />}
      <PageTitle pageTitle="Dashboard" />
      <div className='dashboard-page dashboard-page_admin'>
        <Selector onSelect={filter => onSettingData({filterParam: filter})} selected={filter} />
        <div className='cards__wrapper'>
          <div className={`cards__row cards__top-wrapper margin-bottom ${loadingFilter ? 'data-loading' : ''}`}>
            <AdminCard className='cards__row_top-item'
                       numberField={`${toMoneySum(info.pledgedInvestment.periodPledgedInvestment)}`}
                       icon="invest"
                       progress={roundFloatNumber(info.pledgedInvestment.progress, 2)}
                       bottomCaption="Daily investment:"
                       bottomValue={`${toMoneySum(info.pledgedInvestment.dailyPledgedInvestment)}`}
                       arrow={filter === 'ALL_TIME' ? 'none' : info.pledgedInvestment.progress >= 0 ? 'up' : 'down'}
                       cardTitle="pledged investment"
                       color="blue"/>
            <AdminCard className='cards__row_top-item'
                       numberField={`${toMoneySum(info.interestPaidOut.periodInterestPaidOut)}`}
                       progress={roundFloatNumber(info.interestPaidOut.progress, 2)}
                       bottomCaption="Daily interest paid out:"
                       bottomValue={`${toMoneySum(info.interestPaidOut.dailyInterestPaidOut)}`}
                       icon="point"
                       arrow={filter === 'ALL_TIME' ? 'none' : info.interestPaidOut.progress >= 0 ? 'up' : 'down'}
                       cardTitle="interest paid out"
                       color="pink"/>
            <AdminCard className='cards__row_top-item'
                       numberField={info.registeredUsers.periodUsers.toString()}
                       progress={roundFloatNumber(info.registeredUsers.progress, 2)}
                       bottomCaption="Daily registered users:"
                       bottomValue={roundFloatNumber(info.registeredUsers.dailyUsers, 2)}
                       icon="user"
                       arrow={filter === 'ALL_TIME' ? 'none' : info.registeredUsers.progress >= 0 ? 'up' : 'down'}
                       cardTitle="registered users"
                       color="orange"/>
          </div>
        </div>
        <div className='cards__wrapper'>
          <div className={`cards__left ${loadingFilter ? 'data-loading' : ''}`}>
            <ChartCard className='margin-bottom double-chart'
                       projectsStatistic={chartOngoing}
                       projectsStatistic2={chartCompleted}
                       cardTitle='projects statistic'
                       caption2={{title: 'completed', count: chartCompletedCaption}}
                       caption={{title: 'ongoing', count: chartOngoingCaption}}/>
            <DraftProjectsCard amount={info.projectsStatistic.DRAFT} percent={draftPercent.toString()}/>
          </div>
          <div className='cards__right'>
            {tableData && <LastProjectsCard projectsData={tableData}
                                            className={`admin-dashboard__last-projects ${loadingPage ? 'data-loading' : ''}`}
                                            changePage={num => {onSettingData({pageParam: num})}}
                                            onRowClick={id => props.history.push(`/platform/projects/edit/${id}`)}
                                            page={page} total={total} />}
          </div>
        </div>
      </div>
    </div>
  );
});
