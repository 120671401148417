import * as React from 'react';

import {Table} from '../Table/Table';
import './investments.scss';

interface Props {
  projectsData: { projectName: string, date: string, amount: string, status: string }[],
  onRowClick: (value: string) => void;
}
const header = {
  projectName: 'Project Name',
  status: 'Status',
  date: 'Date',
  amount: 'Amount',
};

export function InvestmentsTable(props: Props) {
  return <Table header={header}
                headerOrder={['projectName', 'date', 'amount', 'status']}
                data={props.projectsData}
                onRowClick={props.onRowClick}
                dataOrder={['projectName', 'date', 'amount', 'status']}
                evenRowColor='grey'
                headerColor='blue-green'
                className='investments'/>
}
