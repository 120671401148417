import * as React from 'react';
import { RootContext } from '../../stores/root-context';

import './top-nav.scss';
import { observer } from 'mobx-react';
import { NavLink, useLocation } from 'react-router-dom';
import {SvgIcons, Modal, Modes, Message, validators, FormControl, ColoredIcons} from "in5pire-storybook";
import {useState} from 'react';
import { EnumLoginResponseRole, AuthControllerService } from 'in5pire-api';
import { useForm } from 'react-hook-form';
import {EnumUserDtoKycStatus} from 'in5pire-api';


export const TopNav = observer(() => {
  const { mainNavToggle, mainNavVisible, userState } = React.useContext(RootContext);
  const [showMenu, setShowMenu] = useState(false);
  const [showInviteLink, setShowInviteLink] = useState(false);
  const location = useLocation();
  const logout = () => {
    userState.logout();
  };

  React.useEffect(() => {
    setShowMenu(false);
  }, [location]);
  const isProfileActive = userState.user.kycStatus === EnumUserDtoKycStatus.APPROVED;
  const settings = (
    <nav className={`top-nav__user-settings${userState.role === EnumLoginResponseRole.ADMIN ? ' logout-only' : ''}`}>
      {userState.role !== EnumLoginResponseRole.ADMIN &&
      <NavLink to="/platform/profile" className={`top-nav__menu-item ${isProfileActive ? '' : 'disabled-menu'}`}>
        <SvgIcons iconType={'profile'}/>
        My Profile
      </NavLink>}
      <NavLink to="/" className="top-nav__menu-item" onClick={logout}>
        <SvgIcons iconType={'logout'}/>
        Logout
      </NavLink>
    </nav>
  );

  return (
    <div className="top-nav">
      <button className="top-nav__main-nav-toggle" onClick={mainNavToggle}>
        <SvgIcons iconType={'burger'}/>
        {mainNavVisible ? 'Hide menu' : 'Show menu'}
      </button>
      {userState.user != null && (
        <div className="top-nav--right">
          {userState.role === EnumLoginResponseRole.ADMIN &&
            <button type="button" className="button button--primary top-nav__registration-link" onClick={() => setShowInviteLink(true)}>
              Generate registration link
            </button>
          }
          <div className="top-nav__user-menu">
            <button
              className="top-nav__user"
              onClick={() => setShowMenu(!showMenu)}>
              {/*onBlur={() => setUserMenu({...userMenu, isShowMenu: false})}*/}
              <div className="top-nav__userpic" style={userState.user.photo ? {backgroundImage: `url(${userState.user.photo})`} : {}}>
              </div>
              {
                userState.user.fullName
                  ? userState.user.fullName
                  : userState.user.email
              }
            </button>
            {showMenu ? settings : null}
          </div>
        </div>
      )}
      {showInviteLink && <GenerateLinkModal onClose={() => {setShowInviteLink(false)}}></GenerateLinkModal>}
    </div>
  );
});


function GenerateLinkModal(props) {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const { handleSubmit, register, errors, reset } = useForm<{email: string}>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
  });

  const handleSend = React.useCallback(({email}) => {
    setLoading(true);
    setSuccess(false);
    setError(null);
    AuthControllerService.sendInviteLinkUsingPost({email}).then(response => {
      setSuccess(true);
      reset();
    }).catch(e => {
      const {errorCode, message} = e.response.data;
      if (errorCode === 12) {
        setError('There is already an account associated with this email');
      } else {
        setError(message);
      }
    }).finally(() => {
      setLoading(false);
    })
  }, [reset]);

  return (
    <Modal mode={Modes.white} onClose={props.onClose} customClass={`send-registration-modal__content ${props.customClass}`}>
      <ColoredIcons.LogoOnWhite />
      <p>Please enter email address of an investor to send a <strong>registration link</strong>.</p>
      {error != null && <Message type="error">{error}</Message>}
      <form onSubmit={handleSubmit(handleSend)}>
        <fieldset disabled={loading}>
          <FormControl error={errors.email}>
            <input ref={register({required: 'Email required', pattern: {value: validators.emailRe, message: 'invalid email'}})} name="email" type="text" placeholder="Email" />
          </FormControl>
          <div className="control control--submit">
            <button className="button button--primary">Send link</button>
          </div>
        </fieldset>
      </form>
      {success && <p className="send-registration-modal__success">Invite link has been sent successfully.</p>}
    </Modal>
  )
}