import * as React from 'react';
import './last-projects-card.scss';
import {Card} from '../../../Dashboard/components/atoms/Card/Card';
import {Paginator} from 'in5pire-storybook';
import {LastProjectTable} from '../../../Dashboard/components/atoms/LastProjectsTable/LastProjectsTable';

interface Props {
  projectsData: any[], // todo: add good type
  changePage: (payload: number) => void,
  className?: string,
  page?: number;
  total?: number;
  onRowClick: (value: string | number) => void;
}

export const LastProjectsCard = (props: Props) => {
  return (
    <Card cardTitle='latest projects'
          className={props.className}>
      <LastProjectTable projectsData={props.projectsData} onRowClick={props.onRowClick}/>
      <Paginator page={props.page} total={props.total} perPageLimit={8} changePage={props.changePage}/>
    </Card>
  );
};
