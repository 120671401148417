import React from 'react';
import {
  AgreementsControllerService
} from 'in5pire-api';
import {MyDocument} from './doc';
import { PDFViewer } from '@react-pdf/renderer';

import { RouteComponentProps } from 'react-router-dom';

export const LogsPdf = ({match, history, location}: RouteComponentProps) => {

  React.useEffect(() => {
    if(location.state && location.state['logs']) {
      return;
    }
    const id = parseInt(match.params['id'], 10);
    AgreementsControllerService.getAgreementLogsUsingGet({id}).then(({logs}) => {
      history.replace({pathname: location.pathname, state: {logs}})
    });
  }, [match.params, location, history]);
 
  return (
    <>
      {location.state && location.state['logs'] ?
        <PDFViewer style={{width: '100%', height: '800px'}}>
          <MyDocument logs={location.state['logs']} />
        </PDFViewer> : <></>
      }
    </>
  );
}