import * as React from 'react';

import './wallet-card.scss';
import {Card} from '../atoms/Card/Card';
import {Paginator} from 'in5pire-storybook';

import {Wallet} from '../atoms/Wallet/Wallet';
import {WalletHistoryTable} from '../atoms/WalletHistoryTable/WalletHistoryTable';

interface Props {
  holder: string,
  balance: string,
  transactions?: {
    amount: string,
    tsxId: string,
    type: string,
    status: string,
    date: string
  }[],
  changePage?: (payload: number) => void,
  onWithdrawClick: () => void,
  onFundClick: () => void,
  className?: string,
  page: number;
  total: number;
}

export const WalletCard = (props: Props) => {
  return (
    <Card colorUnderline='blue' className={`wallet-card ${props.className}`}>
      <Wallet holder={props.holder}
              balance={props.balance}
              onFundClick={props.onFundClick}
              onWithdrawClick={props.onWithdrawClick}/>
      <WalletHistoryTable data={props.transactions} />
      <Paginator page={props.page} total={props.total} perPageLimit={15} changePage={props.changePage} />
    </Card>
  );
};
