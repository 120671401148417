import * as React from 'react';
import './admin-card.scss';
import {Card} from '../../../Dashboard/components/atoms/Card/Card';
import {ColoredIcons} from 'in5pire-storybook';

interface Props {
  cardTitle: string;
  color: "pink" | "orange" | "blue";
  numberField: string;
  progress: string;
  icon: "point" | "invest" | "user"
  className?: string;
  bottomCaption: string;
  bottomValue: string;
  arrow?: "up" | "down" | "none";
}
const icons = {
  invest: ColoredIcons.InvestmentIcon(),
  point: ColoredIcons.OrangePointIcon(),
  user: ColoredIcons.UserIcon()
}


export function AdminCard(props: Props) {
  return (
    <Card cardTitle={props.cardTitle}
               cardSubTitle='Total'
               colorUnderline={props.color}
               icon={icons[props.icon]}
               className={`${props.className} admin-card`}>
      <div className='admin-card__info'>
        <span className='admin-card__number'>{ props.numberField }</span>
        <span className={props.arrow === 'none' ?
        'admin-card__progress admin-card__progress_none' :
        props.arrow === 'up'  ? 'admin-card__progress admin-card__progress_up' : 'admin-card__progress'}>
          Progress: <strong>{ props.progress }%</strong>
          {ColoredIcons.Arrow()}
        </span>
      </div>
      <div className='admin-card__bottom'>
        <div className='admin-card__bottom-caption'>{props.bottomCaption}</div>
        <div className='admin-card__bottom-value'>{props.bottomValue}</div>
      </div>
    </Card>
  );
}
