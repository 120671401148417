import { EnumProjectDtoStatus, EnumBaseProjectDtoStatus } from 'in5pire-api';
import { ProjectStatus } from '../create-project/ProjectStatus';
export function getAvailableStatuses(status) {
  switch (status) {
    case EnumBaseProjectDtoStatus.DRAFT:
      return new Set([EnumProjectDtoStatus.COMING_SOON, EnumBaseProjectDtoStatus.DRAFT, EnumProjectDtoStatus.ARCHIVED]);

    case EnumProjectDtoStatus.COMING_SOON:
      return new Set([EnumProjectDtoStatus.ARCHIVED, EnumProjectDtoStatus.AVAILABLE_TO_PLEDGE]);

    case EnumProjectDtoStatus.AVAILABLE_TO_PLEDGE:
      return new Set([EnumProjectDtoStatus.CANCELLED_NOT_PAID_OUT]);

    case EnumProjectDtoStatus.FULLY_FUNDED_DEVELOPMENT_NOT_BEGAN:
      return new Set([EnumProjectDtoStatus.FULLY_FUNDED_DEVELOPMENT_BEGAN, EnumProjectDtoStatus.CANCELLED_NOT_PAID_OUT]);

    case EnumProjectDtoStatus.FULLY_FUNDED_DEVELOPMENT_BEGAN:
      return new Set([EnumProjectDtoStatus.CANCELLED_NOT_PAID_OUT]);

    case EnumProjectDtoStatus.COMPLETE_SHORT_TERM:
      return new Set([EnumProjectDtoStatus.FULLY_FUNDED_DEVELOPMENT_COMPLETE_LONG, EnumProjectDtoStatus.CANCELLED_NOT_PAID_OUT]);

    case EnumProjectDtoStatus.COMPLETE:
    case EnumProjectDtoStatus.CANCELLED_PAID_OUT:
      return new Set([EnumProjectDtoStatus.ARCHIVED]);

    default:
      return new Set([]);
  }
}