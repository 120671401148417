import * as React from 'react';

import { PasswordReset } from 'in5pire-storybook';
import {AuthControllerService, EnumValidateTokenFromLinkRequestTokenType} from 'in5pire-api';
import { RouteComponentProps, Redirect } from 'react-router-dom';

enum Statuses {
  wait = 0,
  error = 1,
  success = 2
}
export function NewPasswordPage({ match, history }: RouteComponentProps) {

  const handleReset = React.useCallback(() => {
    history.push('/');
  }, [history]);

  const [status, setStatus] = React.useState(Statuses.wait);

  React.useEffect(() => {
    AuthControllerService.validateTokenUsingPost({request: {
      token: match.params['token'],
      tokenType: EnumValidateTokenFromLinkRequestTokenType.RESTORE_PASSWORD
    }})
      .then(() => setStatus(Statuses.success))
      .catch(() => setStatus(Statuses.error));
  }, [match.params]);
  return (
    <React.Fragment>
      {
        status === Statuses.wait ? <React.Fragment /> :
          status === Statuses.error ? <Redirect to={{
            pathname: '/login',
            state: {expired: true}
          }} /> :
            <PasswordReset
              onError={() => {}}
              onReset={handleReset}
              token={match.params['token']}
            />
      }
    </React.Fragment>
  );
}
