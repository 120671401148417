import { EnumProjectRequestStatus } from "in5pire-api";
export var checkRequiredDocuments = function checkRequiredDocuments(form) {
  var checkRequiredDocument = form.documents.find(function (item) {
    return item.required && item.url === '';
  });
  return checkRequiredDocument !== undefined ? false : true;
};
export var checkInvestmentStrategies = function checkInvestmentStrategies(form, allowLongTerm) {
  var shortValid = form.shortDebtExpectedRateDebt || form['shortDebtSecuredExpectedRateDebt (Secured)'] || form.shortEquityProfitEquity || form.shortShariaProfitSharia;

  if (!allowLongTerm) {
    return shortValid;
  }

  var longValid = form.longDebtExpectedRateDebt || form['longDebtSecuredExpectedRateDebt (Secured)'] || form.longEquityProfitEquity || form.longShariaProfitSharia;
  return longValid && shortValid;
};
export function isStartDateInvalid(value, status) {
  var startDate = new Date(value);
  startDate.setHours(0, 0, 0, 0);
  var now = new Date();
  now.setHours(0, 0, 0, 0);
  if (status !== EnumProjectRequestStatus.AVAILABLE_TO_PLEDGE) return false;
  return startDate < now;
}