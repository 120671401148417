import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
export var downloadFiles = function downloadFiles(urls) {
  var zip = new JSZip();
  var count = 0;
  var zipFilename = 'offerDocuments.zip';
  urls = urls.filter(function (url) {
    return url;
  });
  urls.forEach(function (url) {
    var urlslugs = url.split('/');
    var filename = urlslugs[urlslugs.length - 1];
    JSZipUtils.getBinaryContent(url, function (err, data) {
      if (err) {
        throw err;
      }

      zip.file(filename, data, {
        binary: true
      });
      count++;

      if (count === urls.length) {
        zip.generateAsync({
          type: 'blob'
        }).then(function (content) {
          saveAs(content, zipFilename);
        });
      }
    });
  });
};