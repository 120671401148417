import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MainNav } from '../../components/MainNav/MainNav';
import { TopNav } from '../../components/TopNav/TopNav';
import { KycPage } from '../../pages/Kyc/KycPage';
import { RootContext } from '../../stores/root-context';
import { ProjectListPage } from '../../pages/ProjectList/ProjectListPage';
import { ProjectDetailPage } from '../../pages/ProjectDetails/ProjectDetailsPage';
import { KycRoute, NoKycRoute, ProfileRoute } from '../../routes/KycRoute';
import { EnumLoginResponseRole } from 'in5pire-api';
import { AdminProjectsListPage } from '../../pages/AdminProjectsList/AdminProjectsListPage';
import { ProjectCreatePage } from '../../pages/ProjectCreate/ProjectCraetePage';
import { ProjectEditPage } from '../../pages/ProjectEdit/ProjectEditPage';
import { FinalPledgePage } from '../../pages/PledgeProcess/PledgeProcessPage';
import { UserListPage } from '../../pages/UsersList/UserListPage';
import { UserProfilePage } from '../../pages/UserProfile/UserProfilePage';
import { AdminDashboardPage } from '../../pages/AdminDashboard/AdminDashboardPage';
import { DashboardPage } from '../../pages/Dashboard/DashboardPage';
import {AdminPayments} from '../../pages/AdminPayments/AdminPayments';
import {AgreementsPage} from '../../pages/Agreements/AgreementsPage';
import {LogsPdf} from '../../pages/Agreements/logs';
import {AgreementPdf} from '../../pages/Agreements/agreement';
import { PlatformPreFooter, Footer } from 'in5pire-storybook';

function getNav(role: EnumLoginResponseRole) {
  const nav = [
    {
      path: '/platform/dashboard',
      exact: true,
      name: 'Home',
    },
    {
      path: '/platform/projects',
      exact: false,
      name: 'Projects',
    },
    {
      path: '/platform/agreements/all',
      exact: false,
      name: 'Agreements',
    }
  ];
  if (role === EnumLoginResponseRole.ADMIN) {
    nav.push({
      path: '/platform/users',
      exact: false,
      name: 'Users',
    });
  }
  return nav;
}

export function PlatformLayout() {
  const { userState } = React.useContext(RootContext);
  const projectsListComponent = userState.role === EnumLoginResponseRole.ADMIN ? AdminProjectsListPage : ProjectListPage;
  const dashboardComponent = userState.role === EnumLoginResponseRole.ADMIN ? AdminDashboardPage : DashboardPage;
  const preFooter = userState.role == null || userState.role === EnumLoginResponseRole.INVESTOR ? <PlatformPreFooter/> : null;
  return (
    <div className="platform">
      <MainNav nav={getNav(userState.role)}></MainNav>
      <main>
        <TopNav />
        <Switch>
          <NoKycRoute user={userState} path="/platform/dashboard" component={dashboardComponent} />
          {userState.role === EnumLoginResponseRole.ADMIN &&
            <Route exact path="/platform/projects/create" component={ProjectCreatePage} />
          }
          {userState.role === EnumLoginResponseRole.ADMIN &&
            <Route exact path="/platform/projects/edit/:id" component={ProjectEditPage} />
          }
          {userState.role === EnumLoginResponseRole.ADMIN &&
          <Route exact path="/platform/projects/payments/:id" component={AdminPayments} />
          }
          <NoKycRoute user={userState} exact={true} path="/platform/agreement/:id" component={AgreementPdf} />
          <NoKycRoute user={userState} exact={true} path="/platform/agreement/log/:id" component={LogsPdf} />
          <NoKycRoute user={userState} exact={true} path="/platform/agreements/:type" component={AgreementsPage} />
          <NoKycRoute user={userState} exact={true} path="/platform/projects" component={projectsListComponent} />
          <NoKycRoute user={userState} path="/platform/projects/:id" component={ProjectDetailPage} />
          <KycRoute path="/platform/kyc" user={userState} component={KycPage} />
          <Route exact path="/platform/payment-details/:id" component={FinalPledgePage} />
          {userState.role === EnumLoginResponseRole.ADMIN &&
          <Route exact path="/platform/users/:id" component={UserProfilePage} />}
          <ProfileRoute user={userState} exact path="/platform/profile/:token" component={UserProfilePage} />
          <ProfileRoute user={userState} exact path="/platform/profile" component={UserProfilePage} />
          {userState.role === EnumLoginResponseRole.ADMIN &&
          <Route exact path="/platform/users" component={UserListPage} />}
        </Switch>
        <Footer preFooter={preFooter} />
      </main>
    </div>
  );
}
