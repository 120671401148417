import * as React from 'react';
import {CreateProject} from 'in5pire-storybook';
import { useHistory } from 'react-router-dom';
import {environment} from '../../environment/environment';
export const ProjectCreatePage = () => {
  const history = useHistory();

  return <div>
    <CreateProject
      onSuccess={() => history.push('/platform/projects')}
      mapApiKey={environment.googleMapsApiKey}
      geocodeApiKey={environment.googleGeocodeApiKey}/>
  </div>;
};