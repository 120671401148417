import _toConsumableArray from "/builds/in5pire/in5pire-web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { EnumProjectRequestStatus } from "in5pire-api";
var comingSoon = new Set(['projectType', 'address', 'city', 'latitude', 'longitude']);
var availableToPledge = new Set([].concat(_toConsumableArray(comingSoon), ['shortFundingTarget', 'shortFundingMinimum', 'longFundingTarget', 'longFundingMinimum', 'title', 'purpose', 'allowLongTerm', 'longExpectedProfit', 'shortExpectedProfit', 'longDebtExpectedRate', 'longDebtSecuredExpectedRate', 'shortDebtExpectedRate', 'shortDebtSecuredExpectedRate', 'shortShariaExpectedRate', 'longShariaExpectedRate', 'longEquityExpectedRate', 'shortEquityExpectedRate', 'shortStartDate', 'shortEndDate', 'longStartDate', 'longEndDate']));
var fundedNotBegan = new Set(_toConsumableArray(availableToPledge));
var alwaysEnabled = new Set(['images', 'documents']);
export function getDisabledFields(status, allFields) {
  switch (status) {
    case EnumProjectRequestStatus.DRAFT:
      return new Set();

    case EnumProjectRequestStatus.COMING_SOON:
      return comingSoon;

    case EnumProjectRequestStatus.AVAILABLE_TO_PLEDGE:
      return availableToPledge;

    case EnumProjectRequestStatus.FULLY_FUNDED_DEVELOPMENT_NOT_BEGAN:
      return fundedNotBegan;

    default:
      return new Set(allFields.filter(function (field) {
        return !alwaysEnabled.has(field);
      }));
  }
}