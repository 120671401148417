import * as React from 'react';
import { RouteComponentProps, NavLink } from 'react-router-dom';
import axios from 'axios';
import { saveAs } from 'file-saver';
import {
  AgreementDto,
  EnumLoginResponseRole,
  AgreementsControllerService
} from 'in5pire-api';
import {SvgIcons, ErrorModal, ColoredIcons} from 'in5pire-storybook';
import {useState} from 'react';
import {AgreementsHeader} from './AgreementHeader';
import { RootContext } from '../../stores/root-context';
import {resolveError} from '../../helpers';
import {observer} from 'mobx-react';
import {formatDateGB} from '../../helpers/index';
import './agreements-page.scss';

const PROJECTS_PAGE_LIMIT = 20;
const literals = {
  DEBT_SECURED: 'Debt (Secured)',
  DEBT: 'Debt',
  EQUITY: 'Equity',
  SHARIA: 'Sharia',
  SHORT: 'Short term',
  LONG: 'Long term'
}
const headerTitles = [
  {
    column: 'ID',
    title: 'ID',
    className: 'small-width'
  },
  {
    column: 'PROJECT_NAME',
    title: 'Project Name',
    className: 'break-words medium-width',
  },
  {
    column: 'USER_NAME',
    title: 'Investor Name',
    className: 'break-words medium-width',
  },
  {
    column: 'INVESTMENT_TYPE',
    title: 'Investment Type',
    className: 'medium-width'
  },
  {
    column: 'INVESTMENT_TERM',
    title: 'Investment Term',
    className: 'medium-width'
  },
  {
    column: 'SIGNATURE_DATE',
    title: 'Signature Date',
    className: 'medium-width'
  },
  {
    column: 'STATUS',
    title: 'Status',
    className: 'medium-width'
  }
];

enum filterTypes {
  searchTerm = 'searchTerm',
  page = 'page',
  status = 'status',
  sort = 'sort'
}

const getAgreements = (page, searchTerm, status, column, direction) => {
  const params = {
    limit: PROJECTS_PAGE_LIMIT,
    offset: (page - 1) * PROJECTS_PAGE_LIMIT,
    status,
    searchTerm,
    column,
    direction
  };
  return AgreementsControllerService.getAgreementsUsingGet(params);
}

export const AgreementsPage = observer(({history, location, match}: RouteComponentProps) => {
  const [agreements, setAgreements] = React.useState<AgreementDto[]>([]);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState('');
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [sort, setSort] = React.useState({field: 'ID', direction: 'DESC'});
  const rootState = React.useContext(RootContext);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get('page')) || 1;
    const search = searchParams.get('search') || '';
    const column = searchParams.get('column') || 'ID';
    const direction = searchParams.get('direction') || 'DESC';

    setPage(page as number);
    setSearch(search as string);
    setSort({field: column || 'ID', direction: direction || 'DESC'});
    setLoading(true);
    const status = match.params['type'] === 'all' ? '' : match.params['type'].toUpperCase();
    getAgreements(page, search, status, column, direction)
      .then(({agreements, count}) => {
        setAgreements(agreements);
        setTotal(count);
        setLoading(false);
      })
      .catch(error => {
        setError(resolveError(error));
        setLoading(false);
        setAgreements([]);
        setTotal(0);
      });
  }, [location, match]);

  const setData = React.useCallback((page, search, sort: {field: string, direction: string}) => {
    history.push(`/platform/agreements/${match.params['type']}?page=${page}&search=${search}&column=${sort.field || ''}&direction=${sort.direction || ''}`);
  }, [history, match]);
  const changeFilter = React.useCallback((param, value) => {
    switch(param) {
      case filterTypes.searchTerm:
        setData(1, value, sort);
        break;
      case filterTypes.page:
        setData(value, search, sort);
        break;
      case filterTypes.sort:
        setData(1, search, value);
        break;
    }
  }, [search, setData, sort]);
  const onLoadPdf = React.useCallback((url: string, id: number) => {
    axios({
      headers: {
        'Content-Type': 'application/pdf'
      },
      url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      saveAs(response.data, `in5pire-agreement${id}.pdf`);
    });
  }, []);
  const ths = rootState.userState.role !== EnumLoginResponseRole.ADMIN ?
    headerTitles.filter(({column}) => column !== 'USER_NAME') : headerTitles;

  return (
    <div className={loading ? 'disable-controls data-loading agreements-list' : 'agreements-list'}>
      {error && <ErrorModal content={error} onClose={() => setError('')}/>}
      <AgreementsHeader caption="Agreements"
        changeFilter={changeFilter}
        page={page}
        total={total}
        searchTerm={search}
        activeTab={match.params['type']}
        limit={PROJECTS_PAGE_LIMIT}
      />
      <div className="agreements-list__wrapper">
        <table>
          <thead>
            <tr>
              {ths.map(({column, title, className}, i) => (
                <th key={title} className={className || ''}>
                  <div className={column ? 'project-list__th-sort' : ''}
                    onClick={column ? e => changeFilter(filterTypes.sort, {
                      field: column,
                      direction: sort.direction === 'ASC' && sort.field === column ? 'DESC' : 'ASC'})
                      : () => {}}>
                    <div className={sort.field === column ? 'agreements-list__active-header' : ''}>{title}</div>
                    { sort.field === column &&
                    <SvgIcons isStaticImg
                      customClass={
                        sort.direction === 'ASC' ? 'user-list__rotate-icon' : ''
                      }
                      iconType="down"
                    />}
                  </div>
                </th>
              ))}
              <th className="menu-cell">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {agreements.length > 0 && agreements.map(agreement => (
              <AgreementRow key={agreement.id}
                onLoadPdf={onLoadPdf}
                isAdmin={rootState.userState.role === EnumLoginResponseRole.ADMIN}
                agreement={agreement}/>
            ))}
          </tbody>
        </table>
        {!agreements.length && <div className="table__no-result">No search result</div>}
      </div>
    </div>
  )
});

interface AgreementRowProps {
  agreement: AgreementDto;
  onBlur?: () => void;
  isAdmin?: boolean;
  onLoadPdf?: (url: string, id: number) => void;
}

const MenuContainer = ({agreement, onBlur, onLoadPdf}: AgreementRowProps) => (
  <nav onBlur={onBlur} className="project-list__user-settings agreements__menu">
    <NavLink to={{pathname: `/platform/agreement/${agreement.id}`, state: {agreement: agreement.agreementUrl}}}>
      <ColoredIcons.Eye /> View Agreement
    </NavLink>
    <button type="button" onClick={() => onLoadPdf(agreement.agreementUrl, agreement.id)}>
      <ColoredIcons.Download /> Download Agreement
    </button>
    <NavLink to={`/platform/agreement/log/${agreement.id}`}>
      <ColoredIcons.Eye /> View Agreement Logs
    </NavLink>
  </nav>
);

function AgreementRow(props: AgreementRowProps) {
  const agreement = props.agreement;
  const [menu, setMenu] = useState({
    isShow: false
  });

  return <tr onMouseLeave={() => {setMenu({...menu, isShow: false})}}>
    <td className="small-width">{agreement.id}</td>
    <td className="medium-width break-words">{agreement.projectName}</td>
    {props.isAdmin && <td className="medium-width break-words">{agreement.userName}</td>}
    <td className="medium-width break-words">{literals[agreement.strategy]}</td>
    <td className="medium-width break-words">{literals[agreement.term]}</td>
    <td className="medium-width break-words">{formatDateGB(agreement.signatureDate)}</td>
    <td className={`medium-width agreements-status agreements-status--${agreement.status}`}>
      <div>{agreement.status.toLowerCase()}</div>
    </td>
    <td className="project-list__menu-cell">
      <button
        type="button"
        className="button button--icon"
        onClick={() => { setMenu({...menu, isShow: !menu.isShow})}}
      >
        <img src="more.svg" alt="more"/>
      </button>
      {menu.isShow ? <MenuContainer onBlur={() => { setMenu({...menu, isShow: !menu.isShow})}} {...props} /> : null}
    </td>
  </tr>
}
