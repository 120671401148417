import * as React from 'react';
import { Modal, Message, ColoredIcons, Modes} from 'in5pire-storybook';
import {useState} from 'react';
import {InvestmentsControllerService} from 'in5pire-api';
import { dynamicCurrencyFormat, resolveError } from '../../../../helpers';
import './fund-modal.scss';
import { AmountForm } from './AmountForm';

interface Props {
    type: 'withdraw' | 'fund';
    currentBalance: number;
    onClose: () => void;
}

export function FundModal(props: Props) {
  const [error, setGlobalError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [fundConfirm, setFundConfirm] = useState(false);
  const [pledgeData, setPledgeData] = useState({
    referenceNumber: '',
    sorCode: '',
    accountNumber: '',
    investorName: ''
  });

  const onSubmit = React.useCallback(sum => {
      const action = props.type === 'withdraw' ? InvestmentsControllerService.withdrawUsingPut({sum}) : InvestmentsControllerService.fundWalletUsingPut({sum});
      setIsSubmitDisabled(true);
      action.then(({accountNumber, investorName, referenceNumber, sorCode}) => {
        setPledgeData({accountNumber, investorName, referenceNumber, sorCode});
        setSuccess(true);
        setIsSubmitDisabled(false);
      }).catch(err => {
        setGlobalError(resolveError(err));
        setIsSubmitDisabled(false);
      });
    }, [setSuccess, props]);

  return (
    <Modal mode={Modes.white} onClose={props.onClose} customClass="dashboard-modal">
      <ColoredIcons.LogoOnWhite />
      {props.type === 'withdraw' && !success ?
        <div>
          <div className="dashboard_modal-caption">E-wallet current balance: <strong>£{dynamicCurrencyFormat(props.currentBalance)}</strong></div>
          <div className="dashboard_modal-caption">Please enter amount you would like <strong>to withdraw:</strong></div>
        </div> : props.type === 'withdraw' && success
          ? <p>Your withdraw request has been <strong>successfully created</strong>.</p>
          : <p>Sending funds to your Solia Investments account requires a direct bank transfer.
          Enter your chosen investment amount into the box below and carefully read the instructions before proceeding:</p>
      }
      {error != null && <Message type="error">{error}</Message>}
      <AmountForm
        success={success}
        disabled={isSubmitDisabled || (props.type === 'fund' && !fundConfirm)}
        currentBalance={props.currentBalance}
        type={props.type}
        onSubmit={onSubmit}
      >
        {props.type === 'fund' &&
          <>
            <div className="dashboard-modal__pledge">
              {success &&
                <div className="pledgeproc-stage__mango-data">
                  <div className="pledgeproc-stage__mango-row">
                    <div className="pledgeproc-stage__mango-caption">Unique Reference Number</div>
                    <div className="pledgeproc-stage__mango-value">{pledgeData.referenceNumber}</div>
                  </div>
                  <div className="pledgeproc-stage__mango-row">
                    <div className="pledgeproc-stage__mango-caption">Sort code</div>
                    <div className="pledgeproc-stage__mango-value">{pledgeData.sorCode}</div>
                  </div>
                  <div className="pledgeproc-stage__mango-row">
                    <div className="pledgeproc-stage__mango-caption">Account Number</div>
                    <div className="pledgeproc-stage__mango-value">{pledgeData.accountNumber}</div>
                  </div>
                  <div className="pledgeproc-stage__mango-row">
                    <div className="pledgeproc-stage__mango-caption">Company</div>
                    <div className="pledgeproc-stage__mango-value">MANGOPAY SA</div>
                  </div>
                </div>
              }
              <div className="pledgeproc-stage__mango-instruction">
                <div className="pledgeproc-stage__mango-transer">How to fund your account via Bank Transfer:</div>
                <div className="pledgeproc-stage__mango-step">1. Visit your <span>online banking or app;</span></div>
                <div className="pledgeproc-stage__mango-step">2. Choose <span>to pay a company;</span></div>
                {success
                  ? <>
                      <div className="pledgeproc-stage__mango-step">
                        3. The payee should be titled <span>“MANGOPAY SA”;</span>
                      </div>
                      <div className="pledgeproc-stage__mango-step">MANGOPAY SA is our independent, EU/UK-authorised and regulated, secure online payment services provider. Solia have partnered with MANGOPAY to ensure that investor funds are safely protected in secure escrow while they await project commencement.</div>
                      <div className="pledgeproc-stage__mango-step">4. Enter the <span>sort code</span> and <span>account number</span> as seen above;</div>
                      <div className="pledgeproc-stage__mango-step">5. Enter the <span>unique reference</span> above into your transaction reference.</div>
                      <div className="pledgeproc-stage__mango-step">
                        Upon successful funds transfer using the instructions above, your deposit will automatically show as available funds in your investor dashboard.
                      </div>
                      <div className="pledgeproc-stage__mango-step">
                        <strong>IMPORTANT: Transfers can take up to 48 hours to display in your dashboard.</strong>
                      </div>
                    </>
                  : <>
                      <div className="pledgeproc-stage__mango-step">3. The next step will provide you with the transfer input details including: sort code, account number, company name and unique transaction reference</div>
                      <div className="pledgeproc-stage__mango-step">4. Once the funds have transferred, they will appear in your dashboard available balance within 48 hours</div>
                    </>
                }
              </div>
            </div>
            {!success && <div>
              <label className="confirm-fund">
                <input type="checkbox" checked={fundConfirm} onChange={e => setFundConfirm(e.target.checked)}/>
                I can confirm I have read the instructions and am ready to transfer my funds
              </label>
            </div>}
          </>
        }
      </AmountForm>

      {success &&
        <div className="dashboard-modal__submit-wrapper">
          <button type="button" onClick={props.onClose} className="button button--primary">
            FINISH
          </button>
        </div>
      }
    </Modal>
  )
}