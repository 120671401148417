import React from 'react';

import './payments-table.scss';
import { toCurrencyFormat } from 'in5pire-storybook/dist/';
import { PaymentsTableItem } from '../PaymentTableItem/PaymentTableItem';
import { PaymentDto } from 'in5pire-api';

interface Props {
  makePaymentById: any;
  content: PaymentDto[];
}

const formatter = new Intl.DateTimeFormat('en-GB');

export function PaymentsTable(props: Props) {
  const { content } = props;
  return(
    <table className="admin-payments__table">
      <thead>
        <tr>
          <th></th>
          <th className="name"><span>Name</span></th>
          <th className="content-left">Strategy</th>
          <th>Invest Sum</th>
          <th>Invest Days</th>
          <th>Value Rate</th>
          <th>Annual Rate</th>
          <th>Profit</th>
          <th>Total</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {content !== null ?
        content.map((item: PaymentDto) => {
          return (<PaymentsTableItem
            key={item.id}
            firstInvest={formatter.format(new Date(item.firstInvest))}
            name={item.name}
            strategy={item.details}
            investSum={toCurrencyFormat(item.sum)}
            rate={Math.round(item.rate*100)/100}
            profit={toCurrencyFormat(item.profit)}
            total={toCurrencyFormat(item.total)}
            status={item.status}
            id={item.id}
            days={item.days}
            yearRate={item.yearRate}
            makePaymentById={props.makePaymentById}/>)
        })
        : null
      }
      </tbody>
    </table>
  )
};
