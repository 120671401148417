import * as React from 'react';

import './investments-card.scss';
import {Card} from '../atoms/Card/Card';
import {ColoredIcons, Paginator} from 'in5pire-storybook';
import {InvestmentsTable} from '../atoms/InvestmentsTable/ProjectsTable';

interface ProjectData {
  projectName: string,
  status: string,
  amount: string,
  date: string;
  id: string;
}
interface Props {
  projectsData: ProjectData[],
  changePage: (payload: number) => void,
  className?: string,
  page: number,
  total: number,
  onRowClick: (value: string) => void
}

export const InvestmentsCard = (props: Props) => {
  return (
    <Card cardTitle='my investments'
          icon={ColoredIcons.BlueGreenPoundIcon()}
          className={props.className}>
      <InvestmentsTable projectsData={props.projectsData} onRowClick={props.onRowClick}/>
      <Paginator page={props.page} total={props.total} perPageLimit={8} changePage={props.changePage}/>
    </Card>
  );
};
