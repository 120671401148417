import React, {useEffect, useState} from 'react';

import './admin-payments.scss';

import {Paginator, projectStatus, toCurrencyFormat} from 'in5pire-storybook';
import {PaymentsTable} from './components/PaymentsTable/PaymentsTable';
import {EnumProjectDtoStatus, PaymentsResponse, ProjectDto, ProjectsControllerService} from 'in5pire-api';
import {PaymentsWallet} from './components/PaymentWallet/PaymentWallet';
import {Link, RouteComponentProps} from 'react-router-dom';
import {resolveError} from "../../helpers";

const limit = 10;
const msPaymentUpdateInterval = 10000;

function getPage(search: URLSearchParams): number {
  const page = Number(search.get('page') || 1);
  return isNaN(page) ? 1 : page;
}

function getLongTerm(search: URLSearchParams): boolean {
  return search.get('long') === 'true';
}

const isPaymentBtnActive = new Set([
  EnumProjectDtoStatus.FULLY_FUNDED_DEVELOPMENT_BEGAN,
  EnumProjectDtoStatus.COMPLETE_SHORT_TERM,
  EnumProjectDtoStatus.CANCELLED_NOT_PAID_OUT,
  EnumProjectDtoStatus.FULLY_FUNDED_DEVELOPMENT_COMPLETE_LONG,
]);

export const AdminPayments = ({history, location, match}: RouteComponentProps) => {

  const [payments, setPayments] = useState<PaymentsResponse>(null);
  const [project, setProject] = useState<ProjectDto>(null);

  const id = Number(match.params['id']);
  // TODO check isNan id and redirect to 404 or show error
  const path = '/platform/projects/payments/' + id;
  const search = new URLSearchParams(location.search);
  const page = getPage(search);
  const longTerm = getLongTerm(search);

  const makePaymentsActive = project && isPaymentBtnActive.has(project.status) && payments?.payments[0]?.profit != null;

  const getPayments = React.useCallback((id, longTerm, page) => {
    ProjectsControllerService.getPaymentsUsingGet({
      id,
      limit,
      longTerm,
      offset: (page - 1) * limit,
    })
      .then(setPayments)
      .catch(() => console.error());
  }, [setPayments]);

  const getProject = React.useCallback(id => {
    ProjectsControllerService.getProjectUsingGet({id}).then(response => {
      setProject(response.project);
    });
  }, [setProject]);

  const [errorPaymentMessage, setErrorPaymentMessage] = React.useState({ isMessageVisible: false, message: '' });
  const showErrorPaymentMessage = (error) => {
    setErrorPaymentMessage({isMessageVisible: true, message: error});
    setTimeout(() => {
      setErrorPaymentMessage({isMessageVisible: false, message: ''});
    }, 6000);
  };

  const recalculatePayments = React.useCallback(() => {
    ProjectsControllerService.recalculatePaymentsUsingPost({ id, longTerm})
    .then(() => getPayments(id, longTerm, page))
    .catch((error) => {
      if(error.response == null) return
      if(error.response.data.errorCode === 35) {
        return showErrorPaymentMessage('Not enough money to make paid out. Please fund project e-wallet.')
      }
      return showErrorPaymentMessage(resolveError(error));
    })
  }, [id, longTerm, page, getPayments]);

  const makeAllPayments = React.useCallback(() => {
    ProjectsControllerService.makePaymentsUsingPost({id, longTerm})
      .then(() => getPayments(id, longTerm, page))
      .catch((error) => {
        if(error.response == null) return
        if(error.response.data.errorCode === 35) {
          return showErrorPaymentMessage('Not enough money to make paid out. Please fund project e-wallet.')
        }
        return showErrorPaymentMessage(resolveError(error));
      })
  }, [id, longTerm, page, getPayments]);

  const makePaymentById = React.useCallback((paymentId) => {
    ProjectsControllerService.makePaymentUsingPost({id, paymentId})
  }, [id]);

  const openPage = React.useCallback(page => {
    history.push(path + '?page=' + page + '&long=' + (longTerm ? 'true' : ''));
  }, [path, longTerm, history]);


  useEffect(() => {
    getProject(id);
  }, [getProject, id]);

  useEffect(() => {
    getPayments(id, longTerm, page);
    const getUpdates = setInterval(() => getPayments(id, longTerm, page), msPaymentUpdateInterval);
    return () => clearInterval(getUpdates)
  }, [getPayments, id, longTerm, page]);

  return(
    <section className="admin-payments">
      {!!project && <div className="admin-payments__header-1">
        <span><b>{project.title} </b> {projectStatus(project.status)}</span>
        <button
            className="button button--primary"
            disabled={(!isPaymentBtnActive.has(project.status))}
            onClick={recalculatePayments}>
            calculate&nbsp;profit
        </button>
        <div className="admin-payments__header-1-container">
          <button
            className="button button--primary"
            disabled={!makePaymentsActive}
            onClick={makeAllPayments}>
            make&nbsp;payments
          </button>
          {errorPaymentMessage.isMessageVisible ? <div className="admin-payments__error">{errorPaymentMessage.message}</div> : null}
        </div>
      </div>}

      {!!payments && <div className="admin-payments__main-content">
        <div className="admin-payments__header-2">
          <PaymentsWallet
            balance={toCurrencyFormat(payments.walletBalance)}
            totalPaidOuts={toCurrencyFormat(payments.totalSum)}
            sumInfo={payments.sumInfo}
            payments={payments.payments}
            transferredToLongWallet={toCurrencyFormat(payments.transferredToLongWallet)}
          />
          <Paginator page={page} total={payments.count} perPageLimit={limit} changePage={openPage}/>
        </div>
        <ul className="admin-payments__tabs">
          <li className={!longTerm ? 'active' : ''}>
            <Link to={{
              pathname: path,
              search: '?long='
            }}>Short term</Link>
          </li>
          <li className={longTerm ? 'active' : ''}>
            <Link to={{
              pathname: path,
              search: '?long=true'
            }}>Long term</Link>
          </li>
        </ul>
        <PaymentsTable content={payments.payments} makePaymentById={makePaymentById}/>
      </div>}
    </section>
  )
}




