import * as React from 'react';
import { HashRouter as Router, Switch } from 'react-router-dom';

import { UnauthorizedLayout } from './layouts/Unauthorized/UnauthorizedLayout';
import { PlatformLayout } from './layouts/Platform/PlatformLayout';
import { AuthRoute } from './routes/AuthRoute';
import { AnonRoute } from './routes/AnonRoute';
import { RedirectRoute } from './routes/RedirectRoute';

import './App.scss';
import { RootState } from './stores/root-state';
import { EnumLoginResponseRole } from 'in5pire-api';

export function App({ rootState }: {rootState: RootState}) {
  return (
    <div className={`platform-wrapper${rootState.userState.role !== EnumLoginResponseRole.ADMIN ? ' with-fly-footer' : ''}`}>
      <Router>
        <Switch>
          <AnonRoute path="/login" user={rootState.userState} component={UnauthorizedLayout} />
          <AuthRoute path="/platform" user={rootState.userState} component={PlatformLayout} />
          <RedirectRoute exact path="/" user={rootState.userState} />
        </Switch>
      </Router>
    </div>
  );
}
