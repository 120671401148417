import _objectSpread from "/builds/in5pire/in5pire-web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
var ActionType;

(function (ActionType) {
  ActionType[ActionType["SOURCE"] = 0] = "SOURCE";
  ActionType[ActionType["AMOUNT"] = 1] = "AMOUNT";
  ActionType[ActionType["STRATEGY"] = 2] = "STRATEGY";
  ActionType[ActionType["TERM"] = 3] = "TERM";
  ActionType[ActionType["STEP"] = 4] = "STEP";
  ActionType[ActionType["NEXT_STEP"] = 5] = "NEXT_STEP";
  ActionType[ActionType["PREV_STEP"] = 6] = "PREV_STEP";
  ActionType[ActionType["E_SIGNATURE_IMAGE"] = 7] = "E_SIGNATURE_IMAGE";
  ActionType[ActionType["E_SIGNATURE_TYPE"] = 8] = "E_SIGNATURE_TYPE";
  ActionType[ActionType["STATEMENT"] = 9] = "STATEMENT";
  ActionType[ActionType["CONDITIONS"] = 10] = "CONDITIONS";
  ActionType[ActionType["AMOUNT_SHORT_OPTION"] = 11] = "AMOUNT_SHORT_OPTION";
  ActionType[ActionType["PAYMENT_SUCCESS"] = 12] = "PAYMENT_SUCCESS";
  ActionType[ActionType["PAYMENT_CONFIRM"] = 13] = "PAYMENT_CONFIRM";
  ActionType[ActionType["SMS_CODE"] = 14] = "SMS_CODE";
  ActionType[ActionType["PLEDGE_ID"] = 15] = "PLEDGE_ID";
  ActionType[ActionType["MAKING_PAYMENT"] = 16] = "MAKING_PAYMENT";
})(ActionType || (ActionType = {}));

;
export function reducer(state, _ref) {
  var type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case ActionType.SOURCE:
      return _objectSpread({}, state, {
        source: payload
      });

    case ActionType.STRATEGY:
      return _objectSpread({}, state, {
        strategy: payload,
        isLong: null
      });

    case ActionType.TERM:
      return _objectSpread({}, state, {
        isLong: payload
      });

    case ActionType.AMOUNT:
      return _objectSpread({}, state, {}, payload);

    case ActionType.NEXT_STEP:
      return _objectSpread({}, state, {
        stepIndex: state.stepIndex + 1
      });

    case ActionType.PREV_STEP:
      return _objectSpread({}, state, {
        stepIndex: state.stepIndex - 1
      });

    case ActionType.E_SIGNATURE_IMAGE:
      return _objectSpread({}, state, {
        signatureBase64: payload
      });

    case ActionType.E_SIGNATURE_TYPE:
      return _objectSpread({}, state, {
        eSignatureType: payload
      });

    case ActionType.STATEMENT:
      return _objectSpread({}, state, {
        statement: payload
      });

    case ActionType.CONDITIONS:
      return _objectSpread({}, state, {
        conditions: payload
      });

    case ActionType.PAYMENT_SUCCESS:
      return _objectSpread({}, state, {
        paymentSuccess: payload
      });

    case ActionType.AMOUNT_SHORT_OPTION:
      return _objectSpread({}, state, {
        amountShortOption: payload
      });

    case ActionType.PAYMENT_CONFIRM:
      return _objectSpread({}, state, {
        paymentConfirmed: payload
      });

    case ActionType.SMS_CODE:
      return _objectSpread({}, state, {
        smsCode: payload
      });

    case ActionType.PLEDGE_ID:
      return _objectSpread({}, state, {
        pledgeId: payload
      });

    case ActionType.MAKING_PAYMENT:
      return _objectSpread({}, state, {
        makingPayment: payload
      });

    default:
      return state;
  }
}
export function setSource(source) {
  return {
    type: ActionType.SOURCE,
    payload: source
  };
}
export function setStrategy(strategy) {
  return {
    type: ActionType.STRATEGY,
    payload: strategy
  };
}
export function setTerm(isLong) {
  return {
    type: ActionType.TERM,
    payload: isLong
  };
}
export function goNext() {
  return {
    type: ActionType.NEXT_STEP
  };
}
export function goBack() {
  return {
    type: ActionType.PREV_STEP
  };
}
export function setAmount(payload) {
  return {
    type: ActionType.AMOUNT,
    payload: payload
  };
}
export function setESignatureType(payload) {
  return {
    type: ActionType.E_SIGNATURE_TYPE,
    payload: payload
  };
}
export function setESignatureImage(payload) {
  return {
    type: ActionType.E_SIGNATURE_IMAGE,
    payload: payload
  };
}
export function setStatement(payload) {
  return {
    type: ActionType.STATEMENT,
    payload: payload
  };
}
export function setMakingPayment(payload) {
  return {
    type: ActionType.MAKING_PAYMENT,
    payload: payload
  };
}
export function setConditions(payload) {
  return {
    type: ActionType.CONDITIONS,
    payload: payload
  };
}
export function setAmountShortOption(payload) {
  return {
    type: ActionType.AMOUNT_SHORT_OPTION,
    payload: payload
  };
}
export function setPaymentSuccess(payload) {
  return {
    type: ActionType.PAYMENT_SUCCESS,
    payload: payload
  };
}
export function confirmPayment(payload) {
  return {
    type: ActionType.PAYMENT_CONFIRM,
    payload: payload
  };
}
export function setSmsCode(payload) {
  return {
    type: ActionType.SMS_CODE,
    payload: payload
  };
}
export function setPledgeId(payload) {
  return {
    type: ActionType.PLEDGE_ID,
    payload: payload
  };
}