import * as React  from 'react';

import './card-header.scss';
import {CardTitle} from '../CardTitle/CardTitle';

interface Props {
  cardTitle: string,
  cardSubTitle?: string,
  icon?: JSX.Element,
}

export function CardHeader(props: Props) {
  return (
    <div className='card-header'>
      <CardTitle cardTitle={props.cardTitle}
                 cardSubTitle={props.cardSubTitle} />
        {props.icon}
    </div>
  );
}
