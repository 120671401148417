import * as React from 'react';

import './interest-occurred-card.scss';
import {Card} from '../atoms/Card/Card';
import {ColoredIcons} from 'in5pire-storybook';

interface Props {
  interestOccurred: string,
  percent: string,
  className?: string
}

export function InterestOccurredCard(props: Props) {
  return (
    <Card cardTitle='TOTAL PAID OUT INTEREST ACCRUED'
      cardSubTitle='Total'
      colorUnderline='orange'
      icon={ColoredIcons.OrangePointIcon()}
      className={props.className}>
      <div className='total-info'>
        <span className='total-info__money'>{ props.interestOccurred }</span>
      </div>
    </Card>
  );
}
