// Add default options
export const serviceOptions = {};
// Instance selector
function axios(configs, resolve, reject) {
    if (serviceOptions.axios) {
        return serviceOptions.axios
            .request(configs)
            .then(res => {
            resolve(res.data);
        })
            .catch(err => {
            reject(err);
        });
    }
    else {
        throw new Error('please inject yourself instance like axios  ');
    }
}
function getConfigs(method, contentType, url, options) {
    const configs = { ...options, method, url };
    configs.headers = {
        ...options.headers,
        'Content-Type': contentType
    };
    return configs;
}
export class IList extends Array {
}
export class List extends Array {
}
export class ListResultDto {
}
export class PagedResultDto {
}
// customer definition
// empty
export class AgreementsControllerService {
    /**
     * Get agreements
     */
    static getAgreementsUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/agreements';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                column: params['column'],
                direction: params['direction'],
                limit: params['limit'],
                offset: params['offset'],
                searchTerm: params['searchTerm'],
                status: params['status']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Get agreement
     */
    static getAgreementUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/agreements/{id}';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Get agreement logs
     */
    static getAgreementLogsUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/agreements/{id}logs';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class AuthControllerService {
    /**
     * Change password
     */
    static changePasswordUsingPut(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/auth/change-password';
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params['request'];
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Generate invite link
     */
    static sendInviteLinkUsingPost(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/auth/invite';
            const configs = getConfigs('post', 'application/json', url, options);
            configs.params = { email: params['email'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Login
     */
    static loginUsingPost(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/auth/login';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params['request'];
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Registry investor
     */
    static registryUsingPut(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/auth/registry';
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params['request'];
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Request invite
     */
    static requestInviteUsingPost(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/auth/request-invite';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params['request'];
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Restore password
     */
    static restorePasswordUsingPut(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/auth/restore-password';
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params['request'];
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Send restore password link
     */
    static sendRestorePasswordLinkUsingPost(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/auth/send-restore-password-link';
            const configs = getConfigs('post', 'application/json', url, options);
            configs.params = { email: params['email'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Validate token from link
     */
    static validateTokenUsingPost(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/auth/validate';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params['request'];
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class DashboardControllerService {
    /**
     * Get dashboard
     */
    static getDashboardUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/dashboard/admin';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { filter: params['filter'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Get last project
     */
    static getLastProjectForDashboardUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/dashboard/admin/last-projects';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { filter: params['filter'], limit: params['limit'], offset: params['offset'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Get investor dashboard
     */
    static getUserDashboardUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/dashboard/investor';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { filter: params['filter'], loadFirsPageRelated: params['loadFirsPageRelated'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Get my investments
     */
    static getMyInvestmentsUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/dashboard/investor/investments';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { filter: params['filter'], limit: params['limit'], offset: params['offset'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Get history paid out
     */
    static getHistoryPaidOutUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/dashboard/investor/paid-out';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { filter: params['filter'], limit: params['limit'], offset: params['offset'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Get projects i follow
     */
    static getProjectsIFollowUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/dashboard/investor/projects-follow';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { filter: params['filter'], limit: params['limit'], offset: params['offset'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Get projects are invested
     */
    static getProjectsAreInvestedUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/dashboard/investor/projects-invested';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { filter: params['filter'], limit: params['limit'], offset: params['offset'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Get wallet history
     */
    static getWalletHistoryUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/dashboard/investor/wallet-history';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { filter: params['filter'], limit: params['limit'], offset: params['offset'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class FileControllerService {
    /**
     * Upload temp file
     */
    static uploadTempFileUsingPost(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/file/temp';
            const configs = getConfigs('post', 'multipart/form-data', url, options);
            const data = new FormData();
            if (params['file']) {
                data.append('file', params['file']);
            }
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class HookControllerService {
    /**
     * Web hook for kyc
     */
    static kycHandleUsingPost(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/hooks/{token}/kyc';
            url = url.replace('{token}', params['token'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params['payload'];
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Web hook endpoint
     */
    static handleUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/hooks/{token}/mangopay';
            url = url.replace('{token}', params['token'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { EventType: params['eventType'], RessourceId: params['ressourceId'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class InvestmentsControllerService {
    /**
     * Fund wallet
     */
    static fundWalletUsingPut(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/investments/fund-wallet';
            const configs = getConfigs('put', 'application/json', url, options);
            configs.params = { sum: params['sum'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Withdraw
     */
    static withdrawUsingPut(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/investments/withdraw';
            const configs = getConfigs('put', 'application/json', url, options);
            configs.params = { sum: params['sum'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Get invest info for pay
     */
    static getInvestInfoForPayUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/investments/{id}';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class MarketingControllerService {
    /**
     * Get marketing projects
     */
    static getMarketingProjectsUsingGet(options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/marketing';
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Contact us form
     */
    static contactUsUsingPost(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/marketing/contact-us';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params['request'];
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Get faq search term
     */
    static getAllFaqUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/marketing/faq';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { limit: params['limit'], offset: params['offset'], searchTerm: params['searchTerm'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Add question to faq
     */
    static createFaqRecordUsingPost(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/marketing/faq';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params['request'];
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Delete question from
     */
    static deleteFaqRecordUsingDelete(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/marketing/faq/{id}';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('delete', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class NewsControllerService {
    /**
     * Subscribe to news
     */
    static subscribeToNewsUsingPut(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/news/subscribe';
            const configs = getConfigs('put', 'application/json', url, options);
            configs.params = { email: params['email'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Unsubscribe on project news
     */
    static unsubscribeNewsUsingDelete(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/news/unsubscribe/{token}';
            url = url.replace('{token}', params['token'] + '');
            const configs = getConfigs('delete', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class PledgesControllerService {
    /**
     * Start pledge process
     */
    static startPledgeUsingPost(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/pledges';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params['request'];
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Get valid pledge sum for project
     */
    static getValidPledgeSumForProjectUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/pledges/valid-pledge-sum';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                longAmount: params['longAmount'],
                projectId: params['projectId'],
                shortAmount: params['shortAmount']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Finish pledge process
     */
    static finishPledgeUsingPut(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/pledges/{id}/';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params['request'];
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Send verification sms
     */
    static sendVerificationSmsUsingPut(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/pledges/{id}/send-sms';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('put', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ProjectsControllerService {
    /**
     * Get project list
     */
    static getProjectsUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/projects';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                column: params['column'],
                direction: params['direction'],
                limit: params['limit'],
                offset: params['offset'],
                searchTerm: params['searchTerm'],
                status: params['status']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Create project
     */
    static createProjectUsingPost(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/projects';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params['request'];
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Get project by id
     */
    static getProjectUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/projects/{id}';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Update project by id
     */
    static updateProjectUsingPut(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/projects/{id}';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params['request'];
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Delete project by id
     */
    static deleteProjectUsingDelete(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/projects/{id}';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('delete', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Export investors to csv
     */
    static csvExportSalesUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/projects/{id}/export-investors';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Get project payments
     */
    static getPaymentsUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/projects/{id}/payments';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { limit: params['limit'], longTerm: params['longTerm'], offset: params['offset'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Make all payments
     */
    static makePaymentsUsingPost(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/projects/{id}/payments/make';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            configs.params = { longTerm: params['longTerm'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Recalculate payments
     */
    static recalculatePaymentsUsingPost(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/projects/{id}/payments/recalculate';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            configs.params = { longTerm: params['longTerm'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Make payment
     */
    static makePaymentUsingPost(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/projects/{id}/payments/{paymentId}/make';
            url = url.replace('{id}', params['id'] + '');
            url = url.replace('{paymentId}', params['paymentId'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Subscribe on project news
     */
    static subscribeProjectUsingPost(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/projects/{id}/subscribe';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Unsubscribe from project news
     */
    static unsubscribeProjectUsingDelete(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/projects/{id}/unsubscribe';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('delete', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class UserControllerService {
    /**
     * Get profile
     */
    static getProfileUsingGet(options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/user';
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Update kyc profile
     */
    static updateProfileUsingPut(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/user';
            const configs = getConfigs('put', 'application/json', url, options);
            configs.params = { validateQuestions: params['validateQuestions'] };
            let data = params['request'];
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Update bank details
     */
    static updateBankDetailsUsingPut(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/user/bank-details';
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params['request'];
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Confirm update phone or email
     */
    static confirmUpdatePhoneOrEmailUsingPost(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/user/confirm/{token}';
            url = url.replace('{token}', params['token'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Update user details
     */
    static updateUserDetailsUsingPut(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/user/details';
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params['request'];
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Start kyc procedure
     */
    static startKycProcedureUsingPut(options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/user/start-kyc-procedure';
            const configs = getConfigs('put', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class UsersControllerService {
    /**
     * Get users profiles
     */
    static getUsersUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/users';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                column: params['column'],
                direction: params['direction'],
                limit: params['limit'],
                offset: params['offset'],
                searchTerm: params['searchTerm'],
                status: params['status']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Get user profile
     */
    static getUserUsingGet(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/users/{id}';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Manual approve kyc
     */
    static approveUserKycUsingPut(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/users/{id}/kyc/approve';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('put', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Reset answer attempts
     */
    static resetAnswerAttemptsUsingPut(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/users/{id}/reset-answer-attempts';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('put', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Change user status to active
     */
    static activateUserUsingPut(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/users/{id}/status/active';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('put', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Change user status to not active
     */
    static deactivateUserUsingPut(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = '/users/{id}/status/not-active';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('put', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export var EnumAgreementDtoStatus;
(function (EnumAgreementDtoStatus) {
    EnumAgreementDtoStatus["ACTIVE"] = "ACTIVE";
    EnumAgreementDtoStatus["COMPLETED"] = "COMPLETED";
    EnumAgreementDtoStatus["CANCELLED"] = "CANCELLED";
})(EnumAgreementDtoStatus || (EnumAgreementDtoStatus = {}));
export var EnumAgreementDtoStrategy;
(function (EnumAgreementDtoStrategy) {
    EnumAgreementDtoStrategy["DEBT_SECURED"] = "DEBT_SECURED";
    EnumAgreementDtoStrategy["DEBT"] = "DEBT";
    EnumAgreementDtoStrategy["EQUITY"] = "EQUITY";
    EnumAgreementDtoStrategy["SHARIA"] = "SHARIA";
})(EnumAgreementDtoStrategy || (EnumAgreementDtoStrategy = {}));
export var EnumAgreementDtoTerm;
(function (EnumAgreementDtoTerm) {
    EnumAgreementDtoTerm["SHORT"] = "SHORT";
    EnumAgreementDtoTerm["LONG"] = "LONG";
})(EnumAgreementDtoTerm || (EnumAgreementDtoTerm = {}));
export var EnumAgreementLogDtoEvent;
(function (EnumAgreementLogDtoEvent) {
    EnumAgreementLogDtoEvent["START_PLEDGE"] = "START_PLEDGE";
    EnumAgreementLogDtoEvent["SEND_SMS"] = "SEND_SMS";
    EnumAgreementLogDtoEvent["ATTEMPTS_ENTER"] = "ATTEMPTS_ENTER";
    EnumAgreementLogDtoEvent["SEND_DOCUMENT"] = "SEND_DOCUMENT";
})(EnumAgreementLogDtoEvent || (EnumAgreementLogDtoEvent = {}));
export var EnumBaseProjectDtoProjectType;
(function (EnumBaseProjectDtoProjectType) {
    EnumBaseProjectDtoProjectType["COMMERCIAL_BUILD"] = "COMMERCIAL_BUILD";
    EnumBaseProjectDtoProjectType["RESIDENTIAL_BUILD"] = "RESIDENTIAL_BUILD";
    EnumBaseProjectDtoProjectType["REFURBISHMENT"] = "REFURBISHMENT";
})(EnumBaseProjectDtoProjectType || (EnumBaseProjectDtoProjectType = {}));
export var EnumBaseProjectDtoStatus;
(function (EnumBaseProjectDtoStatus) {
    EnumBaseProjectDtoStatus["DRAFT"] = "DRAFT";
    EnumBaseProjectDtoStatus["COMING_SOON"] = "COMING_SOON";
    EnumBaseProjectDtoStatus["AVAILABLE_TO_PLEDGE"] = "AVAILABLE_TO_PLEDGE";
    EnumBaseProjectDtoStatus["FULLY_FUNDED_DEVELOPMENT_NOT_BEGAN"] = "FULLY_FUNDED_DEVELOPMENT_NOT_BEGAN";
    EnumBaseProjectDtoStatus["FULLY_FUNDED_DEVELOPMENT_BEGAN"] = "FULLY_FUNDED_DEVELOPMENT_BEGAN";
    EnumBaseProjectDtoStatus["FULLY_FUNDED_DEVELOPMENT_COMPLETE_SHORT"] = "FULLY_FUNDED_DEVELOPMENT_COMPLETE_SHORT";
    EnumBaseProjectDtoStatus["COMPLETE_SHORT_TERM"] = "COMPLETE_SHORT_TERM";
    EnumBaseProjectDtoStatus["FULLY_FUNDED_DEVELOPMENT_COMPLETE_LONG"] = "FULLY_FUNDED_DEVELOPMENT_COMPLETE_LONG";
    EnumBaseProjectDtoStatus["COMPLETE"] = "COMPLETE";
    EnumBaseProjectDtoStatus["CANCELLED_NOT_PAID_OUT"] = "CANCELLED_NOT_PAID_OUT";
    EnumBaseProjectDtoStatus["CANCELLED_PAID_OUT"] = "CANCELLED_PAID_OUT";
    EnumBaseProjectDtoStatus["ARCHIVED"] = "ARCHIVED";
})(EnumBaseProjectDtoStatus || (EnumBaseProjectDtoStatus = {}));
export var EnumBaseUserDtoKycStatus;
(function (EnumBaseUserDtoKycStatus) {
    EnumBaseUserDtoKycStatus["DRAFT"] = "DRAFT";
    EnumBaseUserDtoKycStatus["IN_PROGRESS"] = "IN_PROGRESS";
    EnumBaseUserDtoKycStatus["APPROVED"] = "APPROVED";
    EnumBaseUserDtoKycStatus["REJECTED"] = "REJECTED";
})(EnumBaseUserDtoKycStatus || (EnumBaseUserDtoKycStatus = {}));
export var EnumBaseUserDtoStatus;
(function (EnumBaseUserDtoStatus) {
    EnumBaseUserDtoStatus["ACTIVE"] = "ACTIVE";
    EnumBaseUserDtoStatus["NOT_ACTIVE"] = "NOT_ACTIVE";
})(EnumBaseUserDtoStatus || (EnumBaseUserDtoStatus = {}));
export var EnumBaseUserDtoTitle;
(function (EnumBaseUserDtoTitle) {
    EnumBaseUserDtoTitle["MR"] = "MR";
    EnumBaseUserDtoTitle["MS"] = "MS";
    EnumBaseUserDtoTitle["MRS"] = "MRS";
    EnumBaseUserDtoTitle["MISS"] = "MISS";
})(EnumBaseUserDtoTitle || (EnumBaseUserDtoTitle = {}));
export var EnumCompactProjectDtoStatus;
(function (EnumCompactProjectDtoStatus) {
    EnumCompactProjectDtoStatus["DRAFT"] = "DRAFT";
    EnumCompactProjectDtoStatus["COMING_SOON"] = "COMING_SOON";
    EnumCompactProjectDtoStatus["AVAILABLE_TO_PLEDGE"] = "AVAILABLE_TO_PLEDGE";
    EnumCompactProjectDtoStatus["FULLY_FUNDED_DEVELOPMENT_NOT_BEGAN"] = "FULLY_FUNDED_DEVELOPMENT_NOT_BEGAN";
    EnumCompactProjectDtoStatus["FULLY_FUNDED_DEVELOPMENT_BEGAN"] = "FULLY_FUNDED_DEVELOPMENT_BEGAN";
    EnumCompactProjectDtoStatus["FULLY_FUNDED_DEVELOPMENT_COMPLETE_SHORT"] = "FULLY_FUNDED_DEVELOPMENT_COMPLETE_SHORT";
    EnumCompactProjectDtoStatus["COMPLETE_SHORT_TERM"] = "COMPLETE_SHORT_TERM";
    EnumCompactProjectDtoStatus["FULLY_FUNDED_DEVELOPMENT_COMPLETE_LONG"] = "FULLY_FUNDED_DEVELOPMENT_COMPLETE_LONG";
    EnumCompactProjectDtoStatus["COMPLETE"] = "COMPLETE";
    EnumCompactProjectDtoStatus["CANCELLED_NOT_PAID_OUT"] = "CANCELLED_NOT_PAID_OUT";
    EnumCompactProjectDtoStatus["CANCELLED_PAID_OUT"] = "CANCELLED_PAID_OUT";
    EnumCompactProjectDtoStatus["ARCHIVED"] = "ARCHIVED";
})(EnumCompactProjectDtoStatus || (EnumCompactProjectDtoStatus = {}));
export var EnumContactUsRequestInterest;
(function (EnumContactUsRequestInterest) {
    EnumContactUsRequestInterest["PROPERTY_DEVELOPER"] = "PROPERTY_DEVELOPER";
    EnumContactUsRequestInterest["COMPANY_INVESTOR"] = "COMPANY_INVESTOR";
    EnumContactUsRequestInterest["INDIVIDUAL_INVESTOR"] = "INDIVIDUAL_INVESTOR";
    EnumContactUsRequestInterest["KYC"] = "KYC";
    EnumContactUsRequestInterest["OTHER"] = "OTHER";
})(EnumContactUsRequestInterest || (EnumContactUsRequestInterest = {}));
export var EnumFaqDtoCategory;
(function (EnumFaqDtoCategory) {
    EnumFaqDtoCategory["GETTING_STARTED"] = "GETTING_STARTED";
    EnumFaqDtoCategory["PAYMENT"] = "PAYMENT";
    EnumFaqDtoCategory["ACCOUNT"] = "ACCOUNT";
    EnumFaqDtoCategory["SOLIA_FAQ"] = "SOLIA_FAQ";
    EnumFaqDtoCategory["GENERAL_FAQ"] = "GENERAL_FAQ";
    EnumFaqDtoCategory["INVESTOR_FAQ"] = "INVESTOR_FAQ";
})(EnumFaqDtoCategory || (EnumFaqDtoCategory = {}));
export var EnumFaqRequestCategory;
(function (EnumFaqRequestCategory) {
    EnumFaqRequestCategory["GETTING_STARTED"] = "GETTING_STARTED";
    EnumFaqRequestCategory["PAYMENT"] = "PAYMENT";
    EnumFaqRequestCategory["ACCOUNT"] = "ACCOUNT";
    EnumFaqRequestCategory["SOLIA_FAQ"] = "SOLIA_FAQ";
    EnumFaqRequestCategory["GENERAL_FAQ"] = "GENERAL_FAQ";
    EnumFaqRequestCategory["INVESTOR_FAQ"] = "INVESTOR_FAQ";
})(EnumFaqRequestCategory || (EnumFaqRequestCategory = {}));
export var EnumInvestResponseStatus;
(function (EnumInvestResponseStatus) {
    EnumInvestResponseStatus["PENDING"] = "PENDING";
    EnumInvestResponseStatus["SUCCEEDED"] = "SUCCEEDED";
    EnumInvestResponseStatus["FAILED"] = "FAILED";
})(EnumInvestResponseStatus || (EnumInvestResponseStatus = {}));
export var EnumInvestorDashboardInvestmentDtoStatus;
(function (EnumInvestorDashboardInvestmentDtoStatus) {
    EnumInvestorDashboardInvestmentDtoStatus["PENDING"] = "PENDING";
    EnumInvestorDashboardInvestmentDtoStatus["SUCCEEDED"] = "SUCCEEDED";
    EnumInvestorDashboardInvestmentDtoStatus["FAILED"] = "FAILED";
})(EnumInvestorDashboardInvestmentDtoStatus || (EnumInvestorDashboardInvestmentDtoStatus = {}));
export var EnumInvestorDashboardWalletHistoryDtoStatus;
(function (EnumInvestorDashboardWalletHistoryDtoStatus) {
    EnumInvestorDashboardWalletHistoryDtoStatus["PENDING"] = "PENDING";
    EnumInvestorDashboardWalletHistoryDtoStatus["SUCCEEDED"] = "SUCCEEDED";
    EnumInvestorDashboardWalletHistoryDtoStatus["FAILED"] = "FAILED";
})(EnumInvestorDashboardWalletHistoryDtoStatus || (EnumInvestorDashboardWalletHistoryDtoStatus = {}));
export var EnumInvestorDashboardWalletHistoryDtoType;
(function (EnumInvestorDashboardWalletHistoryDtoType) {
    EnumInvestorDashboardWalletHistoryDtoType["PAY_IN"] = "PAY_IN";
    EnumInvestorDashboardWalletHistoryDtoType["TRANSFER_TO_PROJECT"] = "TRANSFER_TO_PROJECT";
    EnumInvestorDashboardWalletHistoryDtoType["TRANSFER_FROM_PROJECT"] = "TRANSFER_FROM_PROJECT";
    EnumInvestorDashboardWalletHistoryDtoType["PAY_OUT"] = "PAY_OUT";
})(EnumInvestorDashboardWalletHistoryDtoType || (EnumInvestorDashboardWalletHistoryDtoType = {}));
export var EnumLoginResponseRole;
(function (EnumLoginResponseRole) {
    EnumLoginResponseRole["ADMIN"] = "ADMIN";
    EnumLoginResponseRole["INVESTOR"] = "INVESTOR";
    EnumLoginResponseRole["DEVELOPER"] = "DEVELOPER";
})(EnumLoginResponseRole || (EnumLoginResponseRole = {}));
export var EnumPaymentDetailDtoStrategy;
(function (EnumPaymentDetailDtoStrategy) {
    EnumPaymentDetailDtoStrategy["DEBT_SECURED"] = "DEBT_SECURED";
    EnumPaymentDetailDtoStrategy["DEBT"] = "DEBT";
    EnumPaymentDetailDtoStrategy["EQUITY"] = "EQUITY";
    EnumPaymentDetailDtoStrategy["SHARIA"] = "SHARIA";
})(EnumPaymentDetailDtoStrategy || (EnumPaymentDetailDtoStrategy = {}));
export var EnumPaymentDtoStatus;
(function (EnumPaymentDtoStatus) {
    EnumPaymentDtoStatus["PENDING"] = "PENDING";
    EnumPaymentDtoStatus["SUCCEEDED"] = "SUCCEEDED";
    EnumPaymentDtoStatus["FAILED"] = "FAILED";
})(EnumPaymentDtoStatus || (EnumPaymentDtoStatus = {}));
export var EnumProjectDtoProjectType;
(function (EnumProjectDtoProjectType) {
    EnumProjectDtoProjectType["COMMERCIAL_BUILD"] = "COMMERCIAL_BUILD";
    EnumProjectDtoProjectType["RESIDENTIAL_BUILD"] = "RESIDENTIAL_BUILD";
    EnumProjectDtoProjectType["REFURBISHMENT"] = "REFURBISHMENT";
})(EnumProjectDtoProjectType || (EnumProjectDtoProjectType = {}));
export var EnumProjectDtoPurpose;
(function (EnumProjectDtoPurpose) {
    EnumProjectDtoPurpose["PURCHASING"] = "PURCHASING";
    EnumProjectDtoPurpose["DEVELOPING"] = "DEVELOPING";
    EnumProjectDtoPurpose["PURCHASEA_AND_DEVELOP"] = "PURCHASEA_AND_DEVELOP";
})(EnumProjectDtoPurpose || (EnumProjectDtoPurpose = {}));
export var EnumProjectDtoStatus;
(function (EnumProjectDtoStatus) {
    EnumProjectDtoStatus["DRAFT"] = "DRAFT";
    EnumProjectDtoStatus["COMING_SOON"] = "COMING_SOON";
    EnumProjectDtoStatus["AVAILABLE_TO_PLEDGE"] = "AVAILABLE_TO_PLEDGE";
    EnumProjectDtoStatus["FULLY_FUNDED_DEVELOPMENT_NOT_BEGAN"] = "FULLY_FUNDED_DEVELOPMENT_NOT_BEGAN";
    EnumProjectDtoStatus["FULLY_FUNDED_DEVELOPMENT_BEGAN"] = "FULLY_FUNDED_DEVELOPMENT_BEGAN";
    EnumProjectDtoStatus["FULLY_FUNDED_DEVELOPMENT_COMPLETE_SHORT"] = "FULLY_FUNDED_DEVELOPMENT_COMPLETE_SHORT";
    EnumProjectDtoStatus["COMPLETE_SHORT_TERM"] = "COMPLETE_SHORT_TERM";
    EnumProjectDtoStatus["FULLY_FUNDED_DEVELOPMENT_COMPLETE_LONG"] = "FULLY_FUNDED_DEVELOPMENT_COMPLETE_LONG";
    EnumProjectDtoStatus["COMPLETE"] = "COMPLETE";
    EnumProjectDtoStatus["CANCELLED_NOT_PAID_OUT"] = "CANCELLED_NOT_PAID_OUT";
    EnumProjectDtoStatus["CANCELLED_PAID_OUT"] = "CANCELLED_PAID_OUT";
    EnumProjectDtoStatus["ARCHIVED"] = "ARCHIVED";
})(EnumProjectDtoStatus || (EnumProjectDtoStatus = {}));
export var EnumProjectRequestProjectType;
(function (EnumProjectRequestProjectType) {
    EnumProjectRequestProjectType["COMMERCIAL_BUILD"] = "COMMERCIAL_BUILD";
    EnumProjectRequestProjectType["RESIDENTIAL_BUILD"] = "RESIDENTIAL_BUILD";
    EnumProjectRequestProjectType["REFURBISHMENT"] = "REFURBISHMENT";
})(EnumProjectRequestProjectType || (EnumProjectRequestProjectType = {}));
export var EnumProjectRequestPurpose;
(function (EnumProjectRequestPurpose) {
    EnumProjectRequestPurpose["PURCHASING"] = "PURCHASING";
    EnumProjectRequestPurpose["DEVELOPING"] = "DEVELOPING";
    EnumProjectRequestPurpose["PURCHASEA_AND_DEVELOP"] = "PURCHASEA_AND_DEVELOP";
})(EnumProjectRequestPurpose || (EnumProjectRequestPurpose = {}));
export var EnumProjectRequestStatus;
(function (EnumProjectRequestStatus) {
    EnumProjectRequestStatus["DRAFT"] = "DRAFT";
    EnumProjectRequestStatus["COMING_SOON"] = "COMING_SOON";
    EnumProjectRequestStatus["AVAILABLE_TO_PLEDGE"] = "AVAILABLE_TO_PLEDGE";
    EnumProjectRequestStatus["FULLY_FUNDED_DEVELOPMENT_NOT_BEGAN"] = "FULLY_FUNDED_DEVELOPMENT_NOT_BEGAN";
    EnumProjectRequestStatus["FULLY_FUNDED_DEVELOPMENT_BEGAN"] = "FULLY_FUNDED_DEVELOPMENT_BEGAN";
    EnumProjectRequestStatus["FULLY_FUNDED_DEVELOPMENT_COMPLETE_SHORT"] = "FULLY_FUNDED_DEVELOPMENT_COMPLETE_SHORT";
    EnumProjectRequestStatus["COMPLETE_SHORT_TERM"] = "COMPLETE_SHORT_TERM";
    EnumProjectRequestStatus["FULLY_FUNDED_DEVELOPMENT_COMPLETE_LONG"] = "FULLY_FUNDED_DEVELOPMENT_COMPLETE_LONG";
    EnumProjectRequestStatus["COMPLETE"] = "COMPLETE";
    EnumProjectRequestStatus["CANCELLED_NOT_PAID_OUT"] = "CANCELLED_NOT_PAID_OUT";
    EnumProjectRequestStatus["CANCELLED_PAID_OUT"] = "CANCELLED_PAID_OUT";
    EnumProjectRequestStatus["ARCHIVED"] = "ARCHIVED";
})(EnumProjectRequestStatus || (EnumProjectRequestStatus = {}));
export var EnumStartPledgeRequestInvestStrategy;
(function (EnumStartPledgeRequestInvestStrategy) {
    EnumStartPledgeRequestInvestStrategy["DEBT_SECURED"] = "DEBT_SECURED";
    EnumStartPledgeRequestInvestStrategy["DEBT"] = "DEBT";
    EnumStartPledgeRequestInvestStrategy["EQUITY"] = "EQUITY";
    EnumStartPledgeRequestInvestStrategy["SHARIA"] = "SHARIA";
})(EnumStartPledgeRequestInvestStrategy || (EnumStartPledgeRequestInvestStrategy = {}));
export var EnumSumsubHookResponseReviewStatus;
(function (EnumSumsubHookResponseReviewStatus) {
    EnumSumsubHookResponseReviewStatus["init"] = "init";
    EnumSumsubHookResponseReviewStatus["completed"] = "completed";
    EnumSumsubHookResponseReviewStatus["completedSent"] = "completedSent";
    EnumSumsubHookResponseReviewStatus["completedSentFailure"] = "completedSentFailure";
})(EnumSumsubHookResponseReviewStatus || (EnumSumsubHookResponseReviewStatus = {}));
export var EnumSumsubReviewResultReviewAnswer;
(function (EnumSumsubReviewResultReviewAnswer) {
    EnumSumsubReviewResultReviewAnswer["RED"] = "RED";
    EnumSumsubReviewResultReviewAnswer["GREEN"] = "GREEN";
})(EnumSumsubReviewResultReviewAnswer || (EnumSumsubReviewResultReviewAnswer = {}));
export var EnumSumsubReviewResultReviewRejectType;
(function (EnumSumsubReviewResultReviewRejectType) {
    EnumSumsubReviewResultReviewRejectType["FINAL"] = "FINAL";
    EnumSumsubReviewResultReviewRejectType["RETRY"] = "RETRY";
    EnumSumsubReviewResultReviewRejectType["EXTERNAL"] = "EXTERNAL";
})(EnumSumsubReviewResultReviewRejectType || (EnumSumsubReviewResultReviewRejectType = {}));
export var EnumUpdateProfileRequestDocType;
(function (EnumUpdateProfileRequestDocType) {
    EnumUpdateProfileRequestDocType["PASSPORT"] = "PASSPORT";
    EnumUpdateProfileRequestDocType["DRIVERS_LICENSE"] = "DRIVERS_LICENSE";
})(EnumUpdateProfileRequestDocType || (EnumUpdateProfileRequestDocType = {}));
export var EnumUpdateProfileRequestInvestorType;
(function (EnumUpdateProfileRequestInvestorType) {
    EnumUpdateProfileRequestInvestorType["HIGH_NET_WORTH_INVESTOR"] = "HIGH_NET_WORTH_INVESTOR";
    EnumUpdateProfileRequestInvestorType["SELF_CERTIFIED_INVESTOR"] = "SELF_CERTIFIED_INVESTOR";
    EnumUpdateProfileRequestInvestorType["CERTIFIED_SOPHISTICATED_INVESTOR"] = "CERTIFIED_SOPHISTICATED_INVESTOR";
    EnumUpdateProfileRequestInvestorType["ADVISED_INVESTOR"] = "ADVISED_INVESTOR";
    EnumUpdateProfileRequestInvestorType["EVERYDAY_INVESTOR"] = "EVERYDAY_INVESTOR";
})(EnumUpdateProfileRequestInvestorType || (EnumUpdateProfileRequestInvestorType = {}));
export var EnumUpdateProfileRequestLegalEntity;
(function (EnumUpdateProfileRequestLegalEntity) {
    EnumUpdateProfileRequestLegalEntity["BUSINESS"] = "BUSINESS";
    EnumUpdateProfileRequestLegalEntity["ORGANIZATION"] = "ORGANIZATION";
    EnumUpdateProfileRequestLegalEntity["SOLETRADER"] = "SOLETRADER";
})(EnumUpdateProfileRequestLegalEntity || (EnumUpdateProfileRequestLegalEntity = {}));
export var EnumUpdateProfileRequestPersonType;
(function (EnumUpdateProfileRequestPersonType) {
    EnumUpdateProfileRequestPersonType["INDIVIDUAL"] = "INDIVIDUAL";
    EnumUpdateProfileRequestPersonType["PROFESSIONAL"] = "PROFESSIONAL";
})(EnumUpdateProfileRequestPersonType || (EnumUpdateProfileRequestPersonType = {}));
export var EnumUpdateProfileRequestTitle;
(function (EnumUpdateProfileRequestTitle) {
    EnumUpdateProfileRequestTitle["MR"] = "MR";
    EnumUpdateProfileRequestTitle["MS"] = "MS";
    EnumUpdateProfileRequestTitle["MRS"] = "MRS";
    EnumUpdateProfileRequestTitle["MISS"] = "MISS";
})(EnumUpdateProfileRequestTitle || (EnumUpdateProfileRequestTitle = {}));
export var EnumUserDtoDocType;
(function (EnumUserDtoDocType) {
    EnumUserDtoDocType["PASSPORT"] = "PASSPORT";
    EnumUserDtoDocType["DRIVERS_LICENSE"] = "DRIVERS_LICENSE";
})(EnumUserDtoDocType || (EnumUserDtoDocType = {}));
export var EnumUserDtoInvestorType;
(function (EnumUserDtoInvestorType) {
    EnumUserDtoInvestorType["HIGH_NET_WORTH_INVESTOR"] = "HIGH_NET_WORTH_INVESTOR";
    EnumUserDtoInvestorType["SELF_CERTIFIED_INVESTOR"] = "SELF_CERTIFIED_INVESTOR";
    EnumUserDtoInvestorType["CERTIFIED_SOPHISTICATED_INVESTOR"] = "CERTIFIED_SOPHISTICATED_INVESTOR";
    EnumUserDtoInvestorType["ADVISED_INVESTOR"] = "ADVISED_INVESTOR";
    EnumUserDtoInvestorType["EVERYDAY_INVESTOR"] = "EVERYDAY_INVESTOR";
})(EnumUserDtoInvestorType || (EnumUserDtoInvestorType = {}));
export var EnumUserDtoKycStatus;
(function (EnumUserDtoKycStatus) {
    EnumUserDtoKycStatus["DRAFT"] = "DRAFT";
    EnumUserDtoKycStatus["IN_PROGRESS"] = "IN_PROGRESS";
    EnumUserDtoKycStatus["APPROVED"] = "APPROVED";
    EnumUserDtoKycStatus["REJECTED"] = "REJECTED";
})(EnumUserDtoKycStatus || (EnumUserDtoKycStatus = {}));
export var EnumUserDtoLegalEntity;
(function (EnumUserDtoLegalEntity) {
    EnumUserDtoLegalEntity["BUSINESS"] = "BUSINESS";
    EnumUserDtoLegalEntity["ORGANIZATION"] = "ORGANIZATION";
    EnumUserDtoLegalEntity["SOLETRADER"] = "SOLETRADER";
})(EnumUserDtoLegalEntity || (EnumUserDtoLegalEntity = {}));
export var EnumUserDtoPersonType;
(function (EnumUserDtoPersonType) {
    EnumUserDtoPersonType["INDIVIDUAL"] = "INDIVIDUAL";
    EnumUserDtoPersonType["PROFESSIONAL"] = "PROFESSIONAL";
})(EnumUserDtoPersonType || (EnumUserDtoPersonType = {}));
export var EnumUserDtoStatus;
(function (EnumUserDtoStatus) {
    EnumUserDtoStatus["ACTIVE"] = "ACTIVE";
    EnumUserDtoStatus["NOT_ACTIVE"] = "NOT_ACTIVE";
})(EnumUserDtoStatus || (EnumUserDtoStatus = {}));
export var EnumUserDtoTitle;
(function (EnumUserDtoTitle) {
    EnumUserDtoTitle["MR"] = "MR";
    EnumUserDtoTitle["MS"] = "MS";
    EnumUserDtoTitle["MRS"] = "MRS";
    EnumUserDtoTitle["MISS"] = "MISS";
})(EnumUserDtoTitle || (EnumUserDtoTitle = {}));
export var EnumValidateTokenFromLinkRequestTokenType;
(function (EnumValidateTokenFromLinkRequestTokenType) {
    EnumValidateTokenFromLinkRequestTokenType["RESTORE_PASSWORD"] = "RESTORE_PASSWORD";
    EnumValidateTokenFromLinkRequestTokenType["UNSUBSCRIBE_NEWS"] = "UNSUBSCRIBE_NEWS";
    EnumValidateTokenFromLinkRequestTokenType["REGISTRY"] = "REGISTRY";
})(EnumValidateTokenFromLinkRequestTokenType || (EnumValidateTokenFromLinkRequestTokenType = {}));
