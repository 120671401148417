import React from 'react';

import './payments-wallet.scss';
import {toCurrencyFormat} from "in5pire-storybook/dist";
import {PaymentDto} from "in5pire-api";

interface props {
  balance: string,
  totalPaidOuts: string,
  payments: PaymentDto[],
  sumInfo: object,
  transferredToLongWallet: string
}

export function PaymentsWallet(props: props) {
  const {balance, totalPaidOuts, payments, sumInfo, transferredToLongWallet} = props;
  return (
    <div className="admin-payments__wallet">
      <span><b>Project E-wallet Balance: </b>{balance}</span>
      <span><b>Total Paid Outs: </b>{totalPaidOuts}</span>
      <span><b>Transferred to long term: </b>{transferredToLongWallet}</span>
      {sumInfo !== undefined ? SumInfo(sumInfo) : SumInfo({SUCCEEDED: 0, PENDING: 0})}
      <span><b>Failed: </b>{payments ? countFailed(payments) : 0}</span>
    </div>
  )
}

const SumInfo = (props) => {
  const { SUCCEEDED, PENDING } = props;
 return(
   <>
     <span><b>Paid: </b>{toCurrencyFormat(SUCCEEDED)}</span>
     <span><b>Pending: </b>{toCurrencyFormat(PENDING)}</span>
   </>
 )
}

const countFailed = (payments) => {
  let total = 0;
  payments.forEach(item => {
    if(item.status === 'FAILED') {
      total++
    }
  });
  return total
};
