import { toCurrencyFormat } from './currency-utils';

/* (([SHARE OF PROFITS (%)] * (EXPECTED PROFIT (£)]) / [FUNDING TARGET (£)) *100)/[PROJECT DURATION (Years)].
    For example:
Funding Target £600k
Expected profit £225k
Share of profits 40%
18 months (1.5 year)
The expected rate of return will be ((0.40 * £225k)/£600k)*100)/1.5 = 10% per annum
 */
export function calcProfit(profit, data) {
  if (!profit) return null;
  if (!data.startDate || !data.endDate) return null;
  var duration = (data.endDate.getTime() - data.startDate.getTime()) / 1000 / 60 / 60 / 24 / 365;
  return Math.round(profit / 100 * data.expectedProfit / data.fundingTarget * 100 / duration);
}
export function calculatePercent(investment, rate, months) {
  var calcRes = toCurrencyFormat(investment + investment / 100 * rate * (months / 12));
  return calcRes;
}