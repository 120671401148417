import { FormControl } from 'in5pire-storybook';
import * as React from 'react';
import {useState} from 'react';
import { dynamicCurrencyFormat } from '../../../../helpers';

interface Props {
  currentBalance: number;
  type: 'withdraw' | 'fund';
  success: boolean;
  onSubmit: (val: number) => void;
  disabled: boolean;
  children?: React.ReactNode;
}

export function AmountForm({currentBalance, type, success, onSubmit, disabled, children}: Props) {

  const submitTitle = type === 'fund' ? 'deposit' : type;

  const [amount, setAmount] = useState('');
  const [fieldError, setFieldError] = useState('');

  const handleSubmit = React.useCallback(e => {
    e.preventDefault();
    if (disabled) {
      return;
    }
    const sum = parseInt(amount.replace(/[£,]/g, ''), 10);
    if (isNaN(sum)) {
      setFieldError('Field is required');
      return;
    }
    if (sum === 0) {
      setFieldError('Incorrect amount');
      return;
    }
    if (type === 'withdraw' && currentBalance < sum) {
      setFieldError('Insufficient balance');
      return;
    }
    onSubmit(sum);
  }, [amount, onSubmit, currentBalance, type, disabled]);

  const onChange = React.useCallback(e => {
    setFieldError('');
    const matches = e.target.value.replace(/,/g, '').match(/^£?[0-9]+$/g);
    if (e.target.value === '£') {
      setAmount('');
    } else if (matches) {
      setAmount(`£${dynamicCurrencyFormat(parseInt(matches[0].replace(/[£]/g, '')))}`);
  }}, []);

  return (
    <form onSubmit={handleSubmit}>
      <fieldset disabled={success}>
        <FormControl error={fieldError ? {type: '', message: fieldError} : null}>
          <input
            onChange={onChange}
            value={amount}
            type="text"
            placeholder="£"
          />
        </FormControl>
        {children}
        <div className="dashboard-modal__submit-wrapper">
          {!success &&
            <button disabled={disabled} type="submit" className="button button--primary">
              {submitTitle}
            </button>
          }
        </div>
      </fieldset>
    </form>
  );
}
