import * as React from 'react';

import './page-title.scss';

interface Props {
  pageTitle: string
}

export function PageTitle(props: Props) {
  return <h1 className='page-title'>{props.pageTitle}</h1>
}
