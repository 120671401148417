import * as React from 'react';

import './chart-card.scss';
import {Card} from '../atoms/Card/Card';
import {DonutChart} from '../atoms/DonutChart/DonutChart';

interface Props {
  className?: string,
  projectsStatistic?: any[],
  projectsStatistic2?: any[],
  caption?: {title: string, count: number},
  caption2?: {title: string, count: number},
  cardTitle: string
}

export const ChartCard = (props: Props) => {
  return (
    <Card cardTitle={props.cardTitle}
          className={`chart-card ${props.className}`}>
      <div className="chart-card__content">
        <DonutChart projectsStatistic={props.projectsStatistic}
                    isDouble={!!props.projectsStatistic2}
                    caption={props.caption}
        />
        {!!props.projectsStatistic2 &&
        <DonutChart projectsStatistic={props.projectsStatistic2}
                    isDouble={!!props.projectsStatistic2}
                    caption={props.caption2}
                    second={true}
        />}
      </div>
    </Card>
  );
};
