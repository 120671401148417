import { EnumStartPledgeRequestInvestStrategy } from 'in5pire-api';
export function getInvestmentStrategyOptionsForPledge(project) {
  var options = new Map();
  var allowLong = project.allowLongTerm && project.longFundingTarget > project.longFoundedSum;

  if (project.shortDebtSecuredExpectedRate != null) {
    options.set(EnumStartPledgeRequestInvestStrategy.DEBT_SECURED, {
      title: 'Debt Secured Loan Agreement',
      short: project.shortDebtSecuredExpectedRate,
      long: project.longDebtSecuredExpectedRate,
      isLong: allowLong && project.longDebtSecuredExpectedRate != null,
      description: 'A secured debt agreement secures your cash investment against the property for a fixed annual return.'
    });
  }

  if (project.shortDebtExpectedRate != null) {
    options.set(EnumStartPledgeRequestInvestStrategy.DEBT, {
      title: 'Debt Loan Agreement',
      short: project.shortDebtExpectedRate,
      long: project.longDebtExpectedRate,
      isLong: allowLong && project.longDebtExpectedRate != null,
      description: 'A debt agreement is a cash invested loan for a fixed annual return.'
    });
  }

  if (project.shortEquityExpectedRate != null) {
    options.set(EnumStartPledgeRequestInvestStrategy.EQUITY, {
      title: 'Equity Loan Agreement',
      short: project.shortEquityExpectedRate,
      long: project.longEquityExpectedRate,
      isLong: allowLong && project.longEquityExpectedRate != null,
      description: 'An equity loan is a cash investment for a percentage of the realised net profit of an investment following sale.'
    });
  }

  if (project.shortShariaExpectedRate != null) {
    options.set(EnumStartPledgeRequestInvestStrategy.SHARIA, {
      title: 'Sharia Equity Agreement',
      short: project.shortShariaExpectedRate,
      long: project.longShariaExpectedRate,
      isLong: allowLong && project.longShariaExpectedRate != null,
      description: 'A Sharia equity loan is a cash investment for a percentage of the realised net profit of an investment following sale and is fully compliant with Islamic law.'
    });
  }

  return options;
}