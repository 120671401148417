import { EnumUpdateProfileRequestPersonType, EnumBaseUserDtoTitle } from 'in5pire-api';
export var personTypeOptions = [{
  value: EnumUpdateProfileRequestPersonType.INDIVIDUAL,
  caption: 'Individual'
}, {
  value: EnumUpdateProfileRequestPersonType.PROFESSIONAL,
  caption: 'Company'
}];
export var legalEntityOptions = [{
  value: 'BUSINESS',
  caption: 'Business'
}, {
  value: 'ORGANIZATION',
  caption: 'Organization'
}, {
  value: 'SOLETRADER',
  caption: 'Soletrader'
}];
export var titlesOptions = [{
  value: EnumBaseUserDtoTitle.MR,
  caption: 'Mr'
}, {
  value: EnumBaseUserDtoTitle.MRS,
  caption: 'Mrs'
}, {
  value: EnumBaseUserDtoTitle.MISS,
  caption: 'Miss'
}];