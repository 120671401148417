import * as React from 'react';
import * as ReactDOM from 'react-dom';
import axios from 'axios'
import { serviceOptions, UserControllerService } from 'in5pire-api';
import { App } from './App';
import 'in5pire-storybook/dist/scss/style.css';

import { RootState } from './stores/root-state';
import { UserState } from './stores/user-state';
import { RootContext } from './stores/root-context';

serviceOptions.axios = axios.create({
  baseURL: process.env.REACT_APP_PLATFORM_API_URL,
  timeout: 60000,
});

const rootState = new RootState(new UserState());

serviceOptions.axios.interceptors.request.use(config => {
  if (rootState.userState.token != null) {
    config.headers['Authorization'] = `Bearer ${rootState.userState.token}`;
  }
  config.paramsSerializer = (params) => {
    const search = new URLSearchParams();
    Object.keys(params).forEach(key => {
      if (params[key] == null) return;
      if (Array.isArray(params[key])) {
        params[key].forEach(param => search.append(key, param))
      } else {
        search.append(key, params[key]);
      }
    });
    return search.toString();
  }
  return config;
}, error => {
  return Promise.reject(error);
});

UserControllerService.getProfileUsingGet().then(response => {
  rootState.userState.setUser(response.user);
}).catch(error => {
  console.error('init getting user', error);
  rootState.userState.logout();
}).finally(() => {
  ReactDOM.render(
    React.createElement(RootContext.Provider, {value: rootState}, React.createElement(App, {rootState})),
    document.getElementById('root')
  );
})

