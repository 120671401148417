import * as React from 'react';

import './card-title.scss';

interface Props {
  cardTitle: string,
  cardSubTitle?: string,
}

export function CardTitle(props: Props) {
  return (
    <div className="card-title--wrapper">
      <h5 className="card-title__subtitle">{props.cardSubTitle}</h5>
      <h4 className='card-title__title'>{props.cardTitle}</h4>
    </div>
  );
}
