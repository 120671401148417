import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import {COUNTRIES} from 'in5pire-storybook';
import { AgreementLogDto } from 'in5pire-api';

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    marginTop: '25px',
    marginRight: '10px'
  },
  row: {
    flexDirection: 'column',
    margin: '10px'
  },
  rowTitle: {
    backgroundColor: 'lightgrey',
    marginLeft: '10px'
  },
  line: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: 15
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
  },
  flex: {
    flex: 1
  },
  title: {
    margin: '25px',
    textAlign: 'center'
  }
});

const formatter = Intl.DateTimeFormat('en-GB', {
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  year: 'numeric', month: 'numeric', day: 'numeric',
});

export const MyDocument = (props) => {


  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.title}>
          <Text style={styles.bold}>Agreement metadata</Text>
        </View>
        {props.logs.map((log, i) => {
          return (
            <View key={i} style={styles.root}>
              <View style={styles.rowTitle}><Text>{log.event.replace('_', ' ')}</Text></View>
              <View style={styles.row}><PdfText log={log} /></View>
            </View>
          )
        })}
      </Page>
    </Document>
  );
}


const PdfText = ({log}: {log: AgreementLogDto}) => {
  const {code, event, ip, location, number, timestamp, userName} = log;
  const date = new Date(timestamp);
  const country = COUNTRIES.reduce((res, {name, code}) => {
    if (location.indexOf(code) !== -1) {
      res = {code, name};
    };
    return res;
  }, {code: '', name: ''});
  const formattedLocation = location.replace(country.code, `${country.name},`)
  const formattedDate = formatter.format(date);
  switch (event) {
    case 'START_PLEDGE':
      return (
        <View style={{flexDirection: 'column'}}>
          <View style={styles.line}>
            <Text>Pledge process has been started at {formattedDate} by the </Text>
            <Text style={{...styles.bold}}>{userName}. </Text>
          </View>
          <View style={styles.line}>
            <Text style={styles.bold}>Country: </Text>
            <Text>{formattedLocation}. </Text>
            <Text style={styles.bold}>IP: </Text>
            <Text>{ip}</Text>
          </View>
        </View>
      )
    case 'SEND_SMS':
      return <Text  style={styles.line}>SMS code {code} has been sent to the {number} at {formattedDate}</Text>
    case 'ATTEMPTS_ENTER':
      return <Text style={styles.line}>{userName} has tried to enter SMS code {code} at {formattedDate}</Text>
    case 'SEND_DOCUMENT':
      return (
        <View style={{flexDirection: 'column'}}>
          <View style={styles.line}>
            <Text>Agreement has been signed by the </Text>
            <Text style={styles.bold}>{userName}. </Text>
          </View>
          <View style={styles.line}>
            <Text style={styles.bold}>Country: </Text>
            <Text>{formattedLocation}. </Text>
            <Text style={styles.bold}>IP: </Text>
            <Text>{ip} at {formattedDate}</Text>
          </View>
        </View>
      )
  }
};