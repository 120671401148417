import React, {useState} from 'react';

import './payment-table-item.scss';
import {SvgIcons, toCurrencyFormat} from 'in5pire-storybook/dist/';
import {PaymentDetailDto} from "in5pire-api";


interface Props {
  firstInvest: string;
  id: number;
  investSum: string;
  makePaymentById: (arg: number) => void;
  name: string;
  profit: string;
  rate: number;
  status: string;
  strategy: PaymentDetailDto[];
  total: string;
  days: number;
  yearRate: number;
}

const maxItemsToShow = 2;

const formatter = new Intl.DateTimeFormat('en-GB');

export function PaymentsTableItem(props: Props) {
  const { name, firstInvest, strategy, investSum, profit, total, status, id, rate, days, yearRate } = props;
  const [expanded, isExpanded] = useState(false);
  const [menu, setMenu] = useState(false);
  const visibleItems = strategy.length > maxItemsToShow ? strategy.slice(0, maxItemsToShow) : strategy;
  return(
    <>
      <tr>
        <td className="icon">
          { strategy.length > 1 ?
            <button
              onClick={() => isExpanded(!expanded)}
              className={`icon-plus ${expanded ? 'active' : ''}`}>
            </button>
          : null }
        </td>
        <td className="d-column content-left"><span><b>{name}</b></span><span>{firstInvest}</span></td>
        <td className="d-column content-left">
          {!expanded
            ? visibleItems.map((item, index) => (
                <span key={index}><b>{item.strategy}</b></span>
              ))
            : null}
          {!expanded ? strategy.length > maxItemsToShow && <span>...</span> : null}
        </td>
        <td>{investSum}</td>
        <td>{days}</td>
        <td>{rate}%</td>
        <td>{Math.round(yearRate*100)/100}%</td>
        <td>{profit}</td>
        <td><b>{total}</b></td>
        <td className={status}>{status}</td>
        <td className="icon status-icon">{status === 'FAILED'
          ? <>
              <button type="button" className="admin-payments__button" onClick={() => setMenu(!menu)}>
                <SvgIcons isStaticImg iconType={'more'}/>
              </button>
              {menu ? <button type="button" onClick={() => props.makePaymentById(id)}><SvgIcons isStaticImg iconType={'payments'}/> Make Payment</button> : null}
            </>
          : ''}
        </td>
      </tr>
      {expanded && strategy ?
        strategy.map((item, index) => (
           <tr className="expanded" key={index}>
            <td className="icon"></td>
            <td className="content-left">
              {item.longTerm ? 'Long-Term' : 'Short-Term'}
              <p>
                {formatter.format(new Date(item.investDate))}
              </p>
            </td>
            <td className="content-left"><b>{item.strategy}</b></td>
            <td>{toCurrencyFormat(item.sum)}</td>
            <td>{item.investDays}</td>
            <td>{Math.round(item.rate*100)/100}%</td>
            <td>{Math.round(item.yearRate*100)/100}%</td>
            <td>{toCurrencyFormat(item.profit)}</td>
            <td><b>{toCurrencyFormat(item.total)}</b></td>
            <td className={status}></td>
            <td className="icon"></td>
          </tr>
          ))
      : null}
    </>
  )
}
