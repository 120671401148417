import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { Profile, ErrorModal }  from 'in5pire-storybook';
import { RootContext } from '../../stores/root-context';
import {resolveError} from '../../helpers';
import {
  UserDetailsRequest,
  UserBankDetailsRequest,
  UserDto,
  UsersControllerService,
  UserControllerService,
  EnumLoginResponseRole
} from 'in5pire-api';


import './user-profile-page.scss';

export const UserProfilePage = observer((props: RouteComponentProps) => {

  const { userState } = React.useContext(RootContext);

  const { token } = useParams<{token?: string}>();

  const history = useHistory()

  const [bankDetails, setBankDetais] = React.useState({
    sortCode: userState.user.sortCode,
    accountNumber: userState.user.accountNumber
  } as UserBankDetailsRequest);

  const [personalDetails, setPersonalDetais] = React.useState({
    email: userState.user.email,
    phoneNumber: userState.user.phoneNumber,
    website: userState.user.website,
    photo: userState.user.photo
  } as UserDetailsRequest);

  const [user, setUser] = React.useState({} as UserDto);
  const [fieldErors, setFieldError] = React.useState({});
  const [disableSending, setDisableSending] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [isHideError, setIsHideError] = React.useState(false);
  const isAdmin = userState.role === EnumLoginResponseRole.ADMIN;

  React.useEffect(() => setFieldError({}), [bankDetails, personalDetails]);

  const updateUser = React.useCallback((user: UserDto) => {
    userState.setUser(user);
    setPersonalDetais({
      email: user.email,
      phoneNumber: user.phoneNumber,
      website: user.website,
      photo: user.photo
    })
  }, [userState]);

  const phoneUpdated = React.useRef<boolean>(false);
  const notificationTimeoutHolder = React.useRef<NodeJS.Timeout>();
  const [ notification, setNotification ] = React.useState({text: '', isError: false});

  React.useEffect(() => {
    if (!token || phoneUpdated.current) {
      return;
    }
    UserControllerService.confirmUpdatePhoneOrEmailUsingPost({token}).then(response => {
      updateUser(response.user);
      phoneUpdated.current = true;
      setNotification({
        isError: false,
        text: 'Your Profile details were successfully updated!',
      })
      history.push('../profile');
      if (notificationTimeoutHolder.current) {
        clearTimeout(notificationTimeoutHolder.current)
      }
      notificationTimeoutHolder.current = setTimeout(() => {
        setNotification({text: '', isError: false});
      }, 10000);
    }).catch(e => {
      phoneUpdated.current = false;
      setNotification({
        isError: true,
        text: 'Confirmation link is invalid',
      })
      history.push('../profile');
      if (notificationTimeoutHolder.current) {
        clearTimeout(notificationTimeoutHolder.current)
      }
      notificationTimeoutHolder.current = setTimeout(() => {
        setNotification({text: '', isError: false});
      }, 10000);
    })
  }, [token, updateUser, history])

  const sendInfo = React.useCallback((type: string) => {
    const sendPromise = type === 'bankDetails' ? UserControllerService.updateBankDetailsUsingPut({request: bankDetails})
      : UserControllerService.updateUserDetailsUsingPut({request: personalDetails});
    setDisableSending(true);
    setError('');
    return sendPromise.then(({user}) => {
      setDisableSending(false);
      updateUser(user);
      return true;
    }).catch(error => {
      setIsHideError(false);
      setDisableSending(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const errors = error.response.data.errors.reduce((res, {field, message}) => {
          return {...res, [field[0].toLowerCase() + field.slice(1)]: {message}}
        }, {})
        setFieldError(errors);
      } else {
        setError(resolveError(error));
      }
    });
  }, [bankDetails, personalDetails, updateUser]);


  React.useEffect(() => {
    const id = props.match.params && props.match.params['id'] ? props.match.params['id'] : 0
    if (userState.role === EnumLoginResponseRole.ADMIN && id) {
      setLoading(true);
      setError('');
      UsersControllerService.getUserUsingGet({id})
        .then(({user}) => {
          setLoading(false);
          setUser(user);
        }).catch(error => {
          setLoading(false);
          setIsHideError(false);
          setError(resolveError(error));
        });
    }
  }, [props.match.params, props.location, userState.role, updateUser]);
  return (
    <div className="user-profile-page">
      {!!notification.text && <div className={'user-profile__notification' + (notification.isError ? '_error' : '')}>
        {notification.text}</div>
      }
      <div className="user-profile__caption">{isAdmin ? 'View' : 'My'} Profile</div>
      {loading && <div>Loading...</div>}
      {error && !isHideError && <ErrorModal onClose={() => {setIsHideError(true); setError('');}} content={error} />}
      {!loading && !error && (user.id || userState.user.id) &&
      <Profile bankDetails={bankDetails}
               user={isAdmin ? user : userState.user}
               disableSending={disableSending}
               personalDetails={personalDetails}
               setPersonalDetails={(setPersonalDetais)}
               setBankDetails={setBankDetais}
               sendInfo={sendInfo}
               isAdmin={isAdmin}
               fieldErrors={fieldErors}
      />}
    </div>)
});
