export var DEBT_SECURED = 'Debt (Secured)';
export var DEBT = 'Debt';
export var EQUITY = 'Equity';
export var SHARIA = 'Sharia';
export function getInvestmentStrategyName(project) {
  var longTypes = [];
  var shortTypes = [];
  if (project.longDebtSecuredExpectedRate != null) longTypes.push(DEBT_SECURED);
  if (project.longDebtExpectedRate != null) longTypes.push(DEBT);
  if (project.longShariaExpectedRate != null) longTypes.push(SHARIA);
  if (project.longEquityExpectedRate != null) longTypes.push(EQUITY);
  if (project.shortDebtSecuredExpectedRate != null) shortTypes.push(DEBT_SECURED);
  if (project.shortDebtExpectedRate != null) shortTypes.push(DEBT);
  if (project.shortShariaExpectedRate != null) shortTypes.push(SHARIA);
  if (project.shortEquityExpectedRate != null) shortTypes.push(EQUITY);
  return {
    shortTypes: shortTypes,
    longTypes: longTypes
  };
}
export var getProjectStrategiesForDropDown = function getProjectStrategiesForDropDown(project) {
  var dropdownItems = [];

  if (project.shortDebtExpectedRate != null) {
    dropdownItems.push({
      title: "".concat(DEBT, " \u2014 Short-term"),
      value: project.shortDebtExpectedRate,
      isShortPeriod: true
    });
  }

  if (project.shortDebtSecuredExpectedRate != null) {
    dropdownItems.push({
      title: "".concat(DEBT_SECURED, " \u2014 Short-term"),
      value: project.shortDebtSecuredExpectedRate,
      isShortPeriod: true
    });
  }

  if (project.shortShariaExpectedRate != null) {
    dropdownItems.push({
      title: "".concat(SHARIA, " \u2014 Short-term"),
      value: project.shortShariaExpectedRate,
      isShortPeriod: true
    });
  }

  if (project.shortEquityExpectedRate != null) {
    dropdownItems.push({
      title: "".concat(EQUITY, " \u2014 Short-term"),
      value: project.shortEquityExpectedRate,
      isShortPeriod: true
    });
  }

  if (project.longDebtExpectedRate != null) {
    dropdownItems.push({
      title: "".concat(DEBT, " \u2014 Long-term"),
      value: project.longDebtExpectedRate,
      isShortPeriod: false
    });
  }

  if (project.longDebtSecuredExpectedRate != null) {
    dropdownItems.push({
      title: "".concat(DEBT_SECURED, " \u2014 Long-term"),
      value: project.longDebtSecuredExpectedRate,
      isShortPeriod: false
    });
  }

  if (project.longShariaExpectedRate != null) {
    dropdownItems.push({
      title: "".concat(SHARIA, " \u2014 Long-term"),
      value: project.longShariaExpectedRate,
      isShortPeriod: false
    });
  }

  if (project.longEquityExpectedRate != null) {
    dropdownItems.push({
      title: "".concat(EQUITY, " \u2014 Long-term"),
      value: project.longEquityExpectedRate,
      isShortPeriod: false
    });
  }

  return dropdownItems;
};