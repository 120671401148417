import * as React from 'react';

import './wallet.scss';
import {CardTitle} from '../CardTitle/CardTitle';

interface Props {
  holder: string,
  balance: string,
  onWithdrawClick: () => void,
  onFundClick: () => void,
}

export const Wallet = (props: Props) => {
  return (
    <div className='wallet'>
      <div className='wallet__img'>
        <span className='wallet__cardholder'>{props.holder}</span>
      </div>
      <span className='wallet__row'>
        <CardTitle cardTitle='MY BALANCE' />
        <span className='wallet__row__balance'>{props.balance}</span>
      </span>
      <span className='wallet__row'>
        <button onClick={props.onFundClick} className="button button--primary" type="button">
          deposit
        </button>
        <button onClick={props.onWithdrawClick} className="button button--primary" type="button">
          withdraw
        </button>
      </span>
    </div>
  );
};
