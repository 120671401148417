import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import {Modal} from 'in5pire-storybook';
import { Kyc, ErrorModal, Modes } from 'in5pire-storybook';
import { RootContext } from '../../stores/root-context';
import { UserControllerService, EnumUserDtoKycStatus } from 'in5pire-api';
import {resolveError} from '../../helpers'
import { PlatformContact } from '../ProjectDetails/ProjectDetailsAsk';
import './Kyc-page.scss';

export const KycPage = observer((props: RouteComponentProps) => {
  const rootState = React.useContext(RootContext);
  const [attempts, setAttempt] = React.useState(0);
  const [isFinished, setFinished] = React.useState(false);
  const [error, setError] = React.useState('');
  const [isContactModalOpen, setIsContactModalOpen] = React.useState(false);
  const [isContactFinishModalOpen, setIsContactFinishModalOpen] = React.useState(false);
  const [isStarting, setStarting] = React.useState(
    rootState && rootState.userState && rootState.userState.user && rootState.userState.user.kycStatus === EnumUserDtoKycStatus.DRAFT ?
      false : true
  );

  const refresh = React.useCallback(isSet => {
    return UserControllerService.getProfileUsingGet().then(response => {
      if (isSet) {
        rootState.userState.setUser(response.user);
      }
      return response;
    })
  }, [rootState.userState]);

  const Restart = React.useCallback(() => {
    rootState.userState.setUser({
      ...rootState.userState.user,
      kycRejectReason: null,
      mangopayAddressProofRejectReason: null,
      mangopayArticlesOfAssociationRejectReason: null,
      mangopayIdentityProofRejectReason: null,
      mangopayRegistrationProofRejectReason: null,
      mangopayShareholderDeclarationRejectReason: null,
      kycStatus: EnumUserDtoKycStatus.DRAFT});
    setFinished(false);
  }, [rootState.userState, setFinished]);

  React.useEffect(() => {
    if (rootState && rootState.userState && rootState.userState.user && rootState.userState.user.kycStatus === EnumUserDtoKycStatus.IN_PROGRESS) {
      new Promise(res => setTimeout(() => res(), 300000)).then(() => {
        refresh(true);
      });
    }
  }, [rootState, rootState.userState.user, refresh]);

  React.useEffect(() => {
    if (!isStarting) {
      return;
    }
    new Promise(res => setTimeout(() => res(), 5000)).then(() => {
      refresh(false).then((response) => {
        if (response.user.kycStatus !== EnumUserDtoKycStatus.DRAFT) {
          setStarting(false);
          rootState.userState.setUser(response.user);
        } else if (attempts < 10) {
          setAttempt(attempts + 1);
        } else {
          rootState.userState.setUser(response.user);
          setStarting(false);
          setFinished(false);
        }
      })
    });
  }, [isStarting, refresh, attempts, rootState.userState]);

  return (
    <div className="kyc-page">
      {error && <ErrorModal onClose={() => setError('')} content={error} />}
      {
        rootState && rootState.userState && rootState.userState.user && rootState.userState.user.kycStatus &&
          <Kyc onError={setError}
            quit={() => {props.history.push('/')}}
            user={rootState.userState.user}
            isFinished={isFinished}
            Restart={Restart}
            onContact={() => setIsContactModalOpen(true)}
            onFinish={() => {
              UserControllerService.startKycProcedureUsingPut().then(() => {
                setAttempt(1);
                setFinished(true);
                setStarting(true);}).catch((err) => {
                const error = resolveError(err);
                setError(error);
              });
            }
            }
          />
      }
      {isContactModalOpen &&
        <PlatformContact
          onSend={() => {
            setIsContactModalOpen(false);
            setIsContactFinishModalOpen(true);
          }}
          user={rootState.userState.user}
          projectName=""
          onClose={() => setIsContactModalOpen(false)}
        />
      }
      {isContactFinishModalOpen &&
        <Modal mode={Modes.blue}
          onClose={() => setIsContactFinishModalOpen(false)}>
          <div className="kyc__modal-success">Thank you. Our support will contact you as soon as possible.</div>
        </Modal>
      }
    </div>
  );
});
