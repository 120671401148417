import * as React from 'react';

import './invested-projects-card.scss';
import {Card} from '../atoms/Card/Card';
import {ColoredIcons, Paginator} from 'in5pire-storybook';
import {ProjectsTable} from '../atoms/ProjectsTable/ProjectsTable';

interface Props {
  projectsData: any[], // todo: add good type
  changePage: (payload: number) => void,
  className?: string,
  page: number,
  total: number
  onRowClick: (id: string) => void;
}

export const InvestedProjectsCard = (props: Props) => {
  return (
    <Card cardTitle="PROJECTS I'M INVESTED IN"
      icon={ColoredIcons.PoundIcon()}
      className={props.className}>
      <ProjectsTable projectsData={props.projectsData}
        evenRowColor='violet'
        onRowClick={props.onRowClick}
        headerColor='violet'/>
      <Paginator page={props.page} total={props.total} perPageLimit={8} changePage={props.changePage}/>
    </Card>
  );
};
