import * as React from 'react';
import { observer } from 'mobx-react';
import { PledgeProcess,  SinglePaymentStage, ErrorModal }  from 'in5pire-storybook';
import { InvestmentsControllerService, InvestResponse } from 'in5pire-api';
import { RouteComponentProps } from 'react-router-dom';
import { RootContext } from '../../stores/root-context';

interface Props {
  projectId: number;
  minimumAmount: number;
  remainingAmount: number;
  onClose: Function;
}
enum PledgeStages {
  Exit = -1,
  PledgeAmountStage = 0,
  TermsStage = 1,
  ESignatureStage = 2,
  MangoPaymentStage = 3
}
enum PledgeChoice {
  RemainingAmount = 1,
  CustomAmount = 2,
  MinimumAmount = 3
}

export const PledgeProcessPage = ({projectId, minimumAmount, remainingAmount, onClose}: Props) => {
  const [stage, setStage] = React.useState(PledgeStages.PledgeAmountStage);
  const [loading, setLoading] = React.useState(false);
  const [customAmount, setCustomAmount] = React.useState(0);
  const [finalAmount, setFinalAmount] = React.useState(0);
  const [termsAgreed, setTermsAgreed] = React.useState(false);
  const [paymentInfo, setPaymentInfo] = React.useState(null);
  const [signLink, setSignLink] = React.useState('');
  const [error, setError] = React.useState('');
  const [choice, setChoice] = React.useState(PledgeChoice.RemainingAmount);

  const onClosing = React.useCallback(() => {
    setStage(PledgeStages.PledgeAmountStage);
    setTermsAgreed(false);
    setPaymentInfo(null);
    setSignLink('');
    setError('');
    setLoading(false);
    setChoice(PledgeChoice.RemainingAmount);
    setCustomAmount(minimumAmount);
    onClose();
  }, [minimumAmount, onClose])

  const setInfo = React.useCallback((field, value) => {
    switch(field) {
      case 'stage':
        if (value === PledgeStages.Exit) {
          onClosing();
          break;
        } else if (value === PledgeStages.TermsStage && stage === PledgeStages.PledgeAmountStage) {
          console.log(finalAmount, projectId);
        }
         /* InvestmentsControllerService.investUsingPost({request: {
              projectId
          }}).then(({pdfUrl}) => setSignLink(pdfUrl))
          .catch(error => setError(error.response && error.response && error.response.data && error.response.data.message ?
            error.response.data.message : error.message));
        }
        setStage(value);*/
        break;
      case 'customAmount':
        setCustomAmount(value);
        break;
      case 'finalAmount':
        setFinalAmount(value);
        break;
      case 'termsAgreed':
        setTermsAgreed(value);
        break;
      case 'choice':
        setChoice(value);
        break;
    }
  }, [ finalAmount, onClosing, projectId, stage])
  React.useEffect(() => {
    setCustomAmount(minimumAmount);
  }, [projectId, minimumAmount]);

  return (
    <div className="project-details-page">
      {loading && <div>Loading...</div>}
      {error &&  <ErrorModal onClose={() => setError('')} content={error} />}
      {!loading && !error &&
      <PledgeProcess onError={setError}
                     remainingAmount={remainingAmount}
                     minimumAmount={minimumAmount}
                     customAmount={customAmount}
                     termsAgreed={termsAgreed}
                     stage={stage}
                     paymentInfo={paymentInfo}
                     signLink={signLink}
                     setInfo={setInfo}
                     choice={choice}
      />}
    </div>
  );
};

export const FinalPledgePage = observer((props: RouteComponentProps) => {
  const [paymentInfo, setPaymentInfo] = React.useState(null);
  const [error, setError] = React.useState('');
  const [fundsConfirmed, setFundsConfirmed] = React.useState(false);
  const rootState = React.useContext(RootContext);
  React.useEffect(() => {
    if (!props.match.params || !props.match.params['id']) {
      return;
    }
    InvestmentsControllerService.getInvestInfoForPayUsingGet({id: props.match.params['id']}).then(
      ({
        accountNumber,
        referenceNumber,
        sorCode,
        status,
        sum,
        investorName
      }: InvestResponse) => setPaymentInfo(
        {
          accountNumber,
          referenceNumber,
          sorCode,
          status,
          sum,
          investorName
        }
      )
    ).catch(err => setError(err.response && err.response.data && err.response.data.message ? err.response.data.message : err.message));
  }, [props.match, props.location]);
  React.useEffect(() => {
    rootState.mainNavToggleActive(fundsConfirmed)
  }, [fundsConfirmed, rootState]);
  return <div>
    {!paymentInfo && !error && 'loading...'}
    {error && <ErrorModal onClose={() => setError('')} content={error} />}
    {paymentInfo && !error && <SinglePaymentStage paymentInfo={paymentInfo}
                                                  fundsConfirmed={fundsConfirmed}
                                                  setFundsConfirmed={setFundsConfirmed}
                                                  quit={() => props.history.push('/platform')} />}
  </div>
})
